import { Component, Inject } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { IApplicationState } from 'src/app/app.reducer';
import { enumToObjectsList, getEnumByEnumValue } from 'src/app/modules/core/helpers/enum.helper';
import { GenericTypeModel, GenericTypeSource, GenericTypeStatus, IGenericType } from 'src/app/modules/core/models/generic-type.model';

export interface IEditGenericTypeModel {
    genericType: GenericTypeModel;
    callback: (genericType: GenericTypeModel) => {};
}

@Component({
    selector: 'app-generic-type-item',
    templateUrl: './generic-type-item.component.html',
    styleUrls: ['./generic-type-item.component.scss'],
})
export class GenericTypeItemComponent {
    genericTypeSources = enumToObjectsList(GenericTypeSource);
    genericTypeForm;

    constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public model: IEditGenericTypeModel) {
        const baseType = model.genericType.baseType;

        this.genericTypeForm = this.fb.group({
            id: [baseType.id],
            status: [GenericTypeStatus.new, [Validators.required]],
            name: [baseType.name, [Validators.required]],
            description: [baseType.description],
            shortDescription: [baseType.shortDescription],
            source: [baseType.source, [Validators.required]],
        });
    }

    onAddGenericType() {}

    onSubmit() {
        if (this.genericTypeForm.valid) {
            console.log(getEnumByEnumValue(GenericTypeSource, this.genericTypeForm.value.source!) as GenericTypeSource);
            const genericType: IGenericType = {
                status: GenericTypeStatus.new,
                name: this.genericTypeForm.value.name!,
                description: this.genericTypeForm.value.description!,
                shortDescription: this.genericTypeForm.value.shortDescription!,
                source: getEnumByEnumValue(GenericTypeSource, this.genericTypeForm.value.source!) as GenericTypeSource,
            };
            if (this.genericTypeForm.value.id) {
                genericType.id = this.genericTypeForm.value.id;
            }

            const genericTypeModel = new GenericTypeModel(genericType);
            this.model.callback(genericTypeModel);
        } else {
            console.log('RegistrationComponent valid');
        }
    }
}
