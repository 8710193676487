import { CloneHelper } from '../helpers/clone.helper';
import { EntityModel } from './entity.model';
import { GenericTypeModel } from './generic-type.model';
import { IOpportunity } from './opportunity.model';
import { IUserProfile } from './user.model';

export enum OpportunityRequestFulfillType {
    none = 'none',
    fulfilled = 'fulfilled',
    'partially-fulfilled' = 'partially-fulfilled',
    recommended = 'recommended',
    visited = 'visited',
}

export enum OpportunityRequestStatus {
    new = 'new',
    approved = 'approved',
    canceled = 'canceled',
    deleted = 'deleted',
}

export enum OpportunityRequestSuggestionStatus {
    new = 'new',
}

export interface IOpportunityRequestSuggestion {
    id?: string;
    status: OpportunityRequestSuggestionStatus;
    opportunityRequest?: string;
    suggested: boolean;
    demand: number;
    priority: number;
    solution: number;
}

export const EMPTY_OPPORTUNITY_REQUEST_SUGGESTION: IOpportunityRequestSuggestion = {
    status: OpportunityRequestSuggestionStatus.new,
    suggested: false,
    demand: 0,
    priority: 0,
    solution: 0,
};

export const EMPTY_OPPORTUNITY_REQUEST_TYPE: IOpportunityRequest = {
    status: OpportunityRequestStatus.new,
    profileId: '',
    orderId: 0,
};

export interface IOpportunityRequest {
    id?: string;
    status: OpportunityRequestStatus;
    presenceDate?: Date | null;
    attendanceDate?: Date | null;
    fulfillDate?: Date | null;
    fulfillType?: OpportunityRequestFulfillType;
    profileId: string;
    opportunityId?: string;
    orderId: number;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export const EMPTY_OPPORTUNITY_REQUEST: IOpportunityRequest = {
    status: OpportunityRequestStatus.new,
    profileId: '',
    orderId: 0,
};

export class OpportunityRequestModel extends EntityModel {
    profile?: IUserProfile;
    suggestion?: IOpportunityRequestSuggestion;
    opportunity?: IOpportunity;
    baseRequest: IOpportunityRequest = EMPTY_OPPORTUNITY_REQUEST;
    constructor(opportunityRequest: IOpportunityRequest = EMPTY_OPPORTUNITY_REQUEST) {
        super();

        this.baseRequest = {
            id: opportunityRequest.id,
            status: opportunityRequest.status,
            presenceDate: opportunityRequest.presenceDate,
            attendanceDate: opportunityRequest.attendanceDate,
            fulfillDate: opportunityRequest.fulfillDate,
            fulfillType: opportunityRequest.fulfillType,
            profileId: opportunityRequest.profileId,
            orderId: opportunityRequest.orderId,
            createdAt: opportunityRequest.createdAt,
            updatedAt: opportunityRequest.updatedAt,
        };
        if (opportunityRequest.opportunityId) {
            this.baseRequest.opportunityId = opportunityRequest.opportunityId;
        }
    }

    getId() {
        return (this.baseRequest && this.baseRequest.id) || '';
    }

    getProfile() {
        return this.profile;
    }

    setProfile(profile: IUserProfile) {
        this.profile = profile;
    }

    getOpportunity() {
        return this.opportunity;
    }

    setOpportunity(opportunity: IOpportunity) {
        this.opportunity = opportunity;
    }

    getSuggestion() {
        return this.suggestion;
    }

    confirmFulfill(){
        this.baseRequest.fulfillDate = new Date();
        this.baseRequest.attendanceDate = new Date();
        this.baseRequest.fulfillType = OpportunityRequestFulfillType.fulfilled;
    }

    cancelFulfill(){
        this.baseRequest.fulfillDate = null;
        this.baseRequest.fulfillType = OpportunityRequestFulfillType.none;
    }

    confirmPresence(){
        this.baseRequest.attendanceDate = new Date();
    }

    cancelPresence(){
        this.baseRequest.attendanceDate = null;
        this.baseRequest.fulfillDate = null;
        this.baseRequest.fulfillType = OpportunityRequestFulfillType.none;
    }

    setSuggestion(suggestion?: IOpportunityRequestSuggestion) {
        if (suggestion) {
            this.suggestion = {
                id: suggestion.id,
                status: suggestion.status,
                opportunityRequest: suggestion.opportunityRequest,
                suggested: suggestion.suggested,
                demand: suggestion.demand,
                priority: suggestion.priority,
                solution: suggestion.solution,
            };
        }
    }

    override clone(): OpportunityRequestModel {
        const baseTypeClone = CloneHelper.cloneData(this.baseRequest);
        const clonedModel = new OpportunityRequestModel(baseTypeClone);
        if (this.suggestion) {
            clonedModel.setSuggestion(this.suggestion);
        }
        if (this.profile) {
            clonedModel.setProfile(this.profile);
        }
        if (this.opportunity) {
            clonedModel.setOpportunity(this.opportunity);
        }
        return clonedModel;
    }
}
