import { createReducer, on } from '@ngrx/store';
import { UserModel } from '../../core/models/user.model';
import {
    authenticateFailed,
    clearError,
    recoverPassword,
    recoverPasswordReset,
    recoverPasswordResetSuccess,
    recoverPasswordSuccess,
    refreshToken,
    refreshTokenSuccess,
    signUpActivateAccount,
    signUpActivateAccountSuccess,
    signUpVerificationLetterSent,
    signIn,
    signInRedirect,
    signUp,
    signOut,
    profileUpdate,
    profileUpdateSuccess,
    profileGetSuccess,
    profileGet,
    signUpVerificationLetterRequest,
} from './auth.actions';
import { LoadingState, RefresTokenState, RequestState } from '../../core/models/request-state.model';

export interface IAuthState {
    user: UserModel | null;
    requestState: RequestState;
    tokenState: RefresTokenState;
    redirectUrl: string;
    verificationEmail: string;
}

const initialState: IAuthState = {
    user: null,
    requestState: LoadingState.INIT,
    tokenState: RefresTokenState.INIT,
    redirectUrl: '',
    verificationEmail: '',
};

export const authReducer = createReducer(
    initialState,
    on(authenticateFailed, (state, props) => {
        return {
            ...state,
            requestState: { error: props.error },
        };
    }),
    on(clearError, (state, props) => {
        return {
            ...state,
            requestState: LoadingState.INIT,
            tokenState: RefresTokenState.INIT,
        };
    }),
    on(signUp, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(signIn, (state, props) => {
        return {
            ...state,
            verificationEmail: props.email,
            requestState: LoadingState.LOADING,
        };
    }),
    on(signInRedirect, (state, props) => {
        console.log('reducer: ', signInRedirect);
        return {
            ...state,
            user: props.user,
            verificationEmail: '',
            requestState: LoadingState.LOADED,
        };
    }),
    on(signUpVerificationLetterSent, (state, props) => {
        return {
            ...state,
            requestState: LoadingState.LOADED,
        };
    }),
    on(signUpActivateAccount, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(signUpActivateAccountSuccess, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADED,
        };
    }),
    on(signOut, (state) => {
        console.log('reducer: ', signOut);
        return {
            ...initialState,
        };
    }),
    on(signUpVerificationLetterRequest, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADED,
        };
    }),
    on(recoverPassword, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(recoverPasswordSuccess, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADED,
        };
    }),
    on(recoverPasswordReset, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(recoverPasswordResetSuccess, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADED,
        };
    }),
    on(refreshToken, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
            tokenState: RefresTokenState.REFRESHING,
        };
    }),
    on(refreshTokenSuccess, (state, props) => {
        return {
            ...state,
            user: props.user,
            requestState: LoadingState.LOADED,
            tokenState: RefresTokenState.REFRESHED,
        };
    }),
    on(profileUpdate, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(profileUpdateSuccess, (state, props) => {
        const updatedUser = state.user?.clone();
        if (updatedUser) {
            updatedUser.setProfile(props.profile);

            return {
                ...state,
                user: updatedUser,
                requestState: LoadingState.LOADED,
            };
        } else {
            return {
                ...state,
                requestState: LoadingState.LOADED,
            };
        }
    }),
    on(profileGet, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(profileGetSuccess, (state, props) => {
        const updatedUser = state.user?.clone();
        if (updatedUser) {
            updatedUser.setProfile(props.profile);

            return {
                ...state,
                user: updatedUser,
                requestState: LoadingState.LOADED,
            };
        } else {
            return {
                ...state,
                requestState: LoadingState.LOADED,
            };
        }
    })
);
