<div class="logout">
    <div class="logout__header">
        <h5>
            Вітаємо, <br />
            {{ greetings }}
        </h5>
    </div>
    <div class="logout__email">
        {{ email }}
    </div>
    <div class="logout__action">
        <button
            mat-stroked-button
            color="primary"
            class="logout__button button-grey"
            (click)="onLogout()">
            Вийти
        </button>
    </div>
</div>
