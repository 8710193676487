<div
    class="empty-table-wrapper mat-elevation-z1"
    [ngClass]="{ 'empty-table-wrapper--white': white}"
    fxLayoutAlign="center"
    fxLayout="row">
    <div class="empty-table">
        <div class="empty-table__icon">
            <img
                src="/assets/images/icon-success.png"
                alt="success" />
        </div>
        <div class="empty-table__header">
            <h3>Намає записів</h3>
        </div>
    </div>
</div>
