export interface IErrorModel {
    status: string;
    exception: string;
    statusCode: number;
    message: string;
    response: {
        statusCode: number;
        message: string;
        error: string;
    };
}

export interface IInternalException {
    error: string;
    initialError: string;
}

export enum ExceptionArea {
    client = 'Client',
    admin = 'Admin',
    auth = 'Auth',
}