import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';
import { CommonDialogComponent, CommonDialogIconType, CommonDialogButtonType } from '../common-dialog/common-dialog.component';
import { Gender, IUserProfile, ProfileStatus, UserModel } from 'src/app/modules/core/models/user.model';
import * as AuthActions from '../../../auth/store/auth.actions';
import { toInternationalPhoneFormat } from 'src/app/modules/core/helpers/phone.helper';
import { EMPTY_BASE_ADDRESS, IAddress } from 'src/app/modules/core/models/address.model';
import { validateDocument, validateMainBirthday, validateTemporaryId, validateZip } from 'src/app/modules/core/validators/documents.validator';
import { MatStepper } from '@angular/material/stepper';
import { DateHelper } from 'src/app/modules/core/helpers/date.helper';

export interface IFormErrorResult {
    stepIndex: number;
    errorMessage: string;
}

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
    userSettingsForm: FormGroup;
    @ViewChild('stepper') private stepper!: MatStepper;
    maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));

    @Output() updateProfileEvent = new EventEmitter<IUserProfile>();
    @Input() adminMode = false;
    @Input() memberMode = false;
    @Input() set profile(value: IUserProfile | null) {
        if (value) {
            this.userSettingsForm = this.getFormGroupFromUserData(value);
        }
    }

    private storeSubscription: Subscription = new Subscription();

    constructor(public dialog: MatDialog, private store: Store<IApplicationState>) {
        this.userSettingsForm = this.getInitalUserSettingsFormGroup();
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }

    onSubmit(): void {
        if (this.userSettingsForm.valid) {
            console.log(this.getUserUserPersonalData());
            const updatedProfile = this.getUserUserPersonalData();
            this.updateProfileEvent.emit(updatedProfile);
            this.stepper.selectedIndex = 0;
        } else {
            console.log(this.userSettingsForm);
            const formErrors = this.getFormErrors();
            this.stepper.selectedIndex = formErrors.stepIndex;
            this.dialog.open(CommonDialogComponent, {
                data: {
                    header: 'Сталася помилка',
                    content: 'Невірно заповнені дані. Уважно перевірте всі кроки та заповніть всю необхідну інформацію.',
                    errors: formErrors.errorMessage,
                    icon: CommonDialogIconType.Question,
                    buttonType: CommonDialogButtonType.Text,
                    actionText: 'Зрозуміло',
                },
            });
        }
    }

    getInitalUserSettingsFormGroup(): FormGroup {
        const addditionalPersons: FormGroup[] = [];
        const personalDataSettingsAdditionalPersons = new FormArray(addditionalPersons);

        const personalDataSettingsAddress = new FormGroup({
            id: new FormControl(''),
            region: new FormControl('', [Validators.required]),
            city: new FormControl('', [Validators.required]),
            address1: new FormControl(''),
            zip: new FormControl('', [Validators.required]),
        });

        const personalDataSettingsGroup = new FormGroup({
            id: new FormControl(''),
            firstName: new FormControl('', [Validators.required]),
            lastName: new FormControl('', [Validators.required]),
            parent: new FormControl(''),
            status: new FormControl(''),
            email: new FormControl(''),
            phone: new FormControl('', [Validators.required]),
            birthday: new FormControl(new Date(), [Validators.required]),
            gender: new FormControl(Gender.male, [Validators.required]),
            passport: new FormControl('', [Validators.required, validateDocument]),
            temporaryId: new FormControl(''),
            hasInjury: new FormControl(false, [Validators.required]),
            agreeProcess: new FormControl(false, [Validators.requiredTrue]),
            haveRead: new FormControl(false, [Validators.requiredTrue]),
            agree: new FormControl(false, [Validators.requiredTrue]),
            address: personalDataSettingsAddress,
            additionalPersons: personalDataSettingsAdditionalPersons,
        });

        return personalDataSettingsGroup;
    }

    getFormGroupFromUserData(profile: IUserProfile): FormGroup {
        const addditionalPersons: FormGroup[] = [];
        const personalDataSettingsAdditionalPersons = new FormArray(addditionalPersons);
        if (profile.additionalProfiles) {
            for (let person of profile.additionalProfiles) {
                const personalDataSettingsAdditionalPerson = new FormGroup({
                    id: new FormControl(person.id),
                    parent: new FormControl(profile.id),
                    firstName: new FormControl(person.firstName, [Validators.required]),
                    lastName: new FormControl(person.lastName, [Validators.required]),
                    birthday: new FormControl(person.birthday, [Validators.required]),
                    gender: new FormControl(person.gender.toString(), [Validators.required]),
                    passport: new FormControl(person.passport, [Validators.required, validateDocument()]),
                    temporaryId: new FormControl(person.temporaryId, validateTemporaryId()),
                    hasInjury: new FormControl(person.hasInjury, [Validators.required]),
                });
                personalDataSettingsAdditionalPersons.push(personalDataSettingsAdditionalPerson);
            }
        }

        const address = profile.addresses.length ? profile.addresses[0] : EMPTY_BASE_ADDRESS;
        const personalDataSettingsAddress = new FormGroup({
            id: new FormControl(address.id),
            region: new FormControl(address.region, [Validators.required]),
            city: new FormControl(address.city, [Validators.required]),
            address1: new FormControl(address.address1),
            zip: new FormControl(address.zip, [Validators.required, validateZip()]),
        });

        const mandatoryFields = {
            id: new FormControl(profile.id, [Validators.required]),
            email: new FormControl(profile.email, [Validators.required]),
            status: new FormControl(profile.status, [Validators.required]),
            parent: new FormControl(''),
            firstName: new FormControl(profile.firstName, [Validators.required]),
            lastName: new FormControl(profile.lastName, [Validators.required]),
            phone: new FormControl(profile.phone, [Validators.required]),
            birthday: new FormControl(profile.id ? profile.birthday : this.maxDate, [Validators.required, validateMainBirthday()]),
            gender: new FormControl(profile.gender ? profile.gender.toString() : Gender.male.toString(), [Validators.required]),
            passport: new FormControl(profile.passport, [Validators.required, validateDocument()]),
            temporaryId: new FormControl(profile.temporaryId, validateTemporaryId()),
            hasInjury: new FormControl(profile.hasInjury, [Validators.required]),
            address: personalDataSettingsAddress,
            additionalPersons: personalDataSettingsAdditionalPersons,
        };

        const optionalFields = {
            agreeProcess: new FormControl(profile.status === ProfileStatus.new ? false : true, [Validators.requiredTrue]),
            haveRead: new FormControl(profile.status === ProfileStatus.new ? false : true, [Validators.requiredTrue]),
            agree: new FormControl(profile.status === ProfileStatus.new ? false : true, [Validators.requiredTrue]),
        };

        if (this.adminMode) {
            const personalDataSettingsGroup = new FormGroup({
                ...mandatoryFields,
            });

            return personalDataSettingsGroup;
        } else {
            const personalDataSettingsGroup = new FormGroup({
                ...mandatoryFields,
                ...optionalFields,
            });

            return personalDataSettingsGroup;
        }
    }

    getAdditionalPersons() {
        const additionPersons = this.userSettingsForm.get('additionalPersons') as FormArray;
        return additionPersons;
    }

    onRemovedditionPersonsHandler(index: number) {
        const additionPersons = this.userSettingsForm.get('additionalPersons') as FormArray;
        if (additionPersons) {
            additionPersons.removeAt(index);
        }
    }

    onAddAdditionPersonsHandler() {
        const additionPersons = this.userSettingsForm.get('additionalPersons') as FormArray;
        if (additionPersons) {
            additionPersons.push(
                new FormGroup({
                    id: new FormControl(''),
                    parent: new FormControl(''),
                    firstName: new FormControl('', [Validators.required]),
                    lastName: new FormControl('', [Validators.required]),
                    birthday: new FormControl(new Date(), [Validators.required]),
                    gender: new FormControl(Gender.male, [Validators.required]),
                    passport: new FormControl('', [Validators.required, validateDocument()]),
                    temporaryId: new FormControl('', validateTemporaryId()),
                    hasInjury: new FormControl(false, [Validators.required]),
                })
            );
        }
    }

    getUserUserPersonalData(): IUserProfile {
        const { id, email, birthday, firstName, lastName, passport, temporaryId, phone, gender, hasInjury, address, additionalPersons } = this.userSettingsForm.value;
        let userProfileData: IUserProfile;
        
        userProfileData = {
            id,
            birthday: DateHelper.GetDate(birthday),
            firstName,
            lastName,
            passport: passport.trim(),
            temporaryId: temporaryId ? temporaryId.trim() : '',
            phone: toInternationalPhoneFormat(phone),
            email,
            status: ProfileStatus.active,
            gender,
            hasInjury,
            addresses: [address],
            additionalProfiles: [],
        };

        for (let person of additionalPersons) {
            const personObject: IUserProfile = {
                id: person.id,
                parent: id,
                status: ProfileStatus.active,
                firstName: person.firstName,
                lastName: person.lastName,
                birthday: DateHelper.GetDate(person.birthday),
                gender: person.gender,
                passport: person.passport.trim(),
                temporaryId: person.temporaryId ? person.temporaryId.trim() : '',
                hasInjury: person.hasInjury,
                addresses: [],
            };
            if (userProfileData.additionalProfiles) {
                userProfileData.additionalProfiles.push(personObject);
            }
        }

        return userProfileData;
    }

    getFormErrors(): IFormErrorResult {
        const result: IFormErrorResult = {
            errorMessage: '',
            stepIndex: 0,
        };
        const { email, birthday, firstName, lastName, passport, temporaryId, phone, address, additionalPersons } = this.userSettingsForm.controls;
        const { region, city, zip } = (address as FormGroup).controls;

        let mainProfileErrors = '';
        let additionalProfilesErrors = '';

        if (email.errors) {
            mainProfileErrors = `
                ${mainProfileErrors}<br /> 
                - Email.
            `;
        }
        if (firstName.errors) {
            mainProfileErrors = `
                ${mainProfileErrors}<br /> 
                - Ім'я.
            `;
        }
        if (lastName.errors) {
            mainProfileErrors = `
                ${mainProfileErrors}<br /> 
                - Прізвище.
            `;
        }
        if (passport.errors) {
            mainProfileErrors = `
                ${mainProfileErrors}<br /> 
                - Серія та номер паспорта або свідоцтво. Очікуваний формат: АА111111, 123456789, 1-ЯЯ123123
            `;
        }
        if (temporaryId.errors) {
            mainProfileErrors = `
                ${mainProfileErrors}<br /> 
                - Номер ВПО (за наявності). Очікуваний формат: 1234-1234567890
            `;
        }
        if (phone.errors) {
            mainProfileErrors = `
                ${mainProfileErrors}<br /> 
                - Телефон.
            `;
        }
        if (birthday.errors) {
            mainProfileErrors = `
                ${mainProfileErrors}<br /> 
                - Дата народження.
            `;
        }
        if (region.errors) {
            mainProfileErrors = `
                ${mainProfileErrors}<br /> 
                - Область.
            `;
        }
        if (city.errors) {
            mainProfileErrors = `
                ${mainProfileErrors}<br /> 
                - Населений пункт.
            `;
        }
        if (zip.errors) {
            mainProfileErrors = `
                ${mainProfileErrors}<br /> 
                - Індекс.
            `;
        }

        if (mainProfileErrors) {
            mainProfileErrors = `
                <b>Необхідно виправити особисті дані:</b><br />
                ${mainProfileErrors} 
            `;
        }

        if (additionalPersons && additionalPersons instanceof FormArray) {
            const additionalPersonsControls = (additionalPersons as FormArray).controls;
            additionalPersonsControls.forEach((person, index) => {
                const { birthday, firstName, lastName, passport, temporaryId } = (person as FormGroup).controls;
                let additionalProfileErrors = '';
                if (firstName.errors) {
                    additionalProfileErrors = `
                        ${additionalProfileErrors}<br /> 
                        - Ім'я.
                    `;
                }
                if (lastName.errors) {
                    additionalProfileErrors = `
                        ${additionalProfileErrors}<br /> 
                        - Прізвище.
                    `;
                }
                if (passport.errors) {
                    additionalProfileErrors = `
                        ${additionalProfileErrors} 
                        - Серія та номер паспорта або Очікуваний формат: АА111111, 123456789, 1-ЯЯ123123
                    `;
                }
                if (temporaryId.errors) {
                    additionalProfileErrors = `
                        ${additionalProfileErrors}<br /> 
                        - Номер ВПО (за наявності). Очікуваний формат: 1234-1234567890
                    `;
                }
                if (birthday.errors) {
                    additionalProfileErrors = `
                        ${additionalProfileErrors}<br /> 
                        - Дата народження.
                    `;
                }

                if (additionalProfileErrors) {
                    additionalProfileErrors = `
                        <b>Необхідно дані з анкети №${index + 1}:</b><br />
                        ${additionalProfileErrors} 
                    `;

                    additionalProfilesErrors = `
                    ${additionalProfilesErrors}<br />
                    ${additionalProfileErrors}<br />`;
                }
            });
        }

        if (mainProfileErrors) {
            result.errorMessage = `
                ${mainProfileErrors}<br /><br />
                ${additionalProfilesErrors}
            `;
            result.stepIndex = 0;
        } else if (additionalProfilesErrors) {
            result.errorMessage = `
            ${additionalProfilesErrors}
            `;
            result.stepIndex = 1;
        }

        return result;
    }
}
