import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import * as AuthActions from '../../../auth/store/auth.actions';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnDestroy {
    email = '';
    greetings = '';
    private storeSubscription: Subscription = new Subscription();
    constructor(private store: Store<IApplicationState>) {}

    ngOnInit(): void {
        this.storeSubscription = this.store.select('auth').subscribe((authState) => {
            if (authState.user) {
                const email = authState.user.getProfile().email ?? '';
                const firstName = authState.user.getProfile().firstName ?? '';
                const lastName = authState.user.getProfile().lastName ?? '';

                this.email = authState.user.getProfile().email ?? '';

                if(firstName || lastName) {
                    this.greetings = `${firstName} ${lastName}`;
                } else {
                    this.greetings = email;
                }
                
            }
        });
    }

    onLogout() {
        this.store.dispatch(AuthActions.signOut());
    }

    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }
}
