import { CloneHelper } from '../../core/helpers/clone.helper';
import { IAddress } from '../../core/models/address.model';
import { IUserProfile, UserModel } from '../../core/models/user.model';
import { IAuthResponseData } from '../models/auth.model'

export const DEFAULT_ADDRESS_COUNTRY = 'Ukraine';

export const fromAuthResponseToUserModel = (authResponse: IAuthResponseData): UserModel => {
    const user = authResponse.user;
    const profle = authResponse.profile;
    user.accessToken = authResponse.accessToken;
    user.refreshToken = authResponse.refreshToken;

    const userModel = new UserModel(user);
    userModel.setProfile(profle);
    return userModel;
}

export const fromProfileToProfileRequest = (profle: IUserProfile): IUserProfile => {
    const updateProfile = CloneHelper.cloneData(profle);
    const address = updateProfile.addresses[0];
    const updateAddress: IAddress = {
        country: DEFAULT_ADDRESS_COUNTRY,
        region: address.region,
        city: address.city,
        address1: address.address1,
        zip: address.zip,
    };
    if(address.id) {
        updateAddress.id = address.id;
    }
    updateProfile.addresses = [updateAddress];
    updateProfile.additionalProfiles?.forEach(profile => {
        if(!profile.id) {
            delete profile.id;            
        }
        delete profile.parent;            
    })

    if(updateProfile.id) {
        delete updateProfile.id;
    }
    return updateProfile;
}