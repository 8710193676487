import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { NavTabType } from 'src/app/modules/core/models/nav-tabs.model';
import { UserModel } from 'src/app/modules/core/models/user.model';

@Component({
    selector: 'app-home-main',
    templateUrl: './home-main.component.html',
    styleUrls: ['./home-main.component.scss'],
})
export class HomeMainComponent implements OnInit, OnDestroy {
    private storeSubscription: Subscription = new Subscription();
    user: UserModel | null = null;

    constructor(private router: Router, private store: Store<IApplicationState>) {}

    ngOnInit(): void {
        this.storeSubscription = this.store.select('auth').subscribe((authState) => {
            this.user = authState.user;
        });
    }

    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }

    navigateToLogin() {
        this.router.navigate(['auth'], { queryParams: { type: NavTabType.Login } });
    }

    navigateToRegister() {
        this.router.navigate(['auth'], { queryParams: { type: NavTabType.Register } });
    }

    navigateToMain() {
        this.router.navigate(['client']);
    }

    navigateToSettings() {
        this.router.navigate(['client/settings']);
    }
}
