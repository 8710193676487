import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import * as AdminActions from 'src/app/modules/admin/store/admin.actions';
import { EMPTY_OPPORTUNITY, OpportunityModel, OpportunityStatus } from 'src/app/modules/core/models/opportunity.model';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';

@Component({
    selector: 'app-admin-opportunities',
    templateUrl: './admin-opportunities.component.html',
    styleUrls: ['./admin-opportunities.component.scss'],
})
export class AdminOpportunitiesComponent implements OnInit, OnDestroy {
    isLoading = false;
    error: string | null = '';
    dataSource: OpportunityModel[] = [];

    private storeSubscription: Subscription = new Subscription();

    constructor(private store: Store<IApplicationState>, private dialog: MatDialog, private router: Router) {}

    ngOnInit(): void {
        this.storeSubscription = this.store.select('admin').subscribe((adminState) => {
            this.isLoading = adminState.requestState === LoadingState.LOADING;
            this.error = getError(adminState.requestState);
            this.dataSource = adminState.opportunities;
        });
        this.store.dispatch(AdminActions.opportunitiesGet());
    }
    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }

    onOpportunityAdd() {
        this.router.navigate(['admin', 'opportunity', 'new']);
    }

    getActiveOpportnuties() {
        return this.dataSource.filter(
            (opp) =>
                opp.baseOpportunity.status === OpportunityStatus.active ||
                opp.baseOpportunity.status === OpportunityStatus.approved ||
                opp.baseOpportunity.status === OpportunityStatus.new ||
                opp.baseOpportunity.status === OpportunityStatus.pending
        );
    }

    getInactiveOpportnuties() {
        return this.dataSource.filter((opp) => opp.baseOpportunity.status === OpportunityStatus.inactive);
    }
}
