import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { OpportunityModel, OpportunityStatus } from 'src/app/modules/core/models/opportunity.model';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';
import * as ClientActions from '../../store/client.actions';
import { OpportunityRequestModel, OpportunityRequestStatus } from 'src/app/modules/core/models/opportunity-request.model';

@Component({
    selector: 'app-client-opportunity-requests',
    templateUrl: './client-opportunity-requests.component.html',
    styleUrls: ['./client-opportunity-requests.component.scss'],
})
export class ClientOpportunityRequestsComponent implements OnInit, OnDestroy {
    isLoading = false;
    error: string | null = '';
    dataSource: OpportunityRequestModel[] = [];

    private storeSubscription: Subscription = new Subscription();

    constructor(private store: Store<IApplicationState>, private dialog: MatDialog) {}

    ngOnInit(): void {
        this.storeSubscription = this.store.select('client').subscribe((clientState) => {
            this.isLoading = clientState.requestState === LoadingState.LOADING;
            this.error = getError(clientState.requestState);
            this.dataSource = clientState.opportunitiesRequests;
        });
        this.store.dispatch(ClientActions.opportunitiesRequestGet());
    }
    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }

    getActiveOpportnutiesRequests() {
        return this.dataSource.filter(
            (oppRequest) =>
                oppRequest.getOpportunity()!.status !== OpportunityStatus.inactive &&
                (oppRequest.baseRequest.status === OpportunityRequestStatus.approved || oppRequest.baseRequest.status === OpportunityRequestStatus.new)
        );
    }

    getInactiveOpportnutiesRequests() {
        return this.dataSource.filter(
            (oppRequest) =>
                oppRequest.getOpportunity()!.status === OpportunityStatus.inactive ||
                oppRequest.baseRequest.status === OpportunityRequestStatus.canceled ||
                oppRequest.baseRequest.status === OpportunityRequestStatus.deleted
        );
    }

    getOpportunityModel(opportunityRequest: OpportunityRequestModel) {
        const opportunity = new OpportunityModel(opportunityRequest.getOpportunity());
        opportunity.setOpportunityRequest(opportunityRequest);
        return opportunity;
    }
}
