import {ActionReducerMap} from '@ngrx/store';
import {authReducer, IAuthState} from './modules/auth/store/auth.reducer';
import { clientReducer, IClientState } from './modules/client/store/client.reducer';
import { admintReducer, IAdminState } from './modules/admin/store/admin.reducer';

export interface IApplicationState {
    auth: IAuthState;
    client: IClientState,
    admin: IAdminState

}

export const appReducer: ActionReducerMap<IApplicationState> = {
    auth: authReducer,
    client: clientReducer,
    admin: admintReducer
};
