<h1 mat-dialog-title>
    <span>Деталі</span>
</h1>
<form
    [formGroup]="genericTypeForm"
    (ngSubmit)="onSubmit()">
    <input type="hidden" formControlName="id">
    <input type="hidden" formControlName="shortDescription">
    <input type="hidden" formControlName="status">
    <div
        mat-dialog-content
        class="generic-types">
        <div class="form-row">
            <mat-form-field
                [floatLabel]="'always'"
                appearance="fill"
                class="generic-types__input">
                <mat-label>Назва</mat-label>
                <input
                    matInput
                    placeholder="Назва типу"
                    formControlName="name" />
                <mat-error *ngIf="genericTypeForm.controls['name'].hasError('required')"> Назва <strong>Обов'язкова</strong> </mat-error>
            </mat-form-field>
        </div>
        <div class="form-row">
            <mat-form-field
                [floatLabel]="'always'"
                appearance="fill"
                class="generic-types__input">
                <mat-label>Опис</mat-label>
                <input
                    matInput
                    placeholder="Опис типу"
                    formControlName="description" />
            </mat-form-field>
        </div>
        <div class="form-row">
            <mat-form-field            
                appearance="fill"
                [floatLabel]="'always'"
                class="generic-types__input">
                <mat-label>Категорія</mat-label>
                <mat-select formControlName="source">
                    <mat-option
                        *ngFor="let source of genericTypeSources"
                        [value]="source.value">
                        {{ source.value | translateEnum : 'GenericTypeSource' }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-row" fxLayout="row" fxLayoutAlign="center">
            <div class="generic-types__footer">
                <button
                    mat-stroked-button
                    class="generic-types__button button-grey"
                    mat-dialog-close>
                    Скасувати
                </button>
                <button
                    mat-stroked-button
                    color="primary"
                    class="generic-types__button button-light-blue"
                    type="submit">
                    Зберегти
                </button>
            </div>
        </div>
    </div>
</form>
