import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { IApplicationState } from 'src/app/app.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import * as AdminActions from 'src/app/modules//admin/store/admin.actions';
import { IOpportunityRequestSuggestion, OpportunityRequestFulfillType, OpportunityRequestModel, OpportunityRequestStatus } from 'src/app/modules/core/models/opportunity-request.model';
import { Subscription } from 'rxjs';
import { getError } from 'src/app/modules/core/models/request-state.model';
import { fromGenericTypesToOpportunityTypes, fromGenericTypesToOpportunityAvailableCategories } from '../../store/admin.utils';
import { IEnumToObject, enumToObjectsList } from 'src/app/modules/core/helpers/enum.helper';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { EMPTY_PROFILE, IUserProfile } from 'src/app/modules/core/models/user.model';
import { isMobile } from 'src/app/modules/core/helpers/mobile.helper';
import { OpportunityRequestSuggestionComponent, OpportunityRequestSuggestionIconType } from '../opportunity-request-suggestion/opportunity-request-suggestion.component';
import { PersonAgeDistrinutiontType, ProfileHelper } from 'src/app/modules/core/helpers/profile.helper';
import { formatDate } from '@angular/common';
import { utils, writeFile } from 'xlsx-js-style';
import { PageEvent } from '@angular/material/paginator';
import { OpportunityModel } from 'src/app/modules/core/models/opportunity.model';
import { CommonPromptComponent, CommonPromptIconType } from 'src/app/modules/shared/components/common-prompt/common-prompt.component';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { CommonDialogButtonType, CommonDialogComponent, CommonDialogIconType } from 'src/app/modules/shared/components/common-dialog/common-dialog.component';

type massUpdateType = 'status-change' | 'confirm-presence' | 'cancel-presence' | 'confirm-fulfillment' | 'cancel-fulfillment';
@Component({
    selector: 'app-admin-opportunity-requests',
    templateUrl: './admin-opportunity-requests.component.html',
    styleUrls: ['./admin-opportunity-requests.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class AdminOpportunityRequestsComponent {
    isLoading = false;
    error: string | null = '';
    filterForm: FormGroup;
    expandedElement: OpportunityRequestModel | null = null;
    private storeSubscription: Subscription = new Subscription();
    private formSubscription: Subscription = new Subscription();
    opportunityId = '';
    opportunity: OpportunityModel | null = null;
    opportunityRequests: OpportunityRequestModel[] = [];
    filteredOpportunityRequests: OpportunityRequestModel[] = [];
    expandedOpportunityRequests: Map<string, OpportunityRequestModel> = new Map();
    opportunityRequestsUserProfiles: Map<string, IUserProfile> = new Map();

    opportunityRequestsColumns = ['select', 'username', 'attendance', 'fulfillment', 'suggestment', 'order', 'status'];
    opportunityRequestsColumnsMobile = ['username'];
    opportunityRequestsColumnsWithExpand: string[] = [...this.getColumns(), 'expand'];
    opportunityRequestElementStatuses = enumToObjectsList(OpportunityRequestStatus);

    massUpdateStatusId = '';

    selection = new SelectionModel<OpportunityRequestModel>(true, []);
    tableDataSource = new MatTableDataSource<OpportunityRequestModel>([]);

    opportunityRequestStatuses: IEnumToObject[] = [
        { value: 'new', key: 'Нова' },
        { value: 'approved', key: 'Схвалена' },
        { value: 'canceled', key: 'Відхилена' },
    ];
    opportunityRequestStates: IEnumToObject[] = [
        { value: 'fulfilled', key: 'Отримав' },
        { value: 'visited', key: 'Відвідав' },
        { value: 'recommended', key: 'Рекомедація' },
    ];
    opportunityTypeCtrl = new FormControl('');

    length = 0;
    pageSize = 20;
    pageIndex = 0;
    pageSizeOptions = [20, 100, 500];

    constructor(public dialog: MatDialog, private store: Store<IApplicationState>, private currentRoute: ActivatedRoute, private router: Router) {
        this.filterForm = this.getInitalOpportunityRequestsFilterFormGroup();
        this.pageIndex = 0;
    }

    ngOnInit(): void {
        this.currentRoute.params.subscribe((currentParams) => {
            this.opportunityId = currentParams['opportunityId'];
            if (this.opportunityId) {
                this.store.dispatch(
                    AdminActions.opportunityRequestsGet({
                        opportunityId: this.opportunityId,
                        limit: this.pageSize,
                        page: this.pageIndex,
                        attended: false,
                        fulfilled: false,
                        statuses: [],
                        suggested: false,
                    })
                );

                this.storeSubscription = this.store.select('admin').subscribe((adminState) => {
                    this.error = getError(adminState.requestState);
                    // if (!this.opportunityRequests.length && adminState.opportunityRequests.length) {

                    this.opportunityRequests = adminState.opportunityRequests.map((r) => r.clone());
                    this.length = adminState.opportunityRequestsTotal;
                    this.applyFilterToOpportunitiesRequestList();
                    console.log('admin-opportunity-requests.component', this.opportunityRequests);

                    if (adminState.opportunityRequestsUserProfiles.size) {
                        this.opportunityRequestsUserProfiles = new Map(adminState.opportunityRequestsUserProfiles);
                    }

                    if (adminState.opportunity && this.opportunityId) {
                        this.opportunity = adminState.opportunity.clone();
                    }
                });
            } else {
                this.opportunityRequests = [];
            }
        });

        this.formSubscription = this.filterForm.valueChanges.subscribe((x) => {
            this.processSearch();
        });
    }

    processSearch() {
        const searchParams = this.getSearchParams();
        this.store.dispatch(AdminActions.opportunityRequestsGet(searchParams));
    }

    getSearchParams() {
        const { statuses, states } = this.filterForm.value;
        const attended = states.some((s: string) => s === OpportunityRequestFulfillType.visited);
        const suggested = states.some((s: string) => s === OpportunityRequestFulfillType.recommended);
        const fulfilled = states.some((s: string) => s === OpportunityRequestFulfillType.fulfilled);

        const searchParams = {
            opportunityId: this.opportunityId,
            limit: this.pageSize,
            page: this.pageIndex,
            attended,
            fulfilled,
            statuses,
            suggested,
        };

        return searchParams;
    }

    applyFilterToOpportunitiesRequestList() {
        this.filteredOpportunityRequests = [...this.opportunityRequests];
        this.tableDataSource = new MatTableDataSource<OpportunityRequestModel>(this.filteredOpportunityRequests);
    }

    onSubmit(): void {}

    onEditSuggestment(opportunitRequest: OpportunityRequestModel) {
        this.dialog.open(OpportunityRequestSuggestionComponent, {
            data: {
                icon: OpportunityRequestSuggestionIconType.Success,
                opportunityRequest: opportunitRequest,
                callback: this.processSaveSuggestment.bind(this),
            },
        });
    }

    processSaveSuggestment(opportunityRequest: OpportunityRequestModel) {
        const opportunityRequestToUpdate = opportunityRequest.clone();
        this.store.dispatch(AdminActions.opportunityRequestUpdate({ opportunityRequest: opportunityRequestToUpdate }));
    }

    onOpportunityRequestStatusChange(event: MatSelectChange, opportunityRequest: OpportunityRequestModel) {
        console.log('onOpportunityRequestStatusChange', event.value);
        const updatedStatus = event.value;
        const opportunityRequestToUpdate = opportunityRequest.clone();
        opportunityRequestToUpdate.baseRequest.status = updatedStatus;
        this.store.dispatch(AdminActions.opportunityRequestUpdate({ opportunityRequest: opportunityRequestToUpdate }));
    }

    getInitalOpportunityRequestsFilterFormGroup(): FormGroup {
        const opportunityRequestsFilterFormGroup = new FormGroup({
            statuses: new FormControl([]),
            states: new FormControl([]),
        });

        return opportunityRequestsFilterFormGroup;
    }

    deleteToOpportunityRequest(opportunityRequest: OpportunityRequestModel) {
        this.dialog.open(CommonPromptComponent, {
            data: {
                icon: CommonPromptIconType.Question,
                header: 'Видалити заявку?',
                content: 'Ви впевнені, что бажаєте видалити заявку? Користувач не буде про це сповіщенний!',
                data: opportunityRequest,
                callback: this.processDeleteToOpportunityRequest.bind(this),
            },
        });
    }

    processDeleteToOpportunityRequest(opportunityRequest: OpportunityRequestModel) {
        this.store.dispatch(AdminActions.opportunityRequestDelete({ opportunityRequest }));
    }

    navigateToOpportunityRequestPage(opportunityRequest: OpportunityRequestModel) {
        const url = this.router.serializeUrl(this.router.createUrlTree(['admin', 'opportunity', 'request', opportunityRequest.getId()]));
        window.open(url, '_blank');
    }

    toggleExpandButton(event: MouseEvent, opportunityRequest: OpportunityRequestModel) {
        this.toggleExpandState(opportunityRequest);
        const oppId = opportunityRequest.getId();
        const profileId = (opportunityRequest.profile && opportunityRequest.profile.id) || '';
        event.stopPropagation();

        if (!this.opportunityRequestsUserProfiles.has(profileId) && this.opportunityRequestIsExpanded(opportunityRequest)) {
            this.store.dispatch(AdminActions.userProfileGet({ profileId }));
        }
    }

    toggleExpandState(opportunityRequest: OpportunityRequestModel) {
        const oppId = opportunityRequest.getId();
        if (this.opportunityRequestIsExpanded(opportunityRequest)) {
            this.expandedOpportunityRequests.delete(oppId);
        } else {
            this.expandedOpportunityRequests.set(oppId, opportunityRequest);
        }
    }

    opportunityRequestIsExpanded(opportunityRequest?: OpportunityRequestModel) {
        if (opportunityRequest) {
            const oppId = opportunityRequest.getId();
            return oppId && this.expandedOpportunityRequests.has(oppId);
        } else {
            return false;
        }
    }

    getProfile(opportunityRequest: OpportunityRequestModel): IUserProfile {
        const profileId = (opportunityRequest.profile && opportunityRequest.profile.id) || '';
        const profile = this.opportunityRequestsUserProfiles.get(profileId) || EMPTY_PROFILE;
        return profile;
    }

    getPresenceStatusClasses(value?: number) {
        // switch (value) {
        //     case OpportunityRequestPresenceStatus.new:
        //         return {
        //             'opportunity-requests__label': true,
        //             'opportunity-requests__label--gray': true
        //         };
        //     case OpportunityRequestPresenceStatus.presenceConfirmationNeeded:
        //         return {
        //             'opportunity-requests__label': true,
        //             'opportunity-requests__label--orange': true
        //         };
        //     case OpportunityRequestPresenceStatus.presenceConfirmed:
        //         return {
        //             'opportunity-requests__label': true,
        //             'opportunity-requests__label--green': true
        //         };
        //     case OpportunityRequestPresenceStatus.presenced:
        //         return {
        //             'opportunity-requests__label': true,
        //             'opportunity-requests__label--green': true
        //         };
        // }

        return {
            'opportunity-requests__label': true,
            'opportunity-requests__label--gray': true,
        };
    }

    getLabelClasses(value?: string | Date | number | boolean) {
        if (value) {
            return {
                'opportunity-requests__label': true,
                'opportunity-requests__label--green': true,
            };
        }
        return {
            'opportunity-requests__label': true,
            'opportunity-requests__label--gray': true,
        };
    }

    getSuggestmentLabelClasses(value?: string) {
        // if(value && value.suggested){
        //     return {
        //         'opportunity-requests__label': true,
        //         'opportunity-requests__label--green': true
        //     }
        // }
        return {
            'opportunity-requests__label': true,
            'opportunity-requests__label--gray': true,
        };
    }

    onGetSuggestmentHelp() {
        this.dialog.open(CommonPromptComponent, {
            data: {
                icon: CommonPromptIconType.Question,
                header: 'Виконати процедуру автоматичного розподілу?',
                content: 'УВАГА! Розподіл буде здійснений по всім заявкам та застосує релузьтат до всіх заявок, навіть до тих, що зараз не є на екрані!',
                data: {},
                callback: this.processGetSuggestmentHelp.bind(this),
            },
        });
    }

    processGetSuggestmentHelp() {
        if (this.opportunity) {
            const searchParams = this.getSearchParams();
            this.store.dispatch(AdminActions.opportunitySuggestmentHelpGet(searchParams));
        }
    }

    onExcelExport() {
        if (this.opportunity) {
            const searchParams = this.getSearchParams();
            this.store.dispatch(AdminActions.opportunityRequestsReportGet(searchParams));
        }
    }

    ngOnDestroy(): void {
        this.opportunityRequests = [];
        this.opportunityId = '';
        this.expandedOpportunityRequests = new Map();
        this.storeSubscription.unsubscribe();
        this.formSubscription.unsubscribe();
    }

    processItem(item: any) {
        return JSON.stringify(item);
    }

    getTotalCount() {
        return this.opportunityRequests.length;
    }

    getApprovedCount() {
        return this.opportunityRequests.filter((o) => o.baseRequest.status === OpportunityRequestStatus.approved).length;
    }

    getColumns() {
        return isMobile() ? this.opportunityRequestsColumnsMobile : this.opportunityRequestsColumns;
    }

    isMobile() {
        return isMobile();
    }

    onPageChange(event: PageEvent) {
        this.length = event.length;
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;

        this.processSearch();
    }

    roundSuggestion(value: number | null) {
        if (value) {
            return Math.round(value * 100) / 100;
        } else {
            return null;
        }
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.tableDataSource.data.length;

        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            console.log(this.selection);
            return;
        }

        this.selection.select(...this.tableDataSource.data);
    }

    onMassUpdate(command: massUpdateType) {
        if (command === 'status-change' && !this.massUpdateStatusId) {
            this.dialog.open(CommonDialogComponent, {
                data: {
                    icon: CommonDialogIconType.Question,
                    header: 'Потрідно вибрати статус!',
                    buttonType: CommonDialogButtonType.Text,
                },
            });
            return;
        }

        const content = `Ви впевнені, что бажаєте масово оновити заявки? УВАГА! Усі імейли на відповидні флому буде застосовано до всіх обраних заявок, незважаючі на їх поточний стан.(${command})`;
        this.dialog.open(CommonPromptComponent, {
            data: {
                icon: CommonPromptIconType.Question,
                header: 'Оновити заявки?',
                content,
                data: command,
                callback: this.processMassUpdate.bind(this),
            },
        });
    }

    processMassUpdate(command: massUpdateType) {
        console.log('select', this.selection, 'command', command, 'status', this.massUpdateStatusId);
        const opportunityRequestsToUpdate = this.selection.selected.map((or) => or.clone());

        switch (command) {
            case 'cancel-fulfillment':
                opportunityRequestsToUpdate.forEach((or) => {
                    or.cancelFulfill();
                });
                break;
            case 'confirm-fulfillment':
                opportunityRequestsToUpdate.forEach((or) => {
                    or.confirmFulfill();
                });
                break;
            case 'cancel-presence':
                opportunityRequestsToUpdate.forEach((or) => {
                    or.cancelPresence();
                });
                break;
            case 'confirm-presence':
                opportunityRequestsToUpdate.forEach((or) => {
                    or.confirmPresence();
                });
                break;
            case 'status-change':
                opportunityRequestsToUpdate.forEach((or) => {
                    or.baseRequest.status = this.massUpdateStatusId as OpportunityRequestStatus;
                });
                break;
        }

        this.selection.clear();
        this.store.dispatch(AdminActions.opportunityRequestMassUpdate({ opportunityRequests: opportunityRequestsToUpdate }));
    }
}
