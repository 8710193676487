<div class="auth-reset-password-container">
    <div class="auth-reset-password">
        <div class="auth-reset-password-header">
            <h3>Сброс паролю</h3>
            <span class="text-grey">Вкажіть новий пароль двічі</span>
        </div>
        <form
            [formGroup]="resetPasswordForm"
            novalidate
            (ngSubmit)="onSubmit()">
            <div class="form-row">
                <mat-form-field
                    [floatLabel]="'always'"
                    appearance="fill"
                    class="auth-registration-input">
                    <mat-label>Пароль</mat-label>
                    <input
                        matInput
                        formControlName="password"
                        placeholder="Введіть Ваш пароль"
                        [type]="hidePassword ? 'password' : 'text'" />
                    <mat-icon
                        matSuffix
                        (click)="hidePassword = !hidePassword">
                        {{ hidePassword ? 'lock' : 'lock_open' }}
                    </mat-icon>
                    <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('required')"> Пароль <strong>Обов'язковий</strong> </mat-error>
                    <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('minlength')"> Мінімальна довжина <strong>6 символів</strong> </mat-error>
                </mat-form-field>
            </div>
            <div class="form-row">
                <mat-form-field
                    [floatLabel]="'always'"
                    appearance="fill"
                    class="auth-registration-input">
                    <mat-label>Повторіть пароль</mat-label>
                    <input
                        matInput
                        placeholder="Повторіть вище введений пароль"
                        [type]="hideRePassword ? 'password' : 'text'"
                        formControlName="repassword" />
                    <mat-icon
                        matSuffix
                        (click)="hideRePassword = !hideRePassword">
                        {{ hideRePassword ? 'lock' : 'lock_open' }}
                    </mat-icon>
                    <mat-error *ngIf="resetPasswordForm.controls['repassword'].hasError('required')"> Повтор паролю <strong>Обов'язковий</strong> </mat-error>
                    <mat-error *ngIf="resetPasswordForm.controls['repassword'].hasError('minlength')"> Мінімальна довжина <strong>6 символів</strong> </mat-error>
                </mat-form-field>
            </div>
            <div class="auth-reset-password-buttons">
                <app-error-message
                    *ngIf="error"
                    [errorMessage]="error"></app-error-message>
                <button
                    mat-flat-button
                    color="primary"
                    class="auth-reset-password-buttons__button">
                    Сбросити
                </button>
            </div>
        </form>
    </div>
</div>
