<div class="opportunities container container--admin-grey">
    <h4>Активні заявки</h4>
    <app-error-message
        *ngIf="error"
        [errorMessage]="error"></app-error-message>

    <app-empty-table
        [white]="true"
        *ngIf="!getActiveOpportnutiesRequests().length && !isLoading"></app-empty-table>
    <div
        fxFlexFill
        fxLayout="row wrap"
        fxLayout.xs="column"
        fxLayoutGap="10px 5px grid"
        class="opportunities__list">
        <app-opportunity-card
            fxFlex="25"
            fxFlex.lt-lg="33"
            *ngFor="let item of getActiveOpportnutiesRequests()"
            [isAdmin]="false"
            [opportunity]="getOpportunityModel(item)">
        </app-opportunity-card>
    </div>

    <h4>Неактвивні заявки</h4>

    <app-empty-table
        [white]="true"
        *ngIf="!getInactiveOpportnutiesRequests().length && !isLoading"></app-empty-table>
    <div
        fxFlexFill
        fxLayout="row wrap"
        fxLayout.xs="column"
        fxLayoutGap="10px 5px grid"
        class="opportunities__list">
        <app-opportunity-card
            fxFlex="25"
            fxFlex.lt-lg="33"
            *ngFor="let item of getInactiveOpportnutiesRequests()"
            [isAdmin]="false"
            [opportunity]="getOpportunityModel(item)">
        </app-opportunity-card>
    </div>
</div>
