import { Component, Input } from '@angular/core';
import { EMPTY_PROFILE } from 'src/app/modules/core/models/user.model';

@Component({
    selector: 'app-user-profile-info',
    templateUrl: './user-profile-info.component.html',
    styleUrls: ['./user-profile-info.component.scss'],
})
export class UserProfileInfoComponent {
    @Input() profile = EMPTY_PROFILE;
}
