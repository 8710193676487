<div class="common-prompt-wrapper">
    <div class="common-prompt">
        <div class="common-prompt__icon">
            <img
                [src]="data.icon"
                alt="success" />
        </div>
        <div
            class="common-prompt__header"
            *ngIf="data.header">
            <h3>{{ data.header }}</h3>
        </div>
        <div
            class="common-prompt__content"
            *ngIf="data.header">
            {{ data.content }}
        </div>
        <div
            class="form-row"
            fxLayout="row"
            fxLayoutAlign="center">
            <button
                mat-stroked-button
                class="common-prompt__buttons button-grey"
                mat-dialog-close
                (click)="buttonCancelClickHandler()">
                Скасувати
            </button>
            <button
                mat-stroked-button
                color="primary"
                class="common-prompt__buttons button-light-blue"
                mat-dialog-close
                (click)="buttonProceedClickHandler()">
                {{ data.actionText || defaultActionText }}
            </button>
        </div>
    </div>
</div>
