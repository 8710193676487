import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { OpportunityRequestFulfillType, OpportunityRequestModel } from 'src/app/modules/core/models/opportunity-request.model';
import * as AdminActions from 'src/app/modules/admin/store/admin.actions';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';
import { ActivatedRoute } from '@angular/router';
import { EMPTY_PROFILE, IUserProfile } from 'src/app/modules/core/models/user.model';
import { EMPTY_OPPORTUNITY } from 'src/app/modules/core/models/opportunity.model';
import { NavTabType } from 'src/app/modules/core/models/nav-tabs.model';

@Component({
    selector: 'app-admin-opportunity-request',
    templateUrl: './admin-opportunity-request.component.html',
    styleUrls: ['./admin-opportunity-request.component.scss'],
})
export class AdminOpportunityRequestComponent implements OnInit, OnDestroy {
    isLoading = false;
    error: string | null = '';
    opportunityRequestId = '';
    profileId = '';
    profile: IUserProfile = EMPTY_PROFILE;
    opportunityRequest: OpportunityRequestModel = new OpportunityRequestModel();

    private storeSubscription: Subscription = new Subscription();

    constructor(private store: Store<IApplicationState>, private dialog: MatDialog, private currentRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.currentRoute.params.subscribe((currentParams) => {
            this.opportunityRequestId = currentParams['opportunityRequestId'];
            if (this.opportunityRequestId) {
                this.store.dispatch(AdminActions.opportunityRequestGet({ opportunityRequestId: this.opportunityRequestId }));

                this.storeSubscription = this.store.select('admin').subscribe((adminState) => {
                    this.error = getError(adminState.requestState);
                    if (adminState.opportunityRequest) {
                        this.opportunityRequest = adminState.opportunityRequest.clone();
                        this.profileId = this.opportunityRequest.getProfile()?.id || '';
                        console.log('opportunityRequest', this.opportunityRequest);
                    }

                    if (adminState.opportunityRequestsUserProfiles.get(this.profileId)) {
                        this.profile = adminState.opportunityRequestsUserProfiles.get(this.profileId)!;
                    }

                    // if (!this.profile.id && this.profileId && adminState.opportunityRequest?.baseRequest.id) {
                    //     this.store.dispatch(AdminActions.userProfileGet({ profileId: this.profileId }));
                    // }
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }

    onConfirmFulfillment() {
        console.log('onConfirmFulfillment');
        const opportunityRequestToUpdate = this.opportunityRequest.clone();
        opportunityRequestToUpdate.confirmFulfill();

        this.store.dispatch(AdminActions.opportunityRequestUpdate({ opportunityRequest: opportunityRequestToUpdate }));
    }

    onConfirmPresence() {
        console.log('onConfirmPresence');
        const opportunityRequestToUpdate = this.opportunityRequest.clone();
        opportunityRequestToUpdate.confirmPresence();

        this.store.dispatch(AdminActions.opportunityRequestUpdate({ opportunityRequest: opportunityRequestToUpdate }));
    }

    onCancelFulfillment() {
        console.log('onCancelFulfillment');
        const opportunityRequestToUpdate = this.opportunityRequest.clone();
        opportunityRequestToUpdate.cancelFulfill();

        this.store.dispatch(AdminActions.opportunityRequestUpdate({ opportunityRequest: opportunityRequestToUpdate }));
    }

    onCancelPresence() {
        console.log('onCancelPresence');
        const opportunityRequestToUpdate = this.opportunityRequest.clone();
        opportunityRequestToUpdate.cancelPresence();

        this.store.dispatch(AdminActions.opportunityRequestUpdate({ opportunityRequest: opportunityRequestToUpdate }));
    }

    getOpprtunityUrl() {
        const id = this.opportunityRequest.getOpportunity()?.id || '';
        const url = `/admin/opportunity/${id}`;
        return url;
    }

    getOpprtunityRequestTitle() {
        const profile = this.profile;
        const opportunity = this.opportunityRequest.getOpportunity() || EMPTY_OPPORTUNITY;
        const result = `Заявка від ${profile.firstName} ${profile.lastName} на акцію ${opportunity.name}`;
        return result;
    }

    getOpprtunityTitle() {
        const opportunity = this.opportunityRequest.getOpportunity() || EMPTY_OPPORTUNITY;
        const result = `Акція:  ${opportunity.name}`;
        return result;
    }

    getProfile() {
        return this.profile;
    }

    // showFulfillment(){
    //     return this.opportunityRequest.baseRequest.fulfillDate
    // }
}
