<div class="projects container container--admin">
    <div>
        <h4>Проєкти</h4>

        <app-error-message
            *ngIf="error"
            [errorMessage]="error"></app-error-message>
        <table
            mat-table
            [dataSource]="dataSource">
            <!--- Note that these columns can be defined in any order. class="mat-elevation-z8"
                  The actual rendered columns are set as a property on the row definition" -->

            <!-- name Column -->
            <ng-container matColumnDef="name">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    <span *ngIf="!isMobile()">Проєкт</span>
                    <div
                        *ngIf="isMobile()"
                        fxLayout="row"
                        fxLayoutAlign="space-between center">
                        <div>Проєкти</div>
                        <div>
                            <button
                                mat-flat-button
                                color="primary"
                                class="projects__button-header"
                                (click)="onAddProject()">
                                <mat-icon class="projects__button-icon">add</mat-icon>
                                <span class="projects__button-text">Додати проєкт</span>
                            </button>
                        </div>
                    </div>
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    (click)="onNavigateToProject(element)">
                    <span *ngIf="!isMobile()">{{ element.baseProject.name }}</span>
                    <div *ngIf="isMobile()">
                        <div
                            fxLayoutAlign="start"
                            fxLayout="row">
                            <div class="projects__mobile-title projects__mobile-title--bold">Проєкт</div>
                        </div>
                        <div
                            fxLayoutAlign="start"
                            fxLayout="row">
                            <div class="projects__mobile-info">
                                <div class="projects__mobile-value">{{ element.baseProject.name }}</div>
                            </div>
                        </div>
                        <div
                            fxLayoutAlign="start"
                            fxLayout="row">
                            <div class="projects__mobile-info">
                                <div class="projects__mobile-title">Опис</div>
                                <div class="projects__mobile-value">{{ element.baseProject.description }}</div>
                            </div>
                        </div>
                        <div
                            fxLayoutAlign="start"
                            fxLayout="row">
                            <div class="projects__mobile-info">
                                <div class="projects__mobile-title">Статус</div>
                                <div class="projects__mobile-value">{{ element.baseProject.status | translateEnum : 'ProjectStatus' }}</div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <!-- source Column -->
            <ng-container matColumnDef="description">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Опис
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    (click)="onNavigateToProject(element)">
                    <span>{{ element.baseProject.description }}</span>
                </td>
            </ng-container>

            <!-- description Column -->
            <ng-container matColumnDef="status">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    <div
                        fxLayout="row"
                        fxLayoutAlign="space-between center">
                        <div>Статус</div>
                        <div>
                            <button
                                *ngIf="!isMobile()"
                                mat-flat-button
                                color="primary"
                                class="projects__button-header"
                                (click)="onAddProject()">
                                <mat-icon class="projects__button-icon">add</mat-icon>
                                <span class="projects__button-text">Додати проєкт</span>
                            </button>
                        </div>
                    </div>
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    (click)="onNavigateToProject(element)">
                    <span>{{ element.baseProject.status  | translateEnum : 'ProjectStatus' }}</span>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="getColumns()"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: getColumns()"></tr>
        </table>

        <app-empty-table *ngIf="!dataSource.length && !isLoading"></app-empty-table>
    </div>
</div>
