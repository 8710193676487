<div class="auth-recover-container">
    <div class="auth-recover">
        <div class="auth-recover-header">
            <h3>Забули свій пароль?</h3>
            <span class="text-grey">Для відновлення пароля вкажіть свій E-mail</span>
        </div>
        <form
            [formGroup]="recoverPasswordForm"
            novalidate
            (ngSubmit)="onSubmit()">
            <div>
                <mat-form-field
                    [floatLabel]="'always'"
                    appearance="fill"
                    class="auth-recover-input">
                    <mat-label>E-mail</mat-label>
                    <input
                        matInput
                        placeholder="Введіть Ваш E-mail"
                        formControlName="email" />
                    <mat-icon matSuffix>supervisor_account</mat-icon>
                    <mat-error *ngIf="recoverPasswordForm.controls['email'].hasError('required')"> Email <strong>Обов'язковий</strong> </mat-error>
                </mat-form-field>
            </div>
            <div class="auth-recover-buttons">
                <app-error-message
                    *ngIf="error"
                    [errorMessage]="error"></app-error-message>

                <button
                    mat-flat-button
                    color="primary"
                    class="auth-recover-buttons__button">
                    Далі
                </button>
            </div>
            <div class="auth-recover-buttons">
                <a
                    [routerLink]="['/auth']"
                    class="text-button">
                    Повернутись на сторінку для входу
                </a>
            </div>
        </form>
    </div>
</div>
