<div class="breadcrumps">
    <span
        class="breadcrumps__root-item"
        *ngFor="let item of rootItems">
        <a
            [routerLink]="[item.href]"
            [queryParams]="getQueryParams(item)"
            class="breadcrumps__root-item-link">
            {{ item.text }}
        </a>
        <span class="breadcrumps__divider">></span>
    </span>
    <span class="breadcrumps__current-item">{{ currentItem }}</span>
</div>
