<div class="user container">
    <app-breadcrumps
        [currentItem]="getUserTitle()"
        [rootItems]="[{ text: getUsersTitle(), href: getUsersUrl(), type: 'OpportunityRequests' }]"></app-breadcrumps>
    <h2>{{ getUserTitle() }}</h2>
    <app-error-message
        *ngIf="error"
        [errorMessage]="error"></app-error-message>

    <div class="user-wrapper">
        <div
            fxLayout="row"
            class="user__settings">
            <form
                [formGroup]="userForm"
                (ngSubmit)="onUserSubmit()"
                autocomplete="off">
                <div
                    fxLayoutAlign="start"
                    fxLayoutAlign.xs="space-evenly"
                    fxLayout="row"
                    fxLayout.xs="column">
                    <div class="user__settings-info">
                        <h4>Інфомація про акаунт</h4>
                        <div class="form-row">
                            <mat-form-field
                                [floatLabel]="'always'"
                                appearance="fill">
                                <mat-label>E-mail</mat-label>
                                <input
                                    matInput
                                    placeholder="Введіть Ваш E-mail"
                                    formControlName="email"
                                    autocomplete="off" />
                                <mat-icon matSuffix>supervisor_account</mat-icon>
                                <mat-error *ngIf="userForm.controls['email'].hasError('required')"> Email <strong>Обов'язковий</strong> </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-row">
                            <mat-form-field
                                [floatLabel]="'always'"
                                appearance="fill">
                                <mat-label>Пароль</mat-label>
                                <input
                                    matInput
                                    formControlName="password"
                                    placeholder="Введіть Ваш пароль"
                                    [type]="hidePassword ? 'password' : 'text'"
                                    autocomplete="off" />
                                <mat-icon
                                    matSuffix
                                    (click)="hidePassword = !hidePassword">
                                    {{ hidePassword ? 'lock' : 'lock_open' }}
                                </mat-icon>
                                <mat-error *ngIf="userForm.controls['password'].hasError('required')"> Пароль <strong>Обов'язковий</strong> </mat-error>
                                <mat-error *ngIf="userForm.controls['password'].hasError('minlength')"> Мінімальна довжина <strong>6 символів</strong> </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="user__settings-other">
                        <h4>Заблокувати/розблокувати</h4>
                        <div class="form-row">
                            <mat-slide-toggle
                                (change)="onSettingsUpdate()"
                                formControlName="isActive">
                                Розблокований
                            </mat-slide-toggle>
                        </div>
                        <h4>Ролі</h4>
                        <div class="form-row">
                            <mat-checkbox
                                formControlName="isUser"
                                color="primary"
                                (change)="onSettingsUpdate()">
                                Користувач
                            </mat-checkbox>
                        </div>
                        <div class="form-row">
                            <mat-checkbox
                                formControlName="isMember"
                                color="primary"
                                (change)="onSettingsUpdate()">
                                Голова громади
                            </mat-checkbox>
                        </div>
                        <div class="form-row">
                            <mat-checkbox
                                formControlName="isAdmin"
                                color="primary"
                                (change)="onSettingsUpdate()">
                                Адмін
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div>
                    <button
                        mat-flat-button
                        color="primary"
                        type="submit"
                        [disabled]="!userForm.valid">
                        {{ getButtonUpdateUserTitle() }}
                    </button>
                </div>
            </form>
        </div>
        <!-- <div fxLayout="row">
            <div class="user__items">
                <div class="user__active-item">
                    <div class="user__active-item-title">Номер для пошуку</div>
                    <div class="user__active-item-number">37</div>
                </div>
            </div>
            <div class="user__actions">
                <div fxLayout="row">
                    <div class="user__button-confirm">
                        <div fxLayout="row">
                            <button
                                mat-flat-button
                                color="primary"
                                class="user__button opportunity__button--visible button-green">
                                Підтвердити видачу
                            </button>
                        </div>
                        <div fxLayout="row">
                            <button
                                mat-flat-button
                                color="accent"
                                class="user__button opportunity__button--visible">
                                Скасувати видачу
                            </button>
                        </div>
                    </div>
                </div>

                <div fxLayout="row">
                    <div class="user__button-confirm">
                        <div fxLayout="row">
                            <button
                                mat-flat-button
                                color="primary"
                                class="user__button opportunity__button--visible">
                                Підтвердити присутність
                            </button>
                        </div>
                        <div fxLayout="row">
                            <button
                                mat-flat-button
                                color="accent"
                                class="user__button opportunity__button--visible">
                                Скасувати присутність
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div fxLayout="row">
            <app-user-profile
                *ngIf="profile.id"
                [adminMode]="true"
                [profile]="profile"
                (updateProfileEvent)="updateProfileEventHandler($event)">
            </app-user-profile>
        </div>

        <div class="user-requests-grid">
            <h4>Перелік заявок</h4>
            <app-empty-table *ngIf="!opportunityRequests.length && !isLoading"></app-empty-table>

            <div
                fxLayout="row"
                fxLayoutAlign="start">
                <table
                    *ngIf="opportunityRequests.length"
                    mat-table
                    [dataSource]="opportunityRequests"
                    multiTemplateDataRows
                    class="user-opportunity-requests">
                    <ng-container matColumnDef="username">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="user-opportunity-requests__cell">
                            Подія
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="user-opportunity-requests__cell">
                            <span *ngIf="!isMobile()">{{ element.getOpportunity().name }}</span>
                            <div *ngIf="isMobile()">
                                <div
                                    fxLayoutAlign="start"
                                    fxLayout="row">
                                    <div class="user-opportunity-requests__mobile-info">
                                        <div class="user-opportunity-requests__mobile-title">Подія</div>
                                        <div class="user-opportunity-requests__mobile-value">{{ element.getOpportunity().name }}</div>
                                    </div>
                                </div>
                                <div
                                    fxLayoutAlign="start"
                                    fxLayout="row"
                                    class="user-opportunity-requests__mobile-container">
                                    <div class="user-opportunity-requests__mobile-info">
                                        <div class="user-opportunity-requests__mobile-title">Присутність</div>
                                        <div class="user-opportunity-requests__mobile-value">
                                            {{ element.baseRequest.presenceDate ? 'Присутній' : '&#8212;' }}
                                        </div>
                                    </div>
                                    <div class="user-opportunity-requests__mobile-info">
                                        <div class="user-opportunity-requests__mobile-title">Допомога</div>
                                        <div class="user-opportunity-requests__mobile-value">
                                            {{ element.baseRequest.fulfillDate ? 'Отримав' : '&#8212;' }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    fxLayoutAlign="start"
                                    fxLayout="row"
                                    class="user-opportunity-requests__mobile-container">
                                    <div class="user-opportunity-requests__mobile-info">
                                        <div class="user-opportunity-requests__mobile-title">Рекоменд.</div>
                                        <div class="user-opportunity-requests__mobile-value">
                                            {{ element.getSuggestion()?.suggested ? 'Видати: ' + element.getSuggestion()?.demand + ', Приорітет: ' + element.getSuggestion()?.priority : '&#8212;' }}
                                        </div>
                                    </div>
                                    <div class="user-opportunity-requests__mobile-info">
                                        <div class="user-opportunity-requests__mobile-title">Номер</div>
                                        <div class="user-opportunity-requests__mobile-value">
                                            {{ element.baseRequest.orderId ? element.baseRequest.orderId : '&#8212;' }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    fxLayoutAlign="start"
                                    fxLayout="row">
                                    <div class="user-opportunity-requests__mobile-info">
                                        <div class="user-opportunity-requests__mobile-title">Статус</div>
                                        <div class="user-opportunity-requests__mobile-value requests__select">
                                            {{ element.status | translateEnum : 'OpportunityRequestStatus' }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="attendance">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="align-center">
                            Присутність
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-center">
                            <div>
                                {{ element.baseRequest.presenceDate ? 'Присутній' : '&#8212;' }}
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="fulfillment">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="align-center">
                            Допомога
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-center">
                            <div>
                                {{ element.baseRequest.fulfillDate ? 'Отримав' : '&#8212;' }}
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="suggestment">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="align-center">
                            Рекомендовано
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-center">
                            <div>
                                {{ element.getSuggestion()?.suggested ? 'Видати: ' + element.getSuggestion()?.demand + ', Приорітет: ' + element.getSuggestion()?.priority : '&#8212;' }}
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="order">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="align-center">
                            Номер
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-center">
                            {{ element.baseRequest.orderId ? element.baseRequest.orderId : '&#8212;' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="align-center">
                            Статус
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-center">
                            <div class="requests__select">
                                {{ element.baseRequest.status | translateEnum : 'OpportunityRequestStatus' }}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="expand">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            aria-label="row actions">
                            &nbsp;
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element">
                            <button
                                type="button"
                                mat-icon-button
                                (click)="navigateToOpportunityRequestPage(element)">
                                <mat-icon>visibility</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr
                        mat-header-row
                        *matHeaderRowDef="opportunityRequestsColumnsWithExpand"></tr>
                    <tr
                        mat-row
                        *matRowDef="let element; columns: opportunityRequestsColumnsWithExpand"
                        class="requests__element-row"></tr>
                </table>
            </div>
        </div>
    </div>
</div>
