import { GenericTypeModel } from '../../core/models/generic-type.model';
import { OpportunityRequestModel } from '../../core/models/opportunity-request.model';
import { IOpportunity, OpportunityModel } from '../../core/models/opportunity.model';
import {
    IClientOpportunitiesListResponseData,
    IClientOpportunityRequestResponseData,
    IClientOpportunityRequestResponseModel,
    IClientOpportunityRequestsResponseData,
    IClientOpportunityResponseData,
} from '../models/client.model';

export const fromOpportunitiesToOpportunityModels = (response: IClientOpportunitiesListResponseData) => {
    const { items } = response;

    const opportunities = items.map((item) => {
        const opportunityData: IOpportunity = {
            ...item,
            types: item.types.map((t) => new GenericTypeModel(t)),
            availableCategories: item.availableCategories.map((t) => new GenericTypeModel(t)),
        };

        const opportunity = new OpportunityModel(opportunityData);
        opportunity.setSuggestment(item.suggestionSettings);
        return opportunity;
    });

    return opportunities;
};

export const fromOpportunityToOpportunityModel = (response: IClientOpportunityResponseData) => {
    const opportunityData: IOpportunity = {
        ...response,
        types: response.types.map((t) => new GenericTypeModel(t)),
        availableCategories: response.availableCategories.map((t) => new GenericTypeModel(t)),
    };

    const opportunity = new OpportunityModel(opportunityData);
    opportunity.setSuggestment(response.suggestionSettings);
    return opportunity;
};

export const fromOpportunityRequestToOpportunityRequestModel = (response: IClientOpportunityRequestResponseData) => {
    const opportunityRequestData: IClientOpportunityRequestResponseModel = {
        ...response,
    };

    const opportunityRequest = new OpportunityRequestModel(opportunityRequestData);
    if (response.profile) {
        opportunityRequest.setProfile(response.profile);
    }
    if (response.opportunity) {
        const opportunity: IOpportunity = {
            ...response.opportunity,
            types: response.opportunity.types.map((t) => new GenericTypeModel(t)),
            availableCategories: response.opportunity.availableCategories.map((t) => new GenericTypeModel(t)),
        };

        opportunityRequest.setOpportunity(opportunity);
    }
    return opportunityRequest;
};

export const fromOpportunityRequestsToOpportunityRequestsModel = (response: IClientOpportunityRequestsResponseData) => {
    const { items } = response;

    const opportunityRequests = items.map((item) => {
        const opportunityRequestData: IClientOpportunityRequestResponseModel = {
            ...item,
        };

        const opportunityRequest = new OpportunityRequestModel(opportunityRequestData);
        if (item.profile) {
            opportunityRequest.setProfile(item.profile);
        }
        if (item.suggestion) {
            opportunityRequest.setSuggestion(item.suggestion);
        }

        if (item.opportunity) {
            const opportunity: IOpportunity = {
                ...item.opportunity,
                types: item.opportunity.types.map((t) => new GenericTypeModel(t)),
                availableCategories: item.opportunity.availableCategories.map((t) => new GenericTypeModel(t)),
            };

            opportunityRequest.setOpportunity(opportunity);
        }
        return opportunityRequest;
    });

    return opportunityRequests;
};

export const fromOpportunityRequestToOpportunityRequestRequestModel = (opportunityRequest: OpportunityRequestModel) => {
    const opportunityRequestData = {
        ...opportunityRequest.baseRequest,
    };

    if (opportunityRequestData.id) {
        delete opportunityRequestData.id;
    }

    if (opportunityRequestData.createdAt) {
        delete opportunityRequestData.createdAt;
    }

    if (opportunityRequestData.updatedAt) {
        delete opportunityRequestData.updatedAt;
    }

    return opportunityRequestData;
};
