<div class="auth-login">
    <div class="auth-login-header text-grey">З поверненням!</div>
    <form
        [formGroup]="loginForm"
        novalidate
        (ngSubmit)="onSubmit()">
        <div class="form-row">
            <mat-form-field
                [floatLabel]="'always'"
                appearance="fill"
                class="auth-login-input">
                <mat-label>E-mail</mat-label>
                <input
                    matInput
                    placeholder="Введіть Ваш E-mail"
                    formControlName="email" />
                <mat-icon matSuffix>supervisor_account</mat-icon>
                <mat-error *ngIf="loginForm.controls['email'].hasError('required')"> Email <strong>Обов'язковий</strong> </mat-error>
            </mat-form-field>
        </div>
        <div class="form-row">
            <mat-form-field
                [floatLabel]="'always'"
                appearance="fill"
                class="auth-login-input">
                <mat-label>Пароль</mat-label>
                <input
                    matInput
                    placeholder="Введіть Ваш пароль"
                    type="password"
                    formControlName="password"
                    [type]="hidePassword ? 'password' : 'text'" />
                <mat-icon
                    matSuffix
                    (click)="hidePassword = !hidePassword">
                    {{ hidePassword ? 'lock' : 'lock_open' }}
                </mat-icon>
                <mat-error *ngIf="loginForm.controls['password'].hasError('required')"> Пароль <strong>Обов'язковий</strong> </mat-error>
                <mat-error *ngIf="loginForm.controls['password'].hasError('minlength')"> Мінімальна довжина <strong>6 символів</strong> </mat-error>
            </mat-form-field>
        </div>
        <div
            fxLayout="row"
            fxLayoutAlign="space-between">
            <div>
                <mat-checkbox color="primary">Запам’ятати мене</mat-checkbox>
            </div>
            <div>
                <a
                    [routerLink]="['/auth/recover']"
                    class="text-button">
                    Забули пароль?
                </a>
            </div>
        </div>
        <div class="auth-login-buttons">
            <app-error-message
                *ngIf="error"
                [errorMessage]="error"></app-error-message>
            <button
                mat-flat-button
                color="primary"
                class="auth-login-buttons__button">
                Увійти
            </button>
        </div>
    </form>
</div>
