import { createAction, props } from '@ngrx/store';
import { IUserProfile, UserModel } from '../../core/models/user.model';
export const AUTHENTICATE_FAILED = '[Auth] AUTHENTICATE_FAILED';
export const CLEAR_ERROR = '[Auth] CLEAR_ERROR';
export const SIGNUP = '[Auth] SIGNUP';
export const SIGNUP_RESEND_VERIFICATION_LETTER = '[Auth] SIGNUP_VERIFICATION_LETTER_SENT';
export const SIGNUP_VERIFICATION_LETTER_SENT = '[Auth] SIGNUP_VERIFICATION_LETTER_SENT';
export const SIGNUP_VERIFICATION_LETTER_REQUEST = '[Auth] SIGNUP_VERIFICATION_LETTER_REQUEST';
export const SIGNUP_ACTIVATE_ACCOUNT = '[Auth] SIGNUP_ACTIVATE_ACCOUNT';
export const SIGNUP_ACTIVATE_ACCOUNT_SUCCESSS = '[Auth] SIGNUP_ACTIVATE_ACCOUNT_SUCCESSS';
export const SIGNIN = '[Auth] SIGNIN';
export const SIGNIN_REDIRECT = '[Auth] SIGNIN_REDIRECT';
export const SIGNIN_AUTO = '[Auth] SIGNIN_AUTO';
export const SIGNIN_AUTO_FAILED_REDIRECT = '[Auth] SIGNIN_AUTO_FAILED_REDIRECT';
export const SIGNOUT = '[Auth] SIGNOUT';
export const RECOVER_PASSWORD = '[Auth] RECOVER_PASSWORD';
export const RECOVER_PASSWORD_SUCCESS = '[Auth] RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_RESET = '[Auth] RECOVER_PASSWORD_RESET';
export const RECOVER_PASSWORD_RESET_SUCCESS = '[Auth] RECOVER_PASSWORD_RESET_SUCCESS';

export const PROFILE_UPDATE = '[Auth] PROFILE_UPDATE';
export const PROFILE_UPDATE_SUCCESS = '[Auth] PROFILE_UPDATE_SUCCESS';
export const PROFILE_GET = '[Auth] PROFILE_GET';
export const PROFILE_GET_SUCCESS = '[Auth] PROFILE_GET_SUCCESS';

export const REFRESH_TOKEN = '[Auth] REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = '[Auth] REFRESH_TOKEN_SUCCESS';

export const authenticateFailed = createAction(AUTHENTICATE_FAILED, props<{ error: string; initialError: string }>());
export const clearError = createAction(CLEAR_ERROR);

export const signUp = createAction(SIGNUP, props<{ email: string; password: string }>());
export const signUpVerificationLetterSent = createAction(SIGNUP_VERIFICATION_LETTER_SENT, props<{ email: string }>());
export const signUpVerificationLetterRequest = createAction(SIGNUP_VERIFICATION_LETTER_REQUEST);
export const signupResendVerificationLetter = createAction(SIGNUP_RESEND_VERIFICATION_LETTER);
export const signUpActivateAccount = createAction(SIGNUP_ACTIVATE_ACCOUNT, props<{ token: string }>());
export const signUpActivateAccountSuccess = createAction(SIGNUP_ACTIVATE_ACCOUNT_SUCCESSS);

export const signIn = createAction(SIGNIN, props<{ email: string; password: string }>());
export const signInRedirect = createAction(SIGNIN_REDIRECT, props<{ user: UserModel }>());
export const signInAuto = createAction(SIGNIN_AUTO);
export const signInAutoFailedRedirect = createAction(SIGNIN_AUTO_FAILED_REDIRECT, props<{ skipRedirect: boolean }>());

export const signOut = createAction(SIGNOUT);

export const recoverPassword = createAction(RECOVER_PASSWORD, props<{ email: string }>());
export const recoverPasswordSuccess = createAction(RECOVER_PASSWORD_SUCCESS);

export const recoverPasswordReset = createAction(RECOVER_PASSWORD_RESET, props<{ password: string; repassword: string; token: string }>());
export const recoverPasswordResetSuccess = createAction(RECOVER_PASSWORD_RESET_SUCCESS);

export const refreshToken = createAction(REFRESH_TOKEN);
export const refreshTokenSuccess = createAction(REFRESH_TOKEN_SUCCESS, props<{ user: UserModel }>());

export const profileGet = createAction(PROFILE_GET);
export const profileGetSuccess = createAction(PROFILE_GET_SUCCESS, props<{ profile: IUserProfile }>());
export const profileUpdate = createAction(PROFILE_UPDATE, props<{ user: UserModel }>());
export const profileUpdateSuccess = createAction(PROFILE_UPDATE_SUCCESS, props<{ profile: IUserProfile }>());
