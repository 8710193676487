<div class="user-profile">
    <form
        [formGroup]="userSettingsForm"
        (ngSubmit)="onSubmit()">
        <mat-stepper
            orientation="vertical"
            class="user-profile__stepper"
            #stepper>
            <mat-step class="user-profile-section">
                <ng-template matStepLabel>Особисті дані</ng-template>
                <ng-template matStepContent>
                    <div
                        fxLayoutAlign="start"
                        fxLayoutAlign.lt-md="space-evenly"
                        fxLayout="row"
                        fxLayout.lt-md="column">
                        <div class="user-profile-controls">
                            <mat-form-field
                                class="user-profile-controls__input"
                                [floatLabel]="'always'"
                                appearance="fill">
                                <mat-label>Ім'я</mat-label>
                                <input
                                    matInput
                                    placeholder="Ім'я"
                                    formControlName="firstName"
                                    class="user-profile-controls__input" />
                                <mat-error *ngIf="userSettingsForm.controls['firstName'].hasError('required')"> Ім'я <strong>Обов'язкове</strong> </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="user-profile-controls">
                            <mat-form-field
                                class="user-profile-controls__input"
                                [floatLabel]="'always'"
                                appearance="fill">
                                <mat-label>Прізвище</mat-label>
                                <input
                                    matInput
                                    placeholder="Прізвище"
                                    formControlName="lastName"
                                    class="user-profile-controls__input" />
                                <mat-error *ngIf="userSettingsForm.controls['lastName'].hasError('required')"> Прізвище <strong>Обов'язкове</strong> </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="user-profile-controls">
                            <mat-form-field
                                class="user-profile-controls__input"
                                [floatLabel]="'always'"
                                appearance="fill">
                                <mat-label>Телефон</mat-label>
                                <input
                                    matInput
                                    placeholder="Телефон"
                                    formControlName="phone"
                                    class="user-profile-controls__input"
                                    [imask]="{ mask: '+{38}(000)000-00-00' }" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div
                        fxLayoutAlign="start"
                        fxLayoutAlign.lt-md="space-evenly"
                        fxLayout="row"
                        fxLayout.lt-md="column">
                        <div class="user-profile-controls">
                            <mat-form-field
                                class="user-profile-controls__input"
                                [floatLabel]="'always'"
                                appearance="fill">
                                <mat-label>Дата народження(від 18р)</mat-label>
                                <input
                                    matInput
                                    [matDatepicker]="picker"
                                    [max]="maxDate"
                                    formControlName="birthday" />
                                <mat-hint>дд/мм/рррр</mat-hint>
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>

                                <mat-error *ngIf="userSettingsForm.controls['birthday'].hasError('required')"> Дата народження <strong>Обов'язкова</strong> </mat-error>
                                <mat-error *ngIf="userSettingsForm.controls['birthday'].errors && userSettingsForm.controls['birthday'].errors['dateInvalid']">
                                    Зареєструватися можливо з <strong>18 років</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="user-profile-controls user-profile-controls--centered user-profile-controls--left">
                            <mat-radio-group
                                aria-label="Select an option"
                                formControlName="gender"
                                color="primary">
                                <mat-radio-button value="male">Чоловік</mat-radio-button>
                                <mat-radio-button value="female">Жінка</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="user-profile-controls user-profile-controls--centered">
                            <mat-checkbox
                                formControlName="hasInjury"
                                color="primary">
                                Інвалідність
                            </mat-checkbox>
                        </div>
                    </div>
                    <div
                        fxLayoutAlign="start"
                        fxLayoutAlign.lt-md="space-evenly"
                        fxLayout="row"
                        fxLayout.lt-md="column">
                        <div class="user-profile-controls user-profile-controls--middle">
                            <mat-form-field
                                class="user-profile-controls__input"
                                [floatLabel]="'always'"
                                appearance="fill">
                                <mat-label>Серія та номер паспорта або свідоцтво</mat-label>
                                <input
                                    matInput
                                    placeholder="Серія та номер"
                                    formControlName="passport"
                                    class="user-profile-controls__input" />
                                <mat-error *ngIf="userSettingsForm.controls['passport'].hasError('required')"> Паспорт або свідоцтво <strong>Обов'язкове</strong> </mat-error>
                                <mat-error *ngIf="userSettingsForm.controls['passport'].errors && userSettingsForm.controls['passport'].errors['documentInvalid']">
                                    Невірний фомат <strong>документу.</strong> Очікуваний формат: АА111111, 123456789, 1-ЯЯ123123
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="user-profile-controls user-profile-controls--middle">
                            <mat-form-field
                                class="user-profile-controls__input"
                                [floatLabel]="'always'"
                                appearance="fill">
                                <mat-label>Номер ВПО (за наявності)</mat-label>
                                <input
                                    matInput
                                    placeholder="Номер ВПО (за наявності)"
                                    formControlName="temporaryId"
                                    class="user-profile-controls__input"
                                    [imask]="{ mask: '0000-0000000000' }" />
                                <mat-error *ngIf="userSettingsForm.controls['temporaryId'].errors && userSettingsForm.controls['temporaryId'].errors['documentInvalid']">
                                    Невірний фомат <strong>документу.</strong> Очікуваний формат: 1234-1234567890
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div formGroupName="address">
                        <p>Адреса, де проживаєте зараз (поточна адреса):</p>
                        <div
                            fxLayoutAlign="start"
                            fxLayoutAlign.lt-md="space-evenly"
                            fxLayout="row"
                            fxLayout.lt-md="column">
                            <div class="user-profile-controls">
                                <mat-form-field
                                    class="user-profile-controls__input"
                                    [floatLabel]="'always'"
                                    appearance="fill">
                                    <mat-label>Область</mat-label>
                                    <input
                                        matInput
                                        placeholder="Область"
                                        formControlName="region"
                                        class="user-profile-controls__input" />
                                    <mat-error *ngIf="userSettingsForm.controls['address'].get('region')?.hasError('required')"> Область <strong>Обов'язкова</strong> </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="user-profile-controls">
                                <mat-form-field
                                    class="user-profile-controls__input"
                                    [floatLabel]="'always'"
                                    appearance="fill">
                                    <mat-label>Населений пункт</mat-label>
                                    <input
                                        matInput
                                        placeholder="Населений пункт"
                                        formControlName="city"
                                        class="user-profile-controls__input" />
                                    <mat-error *ngIf="userSettingsForm.controls['address'].get('city')?.hasError('required')"> Населений пункт <strong>Обов'язковий</strong> </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="user-profile-controls">
                                <mat-form-field
                                    class="user-profile-controls__input"
                                    class="user-profile-controls__input"
                                    [floatLabel]="'always'"
                                    appearance="fill">
                                    <mat-label>Індекс</mat-label>
                                    <input
                                        matInput
                                        placeholder="Індекс"
                                        formControlName="zip"
                                        maxlength="5"
                                        class="user-profile-controls__input" />
                                    <mat-error *ngIf="userSettingsForm.controls['address'].get('zip')?.hasError('required')"> Індекс <strong>Обов'язковий</strong> </mat-error>
                                    <mat-error *ngIf="userSettingsForm.controls['address'].get('zip')?.hasError('zipInvalid')"> Невірний фомат <strong>індексу</strong> </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div
                            fxLayoutAlign="start"
                            fxLayoutAlign.lt-md="space-evenly"
                            fxLayout="row"
                            fxLayout.lt-md="column">
                            <div class="user-profile-controls user-profile-controls--long">
                                <mat-form-field
                                    class="user-profile-controls__input"
                                    class="user-profile-controls__input"
                                    [floatLabel]="'always'"
                                    appearance="fill">
                                    <mat-label>Вулиця, номер будинку, квартира</mat-label>
                                    <input
                                        matInput
                                        placeholder="Вулиця, номер будинку, квартира"
                                        formControlName="address1"
                                        class="user-profile-controls__input" />
                                </mat-form-field>
                                <input
                                    type="hidden"
                                    formControlName="id" />
                            </div>
                        </div>
                    </div>
                    <input
                        type="hidden"
                        formControlName="id" />
                    <input
                        type="hidden"
                        formControlName="email" />
                    <input
                        type="hidden"
                        formControlName="status" />
                    <button
                        mat-raised-button
                        matStepperNext
                        color="primary"
                        class="user-profile-controls__button"
                        type="button">
                        Далі
                    </button>
                </ng-template>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Дані {{ memberMode ? 'громади' : 'родичів' }}</ng-template>
                <ng-template matStepContent>
                    <div formArrayName="additionalPersons">
                        <div
                            *ngFor="let person of getAdditionalPersons().controls; let i = index"
                            [formGroupName]="i"
                            class="additional-person"
                            [ngClass]="{ 'additional-person--padding': i !== 0 }">
                            <div
                                fxLayout="row"
                                fxLayoutAlign="start center">
                                <div class="additional-person__title">Анкета №{{ i + 1 }}</div>
                                <div>
                                    <button
                                        mat-button
                                        color="accent"
                                        class="additional-person__button"
                                        type="button"
                                        (click)="onRemovedditionPersonsHandler(i)">
                                        Видалити
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div
                                fxLayoutAlign="start"
                                fxLayoutAlign.lt-md="space-evenly"
                                fxLayout="row"
                                fxLayout.lt-md="column">
                                <div class="user-profile-controls">
                                    <mat-form-field
                                        class="user-profile-controls__input"
                                        [floatLabel]="'always'"
                                        appearance="fill">
                                        <mat-label>Ім'я</mat-label>
                                        <input
                                            matInput
                                            placeholder="Ім'я"
                                            formControlName="firstName"
                                            class="user-profile-controls__input" />
                                        <mat-error *ngIf="person.get('firstName')?.hasError('required')"> Ім'я <strong>Обов'язкове</strong> </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="user-profile-controls">
                                    <mat-form-field
                                        class="user-profile-controls__input"
                                        [floatLabel]="'always'"
                                        appearance="fill">
                                        <mat-label>Прізвище</mat-label>
                                        <input
                                            matInput
                                            placeholder="Прізвище"
                                            formControlName="lastName"
                                            class="user-profile-controls__input" />
                                        <mat-error *ngIf="person.get('lastName')?.hasError('required')"> Прізвище <strong>Обов'язкове</strong> </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div
                                fxLayoutAlign="start"
                                fxLayoutAlign.lt-md="space-evenly"
                                fxLayout="row"
                                fxLayout.lt-md="column">
                                <div class="user-profile-controls">
                                    <mat-form-field
                                        class="user-profile-controls__input"
                                        [floatLabel]="'always'"
                                        appearance="fill">
                                        <mat-label>Дата народження</mat-label>
                                        <input
                                            matInput
                                            [matDatepicker]="picker"
                                            formControlName="birthday" />
                                        <mat-hint>дд/мм/рррр</mat-hint>
                                        <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                    <mat-error *ngIf="person.get('birthday')?.hasError('required')"> Дата народження <strong>Обов'язкова</strong> </mat-error>
                                </div>
                                <div class="user-profile-controls user-profile-controls--centered user-profile-controls--left">
                                    <mat-radio-group
                                        aria-label="Select an option"
                                        formControlName="gender"
                                        color="primary">
                                        <mat-radio-button value="male">Чоловік</mat-radio-button>
                                        <mat-radio-button value="female">Жінка</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="user-profile-controls user-profile-controls--centered">
                                    <mat-checkbox
                                        color="primary"
                                        formControlName="hasInjury">
                                        Інвалідність
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div
                                fxLayoutAlign="start"
                                fxLayoutAlign.lt-md="space-evenly"
                                fxLayout="row"
                                fxLayout.lt-md="column">
                                <div class="user-profile-controls user-profile-controls--middle">
                                    <mat-form-field
                                        class="user-profile-controls__input"
                                        [floatLabel]="'always'"
                                        appearance="fill">
                                        <mat-label>Серія та номер паспорта або свідоцтво</mat-label>
                                        <input
                                            matInput
                                            placeholder="Серія та номер"
                                            formControlName="passport"
                                            class="user-profile-controls__input" />
                                        <mat-error *ngIf="person.get('passport')?.hasError('required')"> Паспорт або свідоцтво <strong>Обов'язкове</strong> </mat-error>
                                        <mat-error *ngIf="person.get('passport')?.hasError('documentInvalid')"> Невірний фомат <strong>документу.</strong> Очікуваний формат: АА111111, 123456789, 1-ЯЯ123123</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="user-profile-controls user-profile-controls--middle">
                                    <mat-form-field
                                        class="full-width"
                                        class="user-profile-controls__input"
                                        [floatLabel]="'always'"
                                        appearance="fill">
                                        <mat-label>Номер ВПО (за наявності)</mat-label>
                                        <input
                                            matInput
                                            placeholder="Номер ВПО (за наявності)"
                                            formControlName="temporaryId"
                                            class="full-width" 
                                            [imask]="{ mask: '0000-0000000000' }" />
                                        <mat-error *ngIf="person.get('temporaryId')?.hasError('documentInvalid')"> Невірний фомат <strong>документу.</strong> Очікуваний формат: 1234-1234567890 </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        fxLayout="row"
                        fxLayoutAlign="space-evenly"
                        fxLayout.lt-md="column"
                        class="user-profile-controls__add-person">
                        <input
                            type="hidden"
                            formControlName="id" />
                        <input
                            type="hidden"
                            formControlName="parent" />
                        <button
                            mat-button
                            color="primary"
                            class="user-profile-controls__bold-button user-profile-controls__wide-button"
                            type="button"
                            (click)="onAddAdditionPersonsHandler()">
                            <mat-icon>add_circle</mat-icon>
                            Додати {{ memberMode ? 'членів громади' : 'родичів' }}
                        </button>
                    </div>
                    <div
                        fxLayout="row"
                        fxLayoutAlign="start">
                        <button
                            mat-stroked-button
                            matStepperPrevious
                            color="primary"
                            class="user-profile-controls__button"
                            type="button">
                            Назад
                        </button>
                        <button
                            mat-raised-button
                            matStepperNext
                            color="primary"
                            class="user-profile-controls__button"
                            type="button"
                            *ngIf="!adminMode">
                            Далі
                        </button>
                        <button
                            mat-raised-button
                            matStepperNext
                            color="primary"
                            class="user-profile-controls__button"
                            type="submit"
                            *ngIf="adminMode">
                            Зберегти
                        </button>
                    </div>
                </ng-template>
            </mat-step>
            <mat-step *ngIf="!adminMode">
                <ng-template matStepLabel>Підтвердження</ng-template>
                <div
                    fxLayoutAlign="start"
                    fxLayoutAlign.lt-md="space-evenly"
                    fxLayout="row"
                    fxLayout.lt-md="column">
                    <div class="user-profile-controls user-profile-controls--long user-profile-controls--vertical-space">
                        <mat-checkbox
                            formControlName="agreeProcess"
                            color="primary">
                            <div class="privacy-text">
                                Я погоджуюся з тим, що коректне заповнення цієї форми є обовʼязковою умовою для розгляду заявки на отримання гуманітарної допомоги від IAC ISHR.
                            </div>
                        </mat-checkbox>
                        <mat-error *ngIf="userSettingsForm.controls['agreeProcess'].hasError('required')"> Згода <strong> обов'язкова</strong> </mat-error>
                    </div>
                </div>
                <div
                    fxLayoutAlign="start"
                    fxLayoutAlign.lt-md="space-evenly"
                    fxLayout="row"
                    fxLayout.lt-md="column">
                    <div class="user-profile-controls user-profile-controls--long user-profile-controls--vertical-space">
                        <mat-checkbox
                            formControlName="haveRead"
                            color="primary">
                            <div class="privacy-text">
                                Я ознайомлений (на) з тим, що заповнення даної форми не означає обовʼязкового отримання гуманітарної допомоги. Мені відомо, що підтвердженням отримання гуманітарної
                                допомоги є схвалення заявки командою IAC ISHR.
                            </div>
                        </mat-checkbox>
                        <mat-error *ngIf="userSettingsForm.controls['haveRead'].hasError('required')"> Згода <strong> обов'язкова</strong> </mat-error>
                    </div>
                </div>
                <div
                    fxLayoutAlign="start"
                    fxLayoutAlign.lt-md="space-evenly"
                    fxLayout="row"
                    fxLayout.lt-md="column">
                    <div class="user-profile-controls user-profile-controls--long user-profile-controls--vertical-space">
                        <mat-checkbox
                            formControlName="agree"
                            color="primary">
                            <div class="privacy-text">Я даю свою згоду на збір та обробку моїх персональних даних.</div>
                        </mat-checkbox>
                        <mat-error *ngIf="userSettingsForm.controls['agree'].hasError('required')"> Згода <strong> обов'язкова</strong> </mat-error>
                    </div>
                </div>
                <div
                    fxLayout="row"
                    fxLayoutAlign="start">
                    <button
                        mat-stroked-button
                        matStepperPrevious
                        color="primary"
                        class="user-profile-controls__button"
                        type="button">
                        Назад
                    </button>
                    <button
                        mat-raised-button
                        matStepperNext
                        color="primary"
                        class="user-profile-controls__button"
                        type="submit">
                        Зберегти
                    </button>
                </div>
            </mat-step>
        </mat-stepper>
    </form>
</div>
