import { deepCopy } from '@angular-devkit/core/src/utils/object';

export class CloneHelper {
    static cloneData<T>(data: T, objMap?: WeakMap<any, any>): T {
        if (!objMap) {
            // Map for handle recursive objects
            objMap = new WeakMap();
        }

        // recursion wrapper
        const deeper = (value: T) => {
            if (value && typeof value === 'object') {
                return CloneHelper.cloneData(value, objMap);
            }
            return value;
        };

        // Array value
        if (Array.isArray(data)) {
            return data.map(deeper) as T;
        }

        // Object value
        if (data && typeof data === 'object') {
            // Same object seen earlier
            if (objMap.has(data)) return objMap.get(data);
            // Date object
            if (data instanceof Date) {
                const result = new Date(data.valueOf());
                objMap.set(data, result);
                return result as T;
            }
            // Use original prototype
            const node = Object.create(Object.getPrototypeOf(data));
            // Save object to map before recursion
            objMap.set(data, node);
            for (const [key, value] of Object.entries(data)) {
                node[key] = deeper(value as T);
            }
            return node;
        }
        // Scalar value
        return data;
    }
}
