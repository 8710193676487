<div class="data-export container container--admin">
    <div>
        <h4>Історія єкспорту</h4>
        <app-error-message
            *ngIf="error"
            [errorMessage]="error"></app-error-message>
        <div fxLayout="row" fxLayoutAlign="start top">
            <div class="data-export__controls">
                <mat-form-field
                    appearance="fill"
                    [floatLabel]="'always'"
                    class="data-export__input select-filter">
                    <mat-label>Події</mat-label>
                    <mat-select
                        [(value)]="selectedOpportunityId">
                        <mat-option
                            *ngFor="let opp of opportunities"
                            [value]="opp.baseOpportunity.id">
                            {{ opp.baseOpportunity.name }}
                            ({{ opp.baseOpportunity.active.startDate | date : 'longDate'  }})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="data-export__controls">
                <button
                    mat-flat-button
                    color="primary"
                    class="data-export__button-header"
                    (click)="onAddType()">
                    <mat-icon class="data-export__button-icon">add</mat-icon>
                    <span class="data-export__button-text"> Сгенерувати</span>
                </button>
            </div>
        </div>
        <app-empty-table *ngIf="!dataSource.length && !isLoading"></app-empty-table>

        <table
            *ngIf="dataSource.length"
            mat-table
            [dataSource]="dataSource"
            class="mat-elevation-z8">
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

            <!-- name Column -->
            <ng-container matColumnDef="id">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Скачати
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    (click)="onAddType(element)">
                    <span *ngIf="element.baseExport.status !== 'approved'">Звіт готується</span>
                    <span
                        *ngIf="element.baseExport.status === 'approved'"
                        class="data-export__download-button"
                        >Скачати звіт</span
                    >
                </td>
            </ng-container>

            <!-- source Column -->
            <ng-container matColumnDef="createdAt">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Дата
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    (click)="onAddType(element)">
                    <span *ngIf="element.baseExport.status !== 'approved'">
                        <span class="data-export__date">{{ element.baseExport.createdAt | date : 'medium' }}</span>
                        <span class="data-export__preparing">Звіт готується. Перевірте через декілька хвилин</span>
                    </span>
                    <span *ngIf="element.baseExport.status === 'approved'">
                        <span class="data-export__date">{{ element.baseExport.createdAt | date : 'medium' }}</span>
                        <span class="data-export__download-button"> Скачати звіт </span>
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Статус
                </th>
                <td
                    mat-cell
                    *matCellDef="let element">
                    {{ element.baseExport.status | translateEnum : 'DataExportStatus' }}
                </td>
            </ng-container>

            <!-- action Column -->
            <ng-container matColumnDef="action">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                </th>
                <td
                    mat-cell
                    *matCellDef="let element">
                    <button
                        mat-stroked-button
                        color="primary"
                        class="button-light-blue data-export__button"
                        (click)="onDeleteHandler(element)">
                        <mat-icon class="data-export__button-icon">delete</mat-icon>
                        <span class="data-export__button-text"> видалити </span>
                    </button>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="getColumns()"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: getColumns()"></tr>
        </table>
    </div>
</div>
