<!-- <div class="opportunities container container--admin-grey">
    <h4>Заявка на акцію</h4>
    <app-error-message
        *ngIf="error"
        [errorMessage]="error"></app-error-message>

</div> -->

<div class="opportunity-request container">
    <app-breadcrumps
        [currentItem]="getOpprtunityRequestTitle()"
        [rootItems]="[{ text: getOpprtunityTitle(), href: getOpprtunityUrl(), type: 'OpportunityRequests' }]"></app-breadcrumps>
    <h2>{{ getOpprtunityRequestTitle() }}</h2>
    <app-error-message
        *ngIf="error"
        [errorMessage]="error"></app-error-message>
    <app-notification-message
        [notificationType]="'success'"
        *ngIf="opportunityRequest.baseRequest.fulfillDate"
        [notificationMessage]="'Власник заявки отримав допомогу'"></app-notification-message>

     <div class="opportunity-request-wrapper">
        <div
            fxLayout="row"
            fxLayout.xs="column">
            <div class="opportunity-request-left">
                <div class="opportunity-request__items">
                    <div class="opportunity-request__active-item">
                        <div class="opportunity-request__active-item-title">Номер для пошуку</div>
                        <div class="opportunity-request__active-item-number">{{ opportunityRequest.baseRequest.orderId }}</div>
                    </div>
                </div>
                <div class="opportunity-request__actions">
                    <div fxLayout="row">
                        <div class="opportunity-request__button-confirm">
                            <div
                                fxLayout="row"
                                *ngIf="!opportunityRequest.baseRequest.fulfillDate">
                                <button
                                    mat-flat-button
                                    color="primary"
                                    class="opportunity-request__button opportunity__button--visible button-green"
                                    (click)="onConfirmFulfillment()">
                                    Підтвердити видачу
                                </button>
                            </div>
                            <div
                                fxLayout="row"
                                *ngIf="opportunityRequest.baseRequest.fulfillDate">
                                <button
                                    mat-flat-button
                                    color="accent"
                                    class="opportunity-request__button opportunity__button--visible"
                                    (click)="onCancelFulfillment()">
                                    Скасувати видачу
                                </button>
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row">
                        <div class="opportunity-request__button-confirm">
                            <div
                                fxLayout="row"
                                *ngIf="!opportunityRequest.baseRequest.attendanceDate">
                                <button
                                    mat-flat-button
                                    color="primary"
                                    class="opportunity-request__button opportunity__button--visible"
                                    (click)="onConfirmPresence()">
                                    Підтвердити присутність
                                </button>
                            </div>
                            <div
                                fxLayout="row"
                                *ngIf="opportunityRequest.baseRequest.attendanceDate">
                                <button
                                    mat-flat-button
                                    color="accent"
                                    class="opportunity-request__button opportunity__button--visible"
                                    (click)="onCancelPresence()">
                                    Скасувати присутність
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="opportunity-request-right">
                <app-user-profile-info [profile]="getProfile()"></app-user-profile-info>
            </div>
        </div>
    </div>
</div>
