import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { IApplicationState } from '../../../app.reducer';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserAuthGuard implements CanActivate {
    constructor(private router: Router, private store: Store<IApplicationState>) {}

    canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
        return this.store.select('auth').pipe(
            take(1),
            map((authState) => {
                return authState.user;
            }),
            map((user) => {
                const isMaintenance = environment.maintenance;
                if(isMaintenance) {
                    return this.router.createUrlTree(['maintenance']);
                }

                const isAuth = user && user.getToken() && user.isProfileFulfilled();
                console.log(user);
                if (isAuth) {
                    return true;
                }
                return this.router.createUrlTree(['user/settings']);
            })
        );
    }
}
