import { Component, OnDestroy, OnInit } from '@angular/core';
import * as ClientActions from '../../store/client.actions';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { EMPTY_OPPORTUNITY, OpportunityModel, OpportunityStatus } from 'src/app/modules/core/models/opportunity.model';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';
import { ActivatedRoute } from '@angular/router';
import { OpportunityRequestFulfillType, OpportunityRequestModel, OpportunityRequestStatus } from 'src/app/modules/core/models/opportunity-request.model';
import { CommonPromptComponent, CommonPromptIconType } from 'src/app/modules/shared/components/common-prompt/common-prompt.component';
import { UserModel } from 'src/app/modules/core/models/user.model';
import { ProfileHelper } from 'src/app/modules/core/helpers/profile.helper';

@Component({
    selector: 'app-client-opportunity',
    templateUrl: './client-opportunity.component.html',
    styleUrls: ['./client-opportunity.component.scss'],
})
export class ClientOpportunityComponent implements OnInit, OnDestroy {
    isLoading = false;
    error: string | null = '';
    opportunityId = '';
    opportunity: OpportunityModel = new OpportunityModel(EMPTY_OPPORTUNITY);
    user: UserModel = new UserModel();
    isConditionMet = false;

    private storeSubscription: Subscription = new Subscription();
    private storeClientSubscription: Subscription = new Subscription();
    NorificationMessageType: any;

    constructor(private store: Store<IApplicationState>, private dialog: MatDialog, private currentRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.isConditionMet = false;
        this.opportunity = new OpportunityModel(EMPTY_OPPORTUNITY);
        this.currentRoute.params.subscribe((currentParams) => {
            this.opportunityId = currentParams['opportunityId'];
            if (this.opportunityId) {
                this.store.dispatch(ClientActions.opportunityGetOne({ opportunityId: this.opportunityId }));
            }

            this.storeSubscription = this.store.select('client').subscribe((clientState) => {
                this.error = getError(clientState.requestState);
                if (clientState.opportunity) {
                    this.opportunity = clientState.opportunity.clone();
                    if(!this.isConditionMet && this.opportunity.baseOpportunity.hasAccess) {
                        this.isConditionMet = true;
                    }
                }
            });

            this.storeClientSubscription = this.store.select('auth').subscribe((authState) => {
                if (authState.user) {
                    // if(authState.user.isAdmin()) {
                    //     this.isConditionMet = true;
                    // }

                    // return authState.user.isAdmin() ||
                    // this.user = authState.user.clone();
                    // const profile = this.user.getProfile();
                    // // if(profile && profile.additionalProfiles && profile.additionalProfiles.length) {
                    // //     const ageDistribution = ProfileHelper.getPersonAgeDistribution(profile);
                    // //     this.isConditionMet = (ageDistribution[2] + ageDistribution[3]) > 0;
                    // // }
                    // if(profile && profile.addresses && profile.addresses.length) {
                    //     const address = profile.addresses[0];
                    //     const zip = address.zip;
                    //     if(this.opportunityId === '81da83e7-607c-45a6-865a-585413ec151d') {
                    //         const allowedZips = ['12313', '41500', '41506', '41512', '41509', '41517', '41512', '41560', '41500', '41608', '41571', '41572', '41513', '41506', '41512', '41510', '41511', '41511', '41593', '41545', '41545', '41545', '41545', '41545', '41545', '41545', '41545', '41540', '41541', '41538', '41520', '41522', '41522', '41521', '41521', '41522', '41520', '41526', '41503', '41503', '41503', '41501', '41503', '41503', '41500', '41503', '41503', '41503', '41503', '41503', '41508', '41503'];
                    //         this.isConditionMet = allowedZips.includes(address.zip.trim());
                    //     }
                    //     if(this.opportunityId === 'dd318901-93a6-403d-bfd3-7bf8ca80e0da') {
                    //         const allowedZips = ['41000', '41001', '41033', '41053', '41029', '41049', '41034', '41004', '41053', '41037', '41048', '41047', '41046', '41057', '41045', '41052', '41058', '41002', '41014', '41054', '41036', '41003', '41051', '41005', '41030', '41006', '41012', '41007', '41050', '41008', '41063', '41044'];
                    //         this.isConditionMet = allowedZips.includes(address.zip.trim());
                    //     }
                    //     if(this.opportunityId === 'f29a2c1d-de0f-447d-ae69-172666d63295') {
                    //         const allowedZips = ['38001','38021','38031','38002','38041','38020','38003','38004','38007','38008','38012','38009','38040','38015','38017','38018','38019','38022','38025','38026','38027','38028','38014','38029','38035','38036','38037','38038','38039','38042','38043','38044','38045','38046','38013','38047','38048','38049','38050','38051','38052','38053','38054','38055','38011','38056','38057','38023','38059','38060','38033','38061','38062','38063','38064','38065','38066','38032','38067','38068','38005','38069','38070','38071','38006','38072','38073','38074','38075','38000','38076','38030', '38034'];
                    //         this.isConditionMet = allowedZips.includes(address.zip.trim());
                    //     }
                    //     if(this.opportunityId === 'c9e6087d-cc0f-4b51-a4d2-b5dde0d6ce8e') {
                    //         const allowedZips = ['07400','07401','07402','07403','07404','07405','07406','07425','07431','07433','07442','07441','07452','07444','07440','07424','07415','07443','07420','07455','07451','07445','07411','07410','07454','07450','07416','07413','07414','07412','07430','07453','07423','07402','07434'];
                    //         this.isConditionMet = allowedZips.includes(address.zip.trim());
                    //     }
                    //     if(this.opportunityId === '530e38d6-cc4a-4d2b-9675-5991960ea7e4') {
                    //         const allowedZips = ['37251','37509','37535','37563','37517','37571','37534','37544','37526','37533','37554','37524','37552','37525','37529','37538','37539','37546','37547','37537','37548','37531','37549','37500','37505','37550','37556','37557','37558','37574','37536','37570','37559','37545','37542','37511','37561','37560','37567','37565','37532','37566','37551','37568','37569','37575','37530','37576','37577','37578','37580','37581','37553','37515','37582','37583','37584','37512','37585','37586','37587','37573','37588', '37501', '37502', '37503', '37504', '37506'];
                    //         this.isConditionMet = allowedZips.includes(address.zip.trim());
                    //     }
                    //     if(this.opportunityId === '51af4e18-648e-4145-9b5c-dfdd682f7759') {
                    //         const allowedZips = ['37620', '37621'];
                    //         this.isConditionMet = allowedZips.includes(address.zip.trim());
                    //     }
                    // }
                }
            });
        });
    }

    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
        this.storeClientSubscription.unsubscribe();
    }

    onCancelRequest() {
        this.dialog.open(CommonPromptComponent, {
            data: {
                icon: CommonPromptIconType.Question,
                header: 'Вы впевнены, що хочете скасувати заявку?',
                data: {},
                callback: this.processCancel.bind(this),
            },
        });
    }

    processCancel() {
        if (this.getOppotunityRequest()) {
            const opportunity = this.opportunity.clone();
            const opportunityReqiest = opportunity.getOpportunityRequest();
            opportunityReqiest!.baseRequest.status = OpportunityRequestStatus.deleted;
            opportunityReqiest!.baseRequest.presenceDate = null;

            this.store.dispatch(ClientActions.opportunityRequestUpdate({ opportunity }));
        }
    }

    onConfirmPresence() {
        if (this.getOppotunityRequest()) {
            const opportunity = this.opportunity.clone();
            const opportunityReqiest = opportunity.getOpportunityRequest();
            opportunityReqiest!.baseRequest.presenceDate = new Date();

            this.store.dispatch(ClientActions.opportunityRequestUpdate({ opportunity }));
        }
    }

    onSubmitOpportunityRequest() {
        if (this.opportunity) {
            this.store.dispatch(ClientActions.opportunityRequestSubmit({ opportunity: this.opportunity }));
        }
    }

    isOpportunityConditionsMet() {
        return this.isConditionMet;
    }

    isFullfilled() {
        return this.getOppotunityRequest() && this.getOppotunityRequest()?.baseRequest.fulfillType === OpportunityRequestFulfillType.fulfilled;
    }

    isStateSent() {
        return this.getOppotunityRequest() && this.getOppotunityRequest()?.baseRequest.status === OpportunityRequestStatus.new;
    }

    isStateApprovedAndNotCofirfirmed() {
        return this.getOppotunityRequest() && this.getOppotunityRequest()?.baseRequest.status === OpportunityRequestStatus.approved && !this.getOppotunityRequest()!.baseRequest.presenceDate;
    }

    isStateApprovedAndCofirfirmed() {
        return this.getOppotunityRequest() && this.getOppotunityRequest()?.baseRequest.status === OpportunityRequestStatus.approved && this.getOppotunityRequest()!.baseRequest.presenceDate;
    }

    isStateRejected() {
        return this.getOppotunityRequest() && this.getOppotunityRequest()?.baseRequest.status === OpportunityRequestStatus.canceled;
    }

    isStateCanceled() {
        return this.getOppotunityRequest() && this.getOppotunityRequest()?.baseRequest.status === OpportunityRequestStatus.deleted;
    }

    isCanSendRequest() {
        return !this.getOppotunityRequest() && this.isOpportunityConditionsMet();
    }

    isCanCancelRequest() {
        return false; //this.getOppotunityRequest() && !this.isStateCanceled() && this.opportunity.baseOpportunity.status !== OpportunityStatus.inactive;
    }

    isStatCanSeeInnerDetails() {
        return (this.isStateApprovedAndCofirfirmed() || this.isStateApprovedAndNotCofirfirmed()) && this.opportunity.baseOpportunity.status === OpportunityStatus.pending && !this.isFullfilled();
    }

    getOppotunityRequest(): OpportunityRequestModel | null {
        return this.opportunity.getOpportunityRequest();
    }

    getQRCodeUrl() {
        const oppReq = this.getOppotunityRequest();
        const opportunity = this.opportunity;
        if (oppReq && opportunity) {
            const result = `${document.location.protocol}//${document.location.hostname}/admin/opportunity/request/${oppReq.baseRequest.id}`;
            return result;
        }
        return '';
    }
}
