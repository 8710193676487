<div class="auth-registration">
    <div class="auth-registration-header text-grey">Ласкаво просимо!</div>
    <form
        [formGroup]="registrationForm"
        (ngSubmit)="onSubmit()">
        <div class="form-row">
            <mat-form-field
                [floatLabel]="'always'"
                appearance="fill"
                class="auth-registration-input">
                <mat-label>E-mail</mat-label>
                <input
                    matInput
                    placeholder="Введіть Ваш E-mail"
                    formControlName="email" />
                <mat-icon matSuffix>supervisor_account</mat-icon>
                <mat-error *ngIf="registrationForm.controls['email'].hasError('required')"> Email <strong>Обов'язковий</strong> </mat-error>
            </mat-form-field>
        </div>
        <div class="form-row">
            <mat-form-field
                [floatLabel]="'always'"
                appearance="fill"
                class="auth-registration-input">
                <mat-label>Пароль</mat-label>
                <input
                    matInput
                    formControlName="password"
                    placeholder="Введіть Ваш пароль"
                    [type]="hidePassword ? 'password' : 'text'" />
                <mat-icon
                    matSuffix
                    (click)="hidePassword = !hidePassword">
                    {{ hidePassword ? 'lock' : 'lock_open' }}
                </mat-icon>
                <mat-error *ngIf="registrationForm.controls['password'].hasError('required')"> Пароль <strong>Обов'язковий</strong> </mat-error>
                <mat-error *ngIf="registrationForm.controls['password'].hasError('minlength')"> Мінімальна довжина <strong>6 символів</strong> </mat-error>
            </mat-form-field>
        </div>
        <div class="form-row">
            <mat-form-field
                [floatLabel]="'always'"
                appearance="fill"
                class="auth-registration-input">
                <mat-label>Повторіть пароль</mat-label>
                <input
                    matInput
                    placeholder="Повторіть вище введений пароль"
                    [type]="hideRePassword ? 'password' : 'text'"
                    formControlName="repassword" />
                <mat-icon
                    matSuffix
                    (click)="hideRePassword = !hideRePassword">
                    {{ hideRePassword ? 'lock' : 'lock_open' }}
                </mat-icon>
                <mat-error *ngIf="registrationForm.controls['repassword'].hasError('required')"> Повтор паролю <strong>Обов'язковий</strong> </mat-error>
                <mat-error *ngIf="registrationForm.controls['repassword'].hasError('minlength')"> Мінімальна довжина <strong>6 символів</strong> </mat-error>
            </mat-form-field>
        </div>
        <div class="auth-registration-buttons">
            <app-error-message
                *ngIf="error"
                [errorMessage]="error"></app-error-message>
            <button
                mat-flat-button
                color="primary"
                class="auth-registration-buttons__button">
                Зареєструватися
            </button>
        </div>
    </form>
</div>
