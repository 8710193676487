import { createReducer, on } from '@ngrx/store';
import { LoadingState, RequestState } from '../../core/models/request-state.model';
import { GenericTypeModel } from '../../core/models/generic-type.model';
import {
    adminFailed,
    genericTypesCreate,
    genericTypesDelete,
    genericTypesGet,
    genericTypesGetSuccess,
    genericTypesUpdate,
    opportunityCreate,
    opportunityDelete,
    opportunityGetOne,
    opportunityGetOneSuccess,
    opportunitiesGetSuccess,
    opportunityUpdate,
    opportunitiesGet,
    opportunityRequestsGet,
    opportunityRequestsGetSuccess,
    userProfileGet,
    userProfileGetSuccess,
    opportunityRequestUpdate,
    opportunityRequestUpdateSuccess,
    opportunityRequestGet,
    opportunityRequestGetSuccess,
    usersProfilesGet,
    usersProfilesGetSuccess,
    userGet,
    userGetSuccess,
    userCreateSuccess,
    userCreate,
    userUpdateSuccess,
    userUpdate,
    userResetPassword,
    userResetPasswordSuccess,
    userProfileUpdateSuccess,
    userProfileUpdate,
    opportunityRequestsGetByUser,
    opportunityRequestsGetByUserSuccess,
    usersGet,
    usersGetSuccess,
    projectCreate,
    projectCreateSuccess,
    projectGet,
    projectGetSuccess,
    projectUpdate,
    projectUpdateSuccess,
    projectsGet,
    projectsGetSuccess,
    opportunityRequestDeleteSuccess,
    opportunityRequestDelete,
    opportunityCreateSuccess,
    opportunityUpdateSuccess,
    opportunityDeleteSuccess,
    opportunitySuggestmentHelpGetSuccess,
    opportunitySuggestmentHelpGet,
    opportunityRequestsReportGetSuccess,
    opportunityRequestsReportGet,
    dataExportGet,
    dataExportsGet,
    dataExportsGetSuccess,
    dataExportGetSuccess,
    dataExportGenerate,
    dataExportGenerateSuccess,
    dataExportDelete,
    dataExportDeleteSuccess,
    opportunityRequestMassUpdate,
    opportunityRequestMassUpdateSuccess,
} from './admin.actions';
import { EMPTY_OPPORTUNITY, OpportunityModel } from '../../core/models/opportunity.model';
import { IUserBase, IUserProfile, UserModel } from '../../core/models/user.model';
import { OpportunityRequestModel } from '../../core/models/opportunity-request.model';
import { ProjectModel } from '../../core/models/project.model';
import { DataExportModel } from '../../core/models/data-export.model';

export interface IAdminState {
    genericTypes: GenericTypeModel[];
    requestState: RequestState;
    redirectUrl: string;
    opportunity: OpportunityModel | null;
    opportunityRequest: OpportunityRequestModel | null;
    opportunities: OpportunityModel[];
    opportunityRequests: OpportunityRequestModel[];
    opportunityRequestsTotal: number;
    opportunityRequestsUserProfiles: Map<string, IUserProfile>;
    user: UserModel | null;
    users: UserModel[];
    usersTotal: number;
    userOpportunityRequests: OpportunityRequestModel[];
    userProfile: IUserProfile | null;
    usersProfiles: IUserProfile[];
    project: ProjectModel | null;
    projects: ProjectModel[];
    dataExport: DataExportModel | null;
    dataExports: DataExportModel[];
}

const initialState: IAdminState = {
    genericTypes: [],
    requestState: LoadingState.INIT,
    redirectUrl: '',
    opportunity: null,
    opportunityRequest: null,
    opportunities: [],
    opportunityRequests: [],
    opportunityRequestsTotal: 0,
    userOpportunityRequests: [],
    opportunityRequestsUserProfiles: new Map(),
    user: null,
    users: [],
    usersTotal: 0,
    userProfile: null,
    usersProfiles: [],
    project: null,
    projects: [],
    dataExport: null,
    dataExports: [],
};

export const admintReducer = createReducer(
    initialState,
    on(adminFailed, (state, props) => {
        return {
            ...state,
            requestState: { error: props.error },
        };
    }),
    on(genericTypesGet, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunitiesGet, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunitiesGetSuccess, (state, props) => {
        return {
            ...state,
            opportunities: props.opportunities,
            requestState: LoadingState.LOADED,
        };
    }),
    on(opportunityGetOne, (state) => {
        return {
            ...state,
            opportunity: new OpportunityModel(EMPTY_OPPORTUNITY),
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunityGetOneSuccess, (state, props) => {
        return {
            ...state,
            opportunity: props.opportunity,
            requestState: LoadingState.LOADED,
        };
    }),

    on(opportunityCreate, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunityCreateSuccess, (state, props) => {
        return {
            ...state,
            opportunity: props.opportunity,
            requestState: LoadingState.LOADED,
        };
    }),
    on(opportunityUpdate, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunityUpdateSuccess, (state, props) => {
        return {
            ...state,
            opportunity: props.opportunity,
            requestState: LoadingState.LOADED,
        };
    }),
    on(opportunityDelete, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunityDeleteSuccess, (state, props) => {
        return {
            ...state,
            opportunity: null,
            requestState: LoadingState.LOADED,
        };
    }),
    on(genericTypesCreate, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(genericTypesUpdate, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(genericTypesDelete, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(genericTypesGetSuccess, (state, props) => {
        console.log('genericTypesGetSuccess');
        return {
            ...state,
            requestState: LoadingState.LOADED,
            genericTypes: props.genericTypes,
        };
    }),
    on(opportunityRequestsGet, (state) => {
        return {
            ...state,
            opportunityRequestsUserProfiles: new Map(),
            opportunityRequests: [],
            opportunityRequestsReport: [],
            opportunityRequestsTotal: 0,
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunityRequestsGetSuccess, (state, props) => {
        return {
            ...state,
            opportunityRequests: props.opportunityRequests,
            opportunityRequestsTotal: props.opportunityRequestsTotal,
            requestState: LoadingState.LOADED,
        };
    }),
    on(opportunityRequestGet, (state) => {
        return {
            ...state,
            opportunityRequest: null,
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunityRequestGetSuccess, (state, props) => {
        const updateProfiles = new Map(state.opportunityRequestsUserProfiles);
        const profileId = props.opportunityRequest.getProfile()?.id || '';
        if (profileId) {
            updateProfiles.set(profileId, props.opportunityRequest.getProfile()!);
        }

        return {
            ...state,
            opportunityRequestsUserProfiles: updateProfiles,
            opportunityRequest: props.opportunityRequest,
            requestState: LoadingState.LOADED,
        };
    }),
    on(opportunityRequestUpdate, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunityRequestUpdateSuccess, (state, props) => {
        const unpdatedOpportunityRequests = [...state.opportunityRequests];
        const updateOpportunityRequest = unpdatedOpportunityRequests.find((or) => or.getId() === props.opportunityRequest.getId());
        if (updateOpportunityRequest) {
            const indexOfUpdateOpportunityRequest = unpdatedOpportunityRequests.indexOf(updateOpportunityRequest);
            unpdatedOpportunityRequests.splice(indexOfUpdateOpportunityRequest, 1, props.opportunityRequest);
        }

        console.log('opportunityRequestUpdateSuccess');

        return {
            ...state,
            opportunityRequest: props.opportunityRequest,
            opportunityRequests: unpdatedOpportunityRequests,
            requestState: LoadingState.LOADED,
        };
    }),
    on(opportunityRequestMassUpdate, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunityRequestMassUpdateSuccess, (state, props) => {
        const unpdatedOpportunityRequests = [...state.opportunityRequests];

        props.opportunityRequests.forEach((upRequests) => {
            const updateOpportunityRequest = unpdatedOpportunityRequests.find((or) => or.getId() === upRequests.getId());
            if (updateOpportunityRequest) {
                const indexOfUpdateOpportunityRequest = unpdatedOpportunityRequests.indexOf(updateOpportunityRequest);
                unpdatedOpportunityRequests.splice(indexOfUpdateOpportunityRequest, 1, upRequests);
            }
        });

        console.log('opportunityRequestMassUpdateSuccess');

        return {
            ...state,
            opportunityRequests: unpdatedOpportunityRequests,
            requestState: LoadingState.LOADED,
        };
    }),
    on(opportunityRequestDelete, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunityRequestDeleteSuccess, (state, props) => {
        const unpdatedOpportunityRequests = [...state.opportunityRequests].filter((or) => or.baseRequest.id !== props.opportunityRequest.baseRequest.id);

        return {
            ...state,
            opportunityRequest: null,
            opportunityRequests: unpdatedOpportunityRequests,
            requestState: LoadingState.LOADED,
        };
    }),
    on(userProfileGet, (state) => {
        return {
            ...state,
            userProfile: null,
            requestState: LoadingState.LOADING,
        };
    }),
    on(userProfileGetSuccess, (state, props) => {
        const currentUserProfiles = new Map(state.opportunityRequestsUserProfiles);
        if (props.profile.id) {
            currentUserProfiles.set(props.profile.id, props.profile);
        }

        return {
            ...state,
            opportunityRequestsUserProfiles: currentUserProfiles,
            requestState: LoadingState.LOADED,
        };
    }),
    on(userProfileUpdate, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(userProfileUpdateSuccess, (state, props) => {
        return {
            ...state,
            userProfile: props.profile,
            requestState: LoadingState.LOADED,
        };
    }),
    on(usersProfilesGet, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(usersProfilesGetSuccess, (state, props) => {
        return {
            ...state,
            usersProfiles: props.usersProfiles,
            requestState: LoadingState.LOADED,
        };
    }),
    on(userGet, (state) => {
        return {
            ...state,
            user: null,
            requestState: LoadingState.LOADING,
        };
    }),
    on(userGetSuccess, (state, props) => {
        return {
            ...state,
            user: props.user,
            requestState: LoadingState.LOADED,
        };
    }),
    on(usersGet, (state) => {
        return {
            ...state,
            users: [],
            usersTotal: 0,
            requestState: LoadingState.LOADING,
        };
    }),
    on(usersGetSuccess, (state, props) => {
        return {
            ...state,
            users: props.users,
            usersTotal: props.usersTotal,
            requestState: LoadingState.LOADED,
        };
    }),
    on(userCreate, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(userCreateSuccess, (state, props) => {
        return {
            ...state,
            user: props.user,
            requestState: LoadingState.LOADED,
        };
    }),
    on(userUpdate, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(userUpdateSuccess, (state, props) => {
        return {
            ...state,
            user: props.user,
            requestState: LoadingState.LOADED,
        };
    }),
    on(userResetPassword, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(userResetPasswordSuccess, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADED,
        };
    }),
    on(opportunityRequestsGetByUser, (state) => {
        return {
            ...state,
            userOpportunityRequests: [],
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunityRequestsGetByUserSuccess, (state, props) => {
        return {
            ...state,
            userOpportunityRequests: props.opportunityRequests,
            requestState: LoadingState.LOADED,
        };
    }),
    on(projectGet, (state) => {
        return {
            ...state,
            project: null,
            requestState: LoadingState.LOADING,
        };
    }),
    on(projectGetSuccess, (state, props) => {
        return {
            ...state,
            project: props.project,
            requestState: LoadingState.LOADED,
        };
    }),
    on(projectsGet, (state) => {
        return {
            ...state,
            projects: [],
            requestState: LoadingState.LOADING,
        };
    }),
    on(projectsGetSuccess, (state, props) => {
        return {
            ...state,
            projects: props.projects,
            requestState: LoadingState.LOADED,
        };
    }),
    on(projectCreate, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(projectCreateSuccess, (state, props) => {
        return {
            ...state,
            project: props.project,
            requestState: LoadingState.LOADED,
        };
    }),
    on(projectUpdate, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(projectUpdateSuccess, (state, props) => {
        return {
            ...state,
            project: props.project,
            requestState: LoadingState.LOADED,
        };
    }),
    on(opportunitySuggestmentHelpGet, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunityRequestsReportGet, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunityRequestsReportGetSuccess, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADED,
        };
    }),
    on(dataExportGenerate, (state) => {
        return {
            ...state,
            dataExport: null,
            requestState: LoadingState.LOADING,
        };
    }),
    on(dataExportGenerateSuccess, (state, props) => {
        return {
            ...state,
            dataExport: props.dataExport,
            requestState: LoadingState.LOADED,
        };
    }),
    on(dataExportGet, (state) => {
        return {
            ...state,
            dataExport: null,
            requestState: LoadingState.LOADING,
        };
    }),
    on(dataExportGetSuccess, (state, props) => {
        return {
            ...state,
            dataExport: props.dataExport,
            requestState: LoadingState.LOADED,
        };
    }),
    on(dataExportsGet, (state) => {
        return {
            ...state,
            dataExports: [],
            requestState: LoadingState.LOADING,
        };
    }),
    on(dataExportsGetSuccess, (state, props) => {
        return {
            ...state,
            dataExports: props.dataExports,
            requestState: LoadingState.LOADED,
        };
    }),
    on(dataExportDelete, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(dataExportDeleteSuccess, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADED,
        };
    })
);
