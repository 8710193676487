export interface IAddress {
    id?: string;
    country?: string;
    region: string;
    city: string;
    state?: string;
    address1: string;
    zip: string;
}

export const EMPTY_BASE_ADDRESS: IAddress = {
    country: '',
    region: '',
    city: '',
    state: '',
    address1: '',
    zip: '',
};
