import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { IApplicationState } from '../../../app.reducer';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class MaintanceGuard implements CanActivate {
    constructor(private router: Router, private store: Store<IApplicationState>) {}

    canActivate(): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
        const isMaintenance = environment.maintenance;
        if(isMaintenance) {
            return this.router.createUrlTree(['maintenance']);
        }
        return true;
    }
}
