import { Component } from '@angular/core';

@Component({
  selector: 'app-home-maintenance',
  templateUrl: './home-maintenance.component.html',
  styleUrls: ['./home-maintenance.component.scss']
})
export class HomeMaintenanceComponent {

}
