import { createAction, props } from '@ngrx/store';
import { GenericTypeModel } from '../../core/models/generic-type.model';
import { OpportunityModel } from '../../core/models/opportunity.model';
import { IOpportunityRequestSuggestion, OpportunityRequestFulfillType, OpportunityRequestModel, OpportunityRequestStatus } from '../../core/models/opportunity-request.model';
import { IUserBase, IUserProfile, UserModel, UserRole, UserStatus } from '../../core/models/user.model';
import { ProjectModel } from '../../core/models/project.model';
import { DataExportModel } from '../../core/models/data-export.model';

export const CLEAR_ERROR = '[Admin] CLEAR_ERROR';

export const GENERIC_TYPES_GET = '[Admin] GENERIC_TYPES_GET';
export const GENERIC_TYPES_CREATE = '[Admin] GENERIC_TYPES_CREATE';
export const GENERIC_TYPES_UPDATE = '[Admin] GENERIC_TYPES_UPDATE';
export const GENERIC_TYPES_DELETE = '[Admin] GENERIC_TYPES_DELETE';
export const GENERIC_TYPES_GET_SUCCESS = '[Admin] GENERIC_TYPES_GET_SUCCESS';

export const OPPORTUNITIES_GET = '[Admin] OPPORTUNITIES_GET';
export const OPPORTUNITIES_GET_SUCCESS = '[Admin] OPPORTUNITIES_GET_SUCCESS';
export const OPPORTUNITY_GET_ONE = '[Admin] OPPORTUNITY_GET_ONE';
export const OPPORTUNITY_GET_ONE_SUCCESS = '[Admin] OPPORTUNITY_GET_ONE_SUCCESS';
export const OPPORTUNITY_CREATE = '[Admin] OPPORTUNITY_CREATE';
export const OPPORTUNITY_CREATE_SUCCESS = '[Admin] OPPORTUNITY_CREATE_SUCCESS';
export const OPPORTUNITY_UPDATE = '[Admin] OPPORTUNITY_UPDATE';
export const OPPORTUNITY_UPDATE_SUCCESS = '[Admin] OPPORTUNITY_UPDATE_SUCCESS';
export const OPPORTUNITY_DELETE = '[Admin] OPPORTUNITY_DELETE';
export const OPPORTUNITY_DELETE_SUCCESS = '[Admin] OPPORTUNITY_DELETE_SUCCESS';

export const USER_PROFILE_GET = '[Admin] USER_PROFILE_GET';
export const USER_PROFILE_GET_SUCCESS = '[Admin] USER_PROFILE_GET_SUCCESS';
export const USER_PROFILE_UPDATE = '[Admin] USER_PROFILE_UPDATE';
export const USER_PROFILE_UPDATE_SUCCESS = '[Admin] USER_PROFILE_UPDATE_SUCCESS';
export const USERS_PROFILES_GET = '[Admin] USERS_PROFILES_GET';
export const USERS_PROFILES_GET_SUCCESS = '[Admin] USERS_PROFILES_GET_SUCCESS';
export const USER_GET = '[Admin] USER_GET';
export const USER_GET_SUCCESS = '[Admin] USER_GET_SUCCESS';
export const USERS_GET = '[Admin] USERS_GET';
export const USERS_GET_SUCCESS = '[Admin] USERS_GET_SUCCESS';
export const USER_CREATE = '[Admin] USER_CREATE';
export const USER_CREATE_SUCCESS = '[Admin] USER_CREATE_SUCCESS';
export const USER_UPDATE = '[Admin] USER_UPDATE';
export const USER_UPDATE_SUCCESS = '[Admin] USER_UPDATE_SUCCESS';
export const USER_RESET_PASSWORD = '[Admin] USER_RESET_PASSWORD';
export const USER_RESET_PASSWORD_SUCCESS = '[Admin] USER_RESET_PASSWORD_SUCCESS';

export const PROJECT_GET = '[Admin] PROJECT_GET';
export const PROJECT_GET_SUCCESS = '[Admin] PROJECT_GET_SUCCESS';
export const PROJECTS_GET = '[Admin] PROJECTS_GET';
export const PROJECTS_GET_SUCCESS = '[Admin] PROJECTS_GET_SUCCESS';
export const PROJECT_CREATE = '[Admin] PROJECT_CREATE';
export const PROJECT_CREATE_SUCCESS = '[Admin] PROJECT_CREATE_SUCCESS';
export const PROJECT_UPDATE = '[Admin] PROJECT_UPDATE';
export const PROJECT_UPDATE_SUCCESS = '[Admin] PROJECT_UPDATE_SUCCESS';

export const DATA_EXPORT_GENERATE = '[Admin] DATA_EXPORT_GENERATE';
export const DATA_EXPORT_GENERATE_SUCCESS = '[Admin] DATA_EXPORT_GENERATE_SUCCESS';
export const DATA_EXPORT_GET = '[Admin] DATA_EXPORT_GET';
export const DATA_EXPORT_GET_SUCCESS = '[Admin] DATA_EXPORT_GET_SUCCESS';
export const DATA_EXPORTS_GET = '[Admin] DATA_EXPORTS_GET';
export const DATA_EXPORTS_GET_SUCCESS = '[Admin] DATA_EXPORTS_GET_SUCCESS';
export const DATA_EXPORT_DELETE = '[Admin] DATA_EXPORT_DELETE';
export const DATA_EXPORT_DELETE_SUCCESS = '[Admin] DATA_EXPORT_DELETE_SUCCESS';

export const OPPORTUNITY_REQUESTS_GET = '[Admin] OPPORTUNITY_REQUESTS_GET';
export const OPPORTUNITY_REQUESTS_GET_SUCCESS = '[Admin] OPPORTUNITY_REQUESTS_GET_SUCCESS';
export const OPPORTUNITY_REQUESTS_GET_BY_USER = '[Admin] OPPORTUNITY_REQUESTS_GET_BY_USER';
export const OPPORTUNITY_REQUESTS_GET_BY_USER_SUCCESS = '[Admin] OPPORTUNITY_REQUESTS_GET_BY_USER_SUCCESS';
export const OPPORTUNITY_REQUEST_GET = '[Admin] OPPORTUNITY_REQUEST_GET';
export const OPPORTUNITY_REQUEST_GET_SUCCESS = '[Admin] OPPORTUNITY_REQUEST_GET_SUCCESS';
export const OPPORTUNITY_REQUEST_UPDATE = '[Admin] OPPORTUNITY_REQUEST_UPDATE';
export const OPPORTUNITY_REQUEST_UPDATE_SUCCESS = '[Admin] OPPORTUNITY_REQUEST_UPDATE_SUCCESS';
export const OPPORTUNITY_REQUEST_MASS_UPDATE = '[Admin] OPPORTUNITY_REQUEST_MASS_UPDATE';
export const OPPORTUNITY_REQUEST_MASS_UPDATE_SUCCESS = '[Admin] OPPORTUNITY_REQUEST_MASS_UPDATE_SUCCESS';

export const OPPORTUNITY_REQUEST_DELETE = '[Admin] OPPORTUNITY_REQUEST_DELETE';
export const OPPORTUNITY_REQUEST_DELETE_SUCCESS = '[Admin] OPPORTUNITY_REQUEST_DELETE_SUCCESS';

export const OPPORTUNITY_SUGGESTMENT_HELP_GET = '[Admin] OPPORTUNITY_SUGGESTMENT_HELP_GET';
export const OPPORTUNITY_SUGGESTMENT_HELP_GET_SUCCESS = '[Admin] OPPORTUNITY_SUGGESTMENT_HELP_GET_SUCCESS';

export const OPPORTUNITY_REQUESTS_REPORT_GET = '[Admin] OPPORTUNITY_REQUESTS_REPORT_GET';
export const OPPORTUNITY_REQUESTS_REPORT_GET_SUCCESS = '[Admin] OPPORTUNITY_REQUESTS_REPORT_GET_SUCCESS';

export const ADMIN_FAILED = '[Admin] ADMIN_FAILED';

export const clearError = createAction(CLEAR_ERROR);

export const opportunitiesGet = createAction(OPPORTUNITIES_GET);
export const opportunitiesGetSuccess = createAction(OPPORTUNITIES_GET_SUCCESS, props<{ opportunities: OpportunityModel[] }>());
export const opportunityGetOne = createAction(OPPORTUNITY_GET_ONE, props<{ opportunityId: string }>());
export const opportunityGetOneSuccess = createAction(OPPORTUNITY_GET_ONE_SUCCESS, props<{ opportunity: OpportunityModel }>());
export const opportunityCreate = createAction(OPPORTUNITY_CREATE, props<{ opportunity: OpportunityModel }>());
export const opportunityCreateSuccess = createAction(OPPORTUNITY_CREATE_SUCCESS, props<{ opportunity: OpportunityModel }>());
export const opportunityUpdate = createAction(OPPORTUNITY_UPDATE, props<{ opportunity: OpportunityModel }>());
export const opportunityUpdateSuccess = createAction(OPPORTUNITY_UPDATE_SUCCESS, props<{ opportunity: OpportunityModel }>());
export const opportunityDelete = createAction(OPPORTUNITY_DELETE, props<{ opportunity: OpportunityModel }>());
export const opportunityDeleteSuccess = createAction(OPPORTUNITY_DELETE_SUCCESS, props<{ opportunity: OpportunityModel }>());

export const userProfileGet = createAction(USER_PROFILE_GET, props<{ profileId: string }>());
export const userProfileGetSuccess = createAction(USER_PROFILE_GET_SUCCESS, props<{ profile: IUserProfile }>());
export const userProfileUpdate = createAction(USER_PROFILE_UPDATE, props<{ profile: IUserProfile }>());
export const userProfileUpdateSuccess = createAction(USER_PROFILE_UPDATE_SUCCESS, props<{ profile: IUserProfile }>());
export const usersProfilesGet = createAction(USERS_PROFILES_GET, props<{ roles: UserStatus[]; searchPattern: string }>());
export const usersProfilesGetSuccess = createAction(USERS_PROFILES_GET_SUCCESS, props<{ usersProfiles: IUserProfile[] }>());
export const userGet = createAction(USER_GET, props<{ userId: string }>());
export const userGetSuccess = createAction(USER_GET_SUCCESS, props<{ user: UserModel }>());
export const usersGet = createAction(USERS_GET, props<{ statuses: UserStatus[]; searchPattern: string; limit: number; page: number, sortField: string, sortDirection: string }>());
export const usersGetSuccess = createAction(USERS_GET_SUCCESS, props<{ users: UserModel[]; usersTotal: number }>());
export const userCreate = createAction(USER_CREATE, props<{ email: string; password: string }>());
export const userCreateSuccess = createAction(USER_CREATE_SUCCESS, props<{ user: UserModel }>());
export const userUpdate = createAction(USER_UPDATE, props<{ userId: string; status: UserStatus; roles: UserRole[] }>());
export const userUpdateSuccess = createAction(USER_UPDATE_SUCCESS, props<{ user: UserModel }>());
export const userResetPassword = createAction(USER_RESET_PASSWORD, props<{ userId: string; password: string }>());
export const userResetPasswordSuccess = createAction(USER_RESET_PASSWORD_SUCCESS);

export const projectGet = createAction(PROJECT_GET, props<{ projectId: string }>());
export const projectGetSuccess = createAction(PROJECT_GET_SUCCESS, props<{ project: ProjectModel }>());
export const projectsGet = createAction(PROJECTS_GET);
export const projectsGetSuccess = createAction(PROJECTS_GET_SUCCESS, props<{ projects: ProjectModel[] }>());
export const projectCreate = createAction(PROJECT_CREATE, props<{ project: ProjectModel }>());
export const projectCreateSuccess = createAction(PROJECT_CREATE_SUCCESS, props<{ project: ProjectModel }>());
export const projectUpdate = createAction(PROJECT_UPDATE, props<{ project: ProjectModel }>());
export const projectUpdateSuccess = createAction(PROJECT_UPDATE_SUCCESS, props<{ project: ProjectModel }>());


export const dataExportGenerate = createAction(DATA_EXPORT_GENERATE, props<{ opportunityId: string }>());
export const dataExportGenerateSuccess = createAction(DATA_EXPORT_GENERATE_SUCCESS, props<{ dataExport: DataExportModel }>());
export const dataExportGet = createAction(DATA_EXPORT_GET, props<{ dataExportId: string }>());
export const dataExportGetSuccess = createAction(DATA_EXPORT_GET_SUCCESS, props<{ dataExport: DataExportModel }>());
export const dataExportsGet = createAction(DATA_EXPORTS_GET);
export const dataExportsGetSuccess = createAction(DATA_EXPORTS_GET_SUCCESS, props<{ dataExports: DataExportModel[] }>());
export const dataExportDelete = createAction(DATA_EXPORT_DELETE, props<{ dataExportId: string }>());
export const dataExportDeleteSuccess = createAction(DATA_EXPORT_DELETE_SUCCESS, props<{ dataExport: DataExportModel }>());

export const opportunityRequestsGet = createAction(
    OPPORTUNITY_REQUESTS_GET,
    props<{ opportunityId: string; statuses: OpportunityRequestStatus[]; fulfilled: boolean; suggested: boolean; attended: boolean; limit: number; page: number }>()
);
export const opportunityRequestsGetSuccess = createAction(OPPORTUNITY_REQUESTS_GET_SUCCESS, props<{ opportunityRequests: OpportunityRequestModel[]; opportunityRequestsTotal: number }>());
export const opportunityRequestsGetByUser = createAction(OPPORTUNITY_REQUESTS_GET_BY_USER, props<{ userId: string }>());
export const opportunityRequestsGetByUserSuccess = createAction(OPPORTUNITY_REQUESTS_GET_BY_USER_SUCCESS, props<{ opportunityRequests: OpportunityRequestModel[] }>());
export const opportunityRequestGet = createAction(OPPORTUNITY_REQUEST_GET, props<{ opportunityRequestId: string }>());
export const opportunityRequestGetSuccess = createAction(OPPORTUNITY_REQUEST_GET_SUCCESS, props<{ opportunityRequest: OpportunityRequestModel }>());
export const opportunityRequestUpdate = createAction(OPPORTUNITY_REQUEST_UPDATE, props<{ opportunityRequest: OpportunityRequestModel }>());
export const opportunityRequestUpdateSuccess = createAction(OPPORTUNITY_REQUEST_UPDATE_SUCCESS, props<{ opportunityRequest: OpportunityRequestModel }>());
export const opportunityRequestMassUpdate = createAction(OPPORTUNITY_REQUEST_MASS_UPDATE, props<{ opportunityRequests: OpportunityRequestModel[] }>());
export const opportunityRequestMassUpdateSuccess = createAction(OPPORTUNITY_REQUEST_MASS_UPDATE_SUCCESS, props<{ opportunityRequests: OpportunityRequestModel[] }>());
export const opportunityRequestDelete = createAction(OPPORTUNITY_REQUEST_DELETE, props<{ opportunityRequest: OpportunityRequestModel }>());
export const opportunityRequestDeleteSuccess = createAction(OPPORTUNITY_REQUEST_DELETE_SUCCESS, props<{ opportunityRequest: OpportunityRequestModel }>());

export const genericTypesGet = createAction(GENERIC_TYPES_GET);
export const genericTypesCreate = createAction(GENERIC_TYPES_CREATE, props<{ genericType: GenericTypeModel }>());
export const genericTypesUpdate = createAction(GENERIC_TYPES_UPDATE, props<{ genericType: GenericTypeModel }>());
export const genericTypesDelete = createAction(GENERIC_TYPES_DELETE, props<{ genericType: GenericTypeModel }>());
export const genericTypesGetSuccess = createAction(GENERIC_TYPES_GET_SUCCESS, props<{ genericTypes: GenericTypeModel[] }>());

export const opportunityRequestsReportGet = createAction(
    OPPORTUNITY_REQUESTS_REPORT_GET,
    props<{ opportunityId: string; statuses: OpportunityRequestStatus[]; fulfilled: boolean; suggested: boolean; attended: boolean; }>()
);
export const opportunityRequestsReportGetSuccess = createAction(OPPORTUNITY_REQUESTS_REPORT_GET_SUCCESS);

export const opportunitySuggestmentHelpGet = createAction(
    OPPORTUNITY_SUGGESTMENT_HELP_GET,
    props<{ opportunityId: string; statuses: OpportunityRequestStatus[]; fulfilled: boolean; suggested: boolean; attended: boolean; limit: number; page: number }>()
);
export const opportunitySuggestmentHelpGetSuccess = createAction(OPPORTUNITY_SUGGESTMENT_HELP_GET_SUCCESS, props<{ gini: number }>());

export const adminFailed = createAction(ADMIN_FAILED, props<{ error: string, initialError: string }>());
