import { OpportunityRequestModel } from '../models/opportunity-request.model';
import { Gender, IUserProfile } from '../models/user.model';

const childAge = 18;
const elderlyAge = 60;

export enum PersonAgeDistrinutiontType {
    common = 0,
    withTemporaryID = 1,
    withInjury = 2,
}

export class ProfileHelper {
    static getPersonAgeDistribution = (profle: IUserProfile, personAgeDistrinutiontType: PersonAgeDistrinutiontType = PersonAgeDistrinutiontType.common) => {
        let numberOfMaleAdults = 0;
        let numberOfFemaleAdults = 0;
        let numberOfMaleChilden = 0;
        let numberOfFemaleChilden = 0;
        let numberOfMaleEderly = 0;
        let numberOfFemaleEderly = 0;

        let personsList = [];

        let personModel: {
            birthdate: Date;
            gender: Gender;
            temporaryID: string;
            hasInjury: boolean;
        } = {
            birthdate: profle.birthday || new Date(),
            gender: profle.gender,
            temporaryID: profle.temporaryId,
            hasInjury: profle.hasInjury,
        };

        personsList.push(personModel);

        if (profle.additionalProfiles && profle.additionalProfiles.length) {
            for (let additionalPerson of profle.additionalProfiles) {
                personModel = {
                    birthdate: additionalPerson.birthday || new Date(),
                    gender: additionalPerson.gender,
                    temporaryID: profle.temporaryId,
                    hasInjury: profle.hasInjury,
                };

                personsList.push(personModel);
            }
        }

        if (personAgeDistrinutiontType === PersonAgeDistrinutiontType.withTemporaryID) {
            personsList = personsList.filter((person) => person.temporaryID);
        }
        if (personAgeDistrinutiontType === PersonAgeDistrinutiontType.withInjury) {
            personsList = personsList.filter((person) => person.hasInjury);
        }

        for (let item of personsList) {
            if (ProfileHelper.isChild(item.birthdate)) {
                switch (item.gender) {
                    case Gender.male:
                        numberOfMaleChilden++;
                        break;
                    case Gender.female:
                        numberOfFemaleChilden++;
                        break;
                }
            } else if (ProfileHelper.isAdult(item.birthdate)) {
                switch (item.gender) {
                    case Gender.male:
                        numberOfMaleAdults++;
                        break;
                    case Gender.female:
                        numberOfFemaleAdults++;
                        break;
                }
            } else if (ProfileHelper.isEderly(item.birthdate)) {
                switch (item.gender) {
                    case Gender.male:
                        numberOfMaleEderly++;
                        break;
                    case Gender.female:
                        numberOfFemaleEderly++;
                        break;
                }
            }
        }

        return [numberOfMaleAdults, numberOfFemaleAdults, numberOfMaleChilden, numberOfFemaleChilden, numberOfMaleEderly, numberOfFemaleEderly];
    };

    static getPersonsSetupFromOpportunityRequests = (opprotunityRequests: OpportunityRequestModel[]) => {
        const personsSetup: number[][][] = [];
        opprotunityRequests.forEach((or) => {
            const familySetup: number[][] = [];
            const profiles: IUserProfile[] = [];

            const mainProfile = or.getProfile();
            if (mainProfile) {
                profiles.push(mainProfile);
                mainProfile.additionalProfiles?.forEach((p) => profiles.push(p));
            }

            profiles.forEach((p) => {
                const personSetup: number[] = [];

                if (p.gender === Gender.male) {
                    personSetup.push(1);
                } else {
                    personSetup.push(0);
                }

                if (ProfileHelper.isChild(p.birthday || new Date())) {
                    personSetup.push(0);
                } else if (ProfileHelper.isAdult(p.birthday || new Date())) {
                    personSetup.push(1);
                } else {
                    personSetup.push(2);
                }

                if (p.hasInjury) {
                    personSetup.push(1);
                } else {
                    personSetup.push(0);
                }

                if (p.temporaryId) {
                    personSetup.push(1);
                } else {
                    personSetup.push(0);
                }

                familySetup.push(personSetup);
            });

            personsSetup.push(familySetup);
        });

        return personsSetup;
    };

    static getFamilyHelpLastTimeReceivedRequests = (opprotunityRequests: OpportunityRequestModel[]) => {
        const timeReceived: number[] = [];
        opprotunityRequests.forEach((or) => {
            if (or.baseRequest.fulfillDate) {
                const fulfillDate = new Date(or.baseRequest.fulfillDate);
                const dayCountLastTimeReceivedHelpMls: number = Math.abs(new Date().valueOf() - fulfillDate.valueOf());
                if (dayCountLastTimeReceivedHelpMls > 0) {
                    const days = Math.floor(dayCountLastTimeReceivedHelpMls / 1000 / 60 / 60 / 24) * -1;
                    timeReceived.push(days);
                } else {
                    timeReceived.push(0);
                }
            } else {
                timeReceived.push(-1000);
            }
        });

        return timeReceived;
    };

    static isChild = (birthdate: string | Date) => {
        const birthDate = new Date(birthdate);
        const age = ProfileHelper.getYearsFromDown(birthDate);

        return age <= childAge;
    };

    static isAdult = (birthdate: string | Date) => {
        const birthDate = new Date(birthdate);
        const age = ProfileHelper.getYearsFromDown(birthDate);

        return age > childAge && age < elderlyAge;
    };

    static isEderly = (birthdate: string | Date) => {
        const birthDate = new Date(birthdate);
        const age = ProfileHelper.getYearsFromDown(birthDate);

        return age >= elderlyAge;
    };

    static getYearsFromDown(date: Date) {
        const today = new Date();
        const years = Math.floor((today.getTime() - date.getTime()) / 8.64e7 / 365.25);
        return years;
    }

    static substructYearsFromDate(date: Date, years: number): string {
        const resultYear = date.getFullYear() - years;
        const resultMonth = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
        const resultDay = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
        const result = `${resultYear}-${resultMonth}-${resultDay}`;
        return result;
    }

    static getNumberOfInjured = (profile: IUserProfile) => {
        const personsList = [];

        let personModel: {
            hasInjury: boolean;
        } = {
            hasInjury: profile.hasInjury,
        };

        personsList.push(personModel);

        if (profile.additionalProfiles && profile.additionalProfiles.length) {
            for (let additionalPerson of profile.additionalProfiles) {
                personModel = {
                    hasInjury: additionalPerson.hasInjury,
                };

                personsList.push(personModel);
            }
        }

        return personsList.filter((p) => p.hasInjury).length;
    };

    static getUserTemporaryIDNumbers = (profile: IUserProfile): string[] => {
        const documentsNumbers: string[] = [];
        if (profile.temporaryId) {
            documentsNumbers.push(profile.temporaryId);
        }

        if (profile.additionalProfiles && profile.additionalProfiles.length) {
            for (let additionalPerson of profile.additionalProfiles) {
                if (additionalPerson.temporaryId) {
                    documentsNumbers.push(additionalPerson.temporaryId);
                }
            }
        }

        return documentsNumbers;
    };

    static getUserDocumentNumbers = (profile: IUserProfile): string[] => {
        const documentsNumbers: string[] = [];
        if (profile.passport) {
            documentsNumbers.push(profile.passport);
        }
        if (profile.temporaryId) {
            documentsNumbers.push(profile.temporaryId);
        }

        if (profile.additionalProfiles && profile.additionalProfiles.length) {
            for (let additionalPerson of profile.additionalProfiles) {
                if (additionalPerson.passport) {
                    documentsNumbers.push(additionalPerson.passport);
                }
                if (additionalPerson.temporaryId) {
                    documentsNumbers.push(additionalPerson.temporaryId);
                }
            }
        }

        return documentsNumbers;
    };
}
