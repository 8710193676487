import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';

// Sentry.init({
//     dsn: 'https://6030bbdf01c358ce0648accf541d06ee@o4506340290396160.ingest.sentry.io/4506340291772416',
//     integrations: [
//         new Sentry.BrowserTracing({
//             // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//             tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//             routingInstrumentation: Sentry.routingInstrumentation,
//         }),
//         new Sentry.Replay(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

enableProdMode();
if (environment && environment.production) {
    // assuming you have env variable configured
    // check if window exists, if you render backend window will not be available
    if (window) {
        window.console.log = function () {};
    }
}
platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((success) => console.log('Bootstrap success'))
    .catch((err) => console.error(err));
