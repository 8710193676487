import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShepherdService } from 'angular-shepherd';
import { IApplicationState } from 'src/app/app.reducer';
import { isMobile } from './mobile.helper';
import * as ClientActions from '../../client/store/client.actions';

type PopperPlacement = 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'right' | 'right-start' | 'right-end' | 'left' | 'left-start' | 'left-end';

@Injectable()
export class TutorialHelper {
    constructor(private shepherdService: ShepherdService, private store: Store<IApplicationState>) {}

    startTutorial() {
        const shepard = this.shepherdService;
        this.shepherdService.defaultStepOptions = {
            classes: 'shepherd-main',
            scrollTo: false,
            cancelIcon: {
                enabled: true,
            },
        };
        this.shepherdService.modal = true;
        this.shepherdService.confirmCancel = false;
        const attachToOn: PopperPlacement = isMobile() ? 'bottom-end' : 'right';
        const stepClass = 'shepherd-step';

        const step1 = {
            id: 'intro',
            beforeShowPromise: () => this.getNextStepAnimation(),
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Закрити',
                    action: function () {
                        shepard.cancel();
                    },
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Далі',
                    action: function () {
                        shepard.next();
                    },
                },
            ],
            cancelIcon: {
                enabled: true,
            },
            classes: stepClass,
            highlightClass: 'highlight',
            scrollTo: false,
            title: 'Вітаемо на порталі IAC ISHR!',
            text: [
                `Саме тут Ви зможете подати заявку на отримання гуманітарної допомоги та відслідкувати її статус.
                <div class="shepherd-title-image-container"><img src="/assets/images/top-banner-children-short.png" alt="main-image" class="shepherd-title-image" /></div>
                `,
            ],
            when: {
                show: () => {
                    console.log('show step');
                },
                hide: () => {
                    console.log('hide step');
                },
            },
        };
        const step2 = {
            id: 'intro',
            attachTo: {
                element: '.shepherd-events',
                on: attachToOn,
            },
            beforeShowPromise: () => this.getNextStepAnimation(),
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Закрити',
                    action: function () {
                        shepard.cancel();
                    },
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Назад',
                    action: function () {
                        shepard.back();
                    },
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Далі',
                    action: function () {
                        shepard.next();
                    },
                },
            ],
            cancelIcon: {
                enabled: true,
            },
            classes: stepClass,
            highlightClass: 'highlight',
            scrollTo: false,
            title: 'Розділ події',
            text: [
                `
                Слідкувати за відкритими реєстраціями на розповсюдження гуманітарної допомоги та подати заявку Ви можете у розділі <b>Події</b>.
                <div class="shepherd-title-image-container"><img src="/assets/images/step-1-tutorial.png" alt="main-image" class="shepherd-title-image" /></div>
            `,
            ],
            when: {
                show: () => {
                    if (isMobile()) {
                        this.store.dispatch(ClientActions.initToggleMenu());
                    }
                    console.log('show step');
                },
                hide: () => {
                    console.log('hide step');
                },
            },
        };
        const step3 = {
            id: 'intro',
            attachTo: {
                element: '.shepherd-requests',
                on: attachToOn,
            },
            beforeShowPromise: () => this.getNextStepAnimation(),
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Закрити',
                    action: function () {
                        shepard.cancel();
                    },
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Назад',
                    action: function () {
                        shepard.back();
                    },
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Далі',
                    action: function () {
                        shepard.next();
                    },
                },
            ],
            cancelIcon: {
                enabled: true,
            },
            classes: stepClass,
            highlightClass: 'highlight',
            scrollTo: false,
            title: 'Заявки на події',
            text: [
                `
                Статус поданої заявки Ви можете відслідкувати у розділ <b>Заявки на події</b>.
                <div class="shepherd-title-image-container"><img src="/assets/images/step-2-tutorial.png" alt="main-image" class="shepherd-title-image" /></div>
            `,
            ],
            when: {
                show: () => {
                    console.log('show step');
                },
                hide: () => {
                    console.log('hide step');
                },
            },
        };
        const step4 = {
            id: 'intro',
            attachTo: {
                element: '.shepherd-settings',
                on: attachToOn,
            },
            beforeShowPromise: () => this.getNextStepAnimation(),
            buttons: [
                {
                    classes: 'shepherd-button-primary',
                    text: 'Розпочати користування',
                    action: () => {
                        shepard.cancel();
                        if (isMobile()) {
                            this.store.dispatch(ClientActions.initToggleMenu());
                        }
                    },
                },
            ],
            cancelIcon: {
                enabled: true,
            },
            classes: stepClass,
            highlightClass: 'highlight',
            scrollTo: false,
            title: 'Налаштування',
            text: [
                `
            Для початку потрібно пройти реєстрацію на нашій платформі, уважно вказавши всі необхідні дані у розділі Налаштування. <b>Налаштування</b>.
                <div class="shepherd-title-image-container"><img src="/assets/images/step-3-tutorial.png" alt="main-image" class="shepherd-title-image" /></div>
            `,
            ],
            when: {
                show: () => {
                    console.log('show step');
                },
                hide: () => {
                    console.log('hide step');
                },
            },
        };

        this.shepherdService.addSteps([step1, step2, step3, step4]);
        this.shepherdService.start();
    }

    getNextStepAnimation() {
        return new Promise(function (resolve) {
            setTimeout(function () {
                window.scrollTo(0, 0);
                resolve(true);
            }, 300);
        });
    }
}
