import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OpportunityRequestFulfillType, OpportunityRequestModel, OpportunityRequestStatus } from 'src/app/modules/core/models/opportunity-request.model';
import { OpportunityModel } from 'src/app/modules/core/models/opportunity.model';

@Component({
    selector: 'app-opportunity-card',
    templateUrl: './opportunity-card.component.html',
    styleUrls: ['./opportunity-card.component.scss'],
})
export class OpportunityCardComponent {
    showDescription = false;
    @Input() isAdmin = false;
    @Input() opportunity: OpportunityModel = new OpportunityModel();

    constructor(private router: Router) {}

    onTogleShowDescription() {
        this.showDescription = !this.showDescription;
    }

    onNavigateToOpportunity() {
        if (this.isAdmin) {
            this.router.navigate(['admin', 'opportunity', this.opportunity.baseOpportunity.id]);
        } else {
            this.router.navigate(['client', 'opportunity', this.opportunity.baseOpportunity.id]);
        }
    }

    isShowMoreVisible() {
        return this.isAdmin;
        //this.opportunity.baseOpportunity.active.endDate
    }

    isStateSent() {
        return this.getOppotunityRequest() && this.getOppotunityRequest()?.baseRequest.status === OpportunityRequestStatus.new && !this.isStateFullfilled();
    }

    isStateFullfilled() {
        return this.getOppotunityRequest() && this.getOppotunityRequest()?.baseRequest.fulfillType === OpportunityRequestFulfillType.fulfilled;
    }

    isStateApproved() {
        return this.getOppotunityRequest() && this.getOppotunityRequest()?.baseRequest.status === OpportunityRequestStatus.approved && !this.isStateFullfilled();
    }

    isStateRejected() {
        return this.getOppotunityRequest() && this.getOppotunityRequest()?.baseRequest.status === OpportunityRequestStatus.canceled;
    }

    getOppotunityRequest(): OpportunityRequestModel | null {
        return this.opportunity.getOpportunityRequest();
    }
}
