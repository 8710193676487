<div class="opportunities container container--admin-grey">
    <h4>Активні Події</h4>
    <app-error-message
        *ngIf="error"
        [errorMessage]="error"></app-error-message>

    <app-empty-table
        [white]="true"
        *ngIf="!getActiveOpportnuties().length && !isLoading"></app-empty-table>
    <div
        fxFlexFill
        fxLayout="row wrap"
        fxLayout.xs="column"
        fxLayoutGap="10px 5px grid"
        class="opportunities__list">
        <app-opportunity-card
            fxFlex="25"
            fxFlex.lt-lg="33"
            *ngFor="let item of getActiveOpportnuties()"
            [isAdmin]="false"
            [opportunity]="item">
        </app-opportunity-card>
    </div>

    <!-- <h4>Неактвивні події</h4>
   
    <app-empty-table [white]="true" *ngIf="!getInactiveOpportnuties().length && !isLoading"></app-empty-table>
    <div
        fxFlexFill
        fxLayout="row wrap"
        fxLayout.xs="column"
        fxLayoutGap="10px 5px grid"
        class="opportunities__list">
        <app-opportunity-card
            fxFlex="25"
            *ngFor="let item of getInactiveOpportnuties()"
            [isAdmin]="false"
            [opportunity]="item">
        </app-opportunity-card>
    </div> -->
</div>
