<div class="opportunities container container--admin-grey">
    <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="opportunities__header">
        <h4>Події</h4>
        <div>
            <button
                mat-flat-button
                color="primary"
                class="opportunities__button-header"
                (click)="onOpportunityAdd()">
                <mat-icon class="opportunities__button-icon">add</mat-icon>
                <span class="opportunities__button-text">Додати подію</span>
            </button>
        </div>
    </div>

    <app-error-message
        *ngIf="error"
        [errorMessage]="error"></app-error-message>
    <app-empty-table *ngIf="!dataSource.length && !isLoading"></app-empty-table>

    <div
        fxFlexFill
        fxLayout="row wrap"
        fxLayout.xs="column"
        fxLayoutGap="10px 5px grid"
        class="opportunities__list">
        <app-opportunity-card
            fxFlex="25"
            fxFlex.lt-lg="33"
            *ngFor="let item of dataSource"
            [isAdmin]="true"
            [opportunity]="item">
        </app-opportunity-card>
    </div>

    <h4>Неактвивні події</h4>

    <app-empty-table
        [white]="true"
        *ngIf="!getInactiveOpportnuties().length && !isLoading"></app-empty-table>
    <div
        fxFlexFill
        fxLayout="row wrap"
        fxLayout.xs="column"
        fxLayoutGap="10px 5px grid"
        class="opportunities__list">
        <app-opportunity-card
            fxFlex="25"
            fxFlex.lt-lg="33"
            *ngFor="let item of getInactiveOpportnuties()"
            [isAdmin]="true"
            [opportunity]="item">
        </app-opportunity-card>
    </div>
</div>
