import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeMainComponent } from './modules/home/components/home-main/home-main.component';
import { AuthMainComponent } from './modules/auth/components/auth-main/auth-main.component';
import { LogoComponent } from './modules/shared/components/logo/logo.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { QRCodeModule } from 'angularx-qrcode';
import { QuillModule } from 'ngx-quill';
import { IMaskModule } from 'angular-imask';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthFormComponent } from './modules/auth/components/auth-form/auth-form.component';
import { AuthLoginComponent } from './modules/auth/components/auth-login/auth-login.component';
import { AuthRegistrationComponent } from './modules/auth/components/auth-registration/auth-registration.component';
import { AuthRecoverComponent } from './modules/auth/components/auth-recover/auth-recover.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { appReducer } from './app.reducer';
import { AuthEffects } from './modules/auth/store/auth.effects';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptorService } from './modules/core/interceptors/auth.interceptor';
import { LoadingComponent } from './modules/shared/components/loading/loading.component';
import { ErrorMessageComponent } from './modules/shared/components/error-message/error-message.component';
import { InnerDialogComponent } from './modules/shared/components/inner-dialog/inner-dialog.component';
import { CommonDialogComponent } from './modules/shared/components/common-dialog/common-dialog.component';
import { ClientMainComponent } from './modules/client/components/client-main/client-main.component';
import { ClientSettingsComponent } from './modules/client/components/client-settings/client-settings.component';
import { ClientOpprotunitiesComponent } from './modules/client/components/client-opprotunities/client-opprotunities.component';
import { AuthActivateAccountComponent } from './modules/auth/components/auth-activate-account/auth-activate-account.component';
import { AuthResetPasswordComponent } from './modules/auth/components/auth-reset-password/auth-reset-password.component';
import { TopBannerComponent } from './modules/shared/components/top-banner/top-banner.component';
import { UserProfileComponent } from './modules/shared/components/user-profile/user-profile.component';
import { ClientEffects } from './modules/client/store/client.effects';
import { LogoutComponent } from './modules/shared/components/logout/logout.component';
import { AdminMainComponent } from './modules/admin/components/admin-main/admin-main.component';
import { AdminOpportunityComponent } from './modules/admin/components/admin-opportunity/admin-opportunity.component';
import { AdminOpportunitySettingsComponent } from './modules/admin/components/admin-opportunity-settings/admin-opportunity-settings.component';
import { AdminOpportunityRequestsComponent } from './modules/admin/components/admin-opportunity-requests/admin-opportunity-requests.component';
import { NavigationComponent } from './modules/shared/components/navigation/navigation.component';
import { ClientHomeComponent } from './modules/client/components/client-home/client-home.component';
import { AdminGenericTypesComponent } from './modules/admin/components/admin-generic-types/admin-generic-types.component';
import { AdminEffects } from './modules/admin/store/admin.effects';
import { GenericTypeItemComponent } from './modules/admin/components/generic-type-item/generic-type-item.component';
import { TranslateEnumPipe } from './modules/core/pipes/translate.pipe';
import { CommonPromptComponent } from './modules/shared/components/common-prompt/common-prompt.component';
import { EmptyTableComponent } from './modules/shared/components/empty-table/empty-table.component';
import { AdminOpportunitiesComponent } from './modules/admin/components/admin-opportunities/admin-opportunities.component';
import { OpportunityCardComponent } from './modules/shared/components/opportunity-card/opportunity-card.component';
import { ClientOpportunityComponent } from './modules/client/components/client-opportunity/client-opportunity.component';
import { BreadcrumpsComponent } from './modules/shared/components/breadcrumps/breadcrumps.component';
import { registerLocaleData } from '@angular/common';
import localeUk from '@angular/common/locales/uk';
import { NotificationMessageComponent } from './modules/shared/components/notification-message/notification-message.component';
import { AdminUsersComponent } from './modules/admin/components/admin-users/admin-users.component';
import { AdminUserComponent } from './modules/admin/components/admin-user/admin-user.component';
import { UserProfileInfoComponent } from './modules/shared/components/user-profile-info/user-profile-info.component';
import { AdminOpportunityRequestComponent } from './modules/admin/components/admin-opportunity-request/admin-opportunity-request.component';
import { OpportunityRequestSuggestionComponent } from './modules/admin/components/opportunity-request-suggestion/opportunity-request-suggestion.component';
import { ClientOpportunityRequestsComponent } from './modules/client/components/client-opportunity-requests/client-opportunity-requests.component';
import { AdminProjectsComponent } from './modules/admin/components/admin-projects/admin-projects.component';
import { AdminProjectComponent } from './modules/admin/components/admin-project/admin-project.component';
import { AdminAuthGuard } from './modules/core/guards/admin.guard';
import { UserAuthGuard } from './modules/core/guards/user.guard';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { TutorialHelper } from './modules/core/helpers/tutorial.helper';
import { HomeMaintenanceComponent } from './modules/home/components/home-maintenance/home-maintenance.component';
import { AdminOppotunityRequestOperationsComponent } from './modules/admin/components/admin-oppotunity-request-operations/admin-oppotunity-request-operations.component';
import { AdminDataExportComponent } from './modules/admin/components/admin-data-export/admin-data-export.component';

const localeUkValue = 'uk-UK';
registerLocaleData(localeUk);

@NgModule({
    declarations: [
        AppComponent,
        HomeMainComponent,
        AuthMainComponent,
        LogoComponent,
        AuthFormComponent,
        AuthLoginComponent,
        AuthRegistrationComponent,
        AuthRecoverComponent,
        LoadingComponent,
        ErrorMessageComponent,
        InnerDialogComponent,
        CommonDialogComponent,
        ClientMainComponent,
        ClientSettingsComponent,
        ClientOpprotunitiesComponent,
        AuthActivateAccountComponent,
        AuthResetPasswordComponent,
        TopBannerComponent,
        UserProfileComponent,
        LogoutComponent,
        AdminMainComponent,
        AdminOpportunityComponent,
        AdminOpportunitySettingsComponent,
        AdminOpportunityRequestsComponent,
        NavigationComponent,
        ClientHomeComponent,
        AdminGenericTypesComponent,
        GenericTypeItemComponent,
        TranslateEnumPipe,
        CommonPromptComponent,
        EmptyTableComponent,
        AdminOpportunitiesComponent,
        OpportunityCardComponent,
        ClientOpportunityComponent,
        BreadcrumpsComponent,
        NotificationMessageComponent,
        AdminUsersComponent,
        AdminUserComponent,
        UserProfileInfoComponent,
        AdminOpportunityRequestComponent,
        OpportunityRequestSuggestionComponent,
        ClientOpportunityRequestsComponent,
        AdminProjectsComponent,
        AdminProjectComponent,
        HomeMaintenanceComponent,
        AdminOppotunityRequestOperationsComponent,
        AdminDataExportComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatSidenavModule,
        MatListModule,
        MatToolbarModule,
        MatBadgeModule,
        MatStepperModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRadioModule,
        MatSelectModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatTableModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatSortModule,
        IMaskModule,
        LayoutModule,
        FlexLayoutModule,
        StoreModule.forRoot(appReducer),
        EffectsModule.forRoot([AuthEffects, ClientEffects, AdminEffects]),
        QuillModule.forRoot({
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    [{ align: [] }],
                    // remove formatting button
                    ['image'], // link and image, video
                ],
            },
        }),
        QRCodeModule,
        HttpClientModule,
        ReactiveFormsModule,
    ],
    providers: [
        TutorialHelper,
        { provide: LOCALE_ID, useValue: localeUkValue },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        AdminAuthGuard,
        UserAuthGuard,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
