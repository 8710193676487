import { HttpErrorResponse } from '@angular/common/http';
import { ExceptionArea, IInternalException } from '../models/exception.model';
import { extractUnprocessableError } from './extract-error.helper';
import { UserModel } from '../models/user.model';

export const extractErrorFromResponse = (response: any, isAdmin: boolean = false): IInternalException => {
    let errorMessage = '';

    const errorMessageText = response && response.error && response.error.message;
    const errorMessageResponse = response && response.error && response.error.response;

    switch (errorMessageText) {
        case 'Unprocessable Entity Exception': {
            errorMessage = 'Дані невірні.';
            if (errorMessageResponse && typeof errorMessageResponse === 'object') {
                errorMessage = extractUnprocessableError(errorMessageResponse);
            }
            break;
        }
        case 'User not found': {
            errorMessage = 'Дані невірні. Користувача не знайдено.';
            break;
        }
        case 'Invalid activation token': {
            errorMessage = 'Ваш аккаунт вже активований. Спробуйте перейти до сторінки Логіну ти здійснити вхід.';
            break;
        }
        case 'User already exist': {
            errorMessage = 'Користувач з таким Email вже існує';
            break;
        }
        case 'Wrong email or password': {
            errorMessage = 'Логін або пароль невірний.';
            break;
        }
        case 'Profiles has same password or temporary ids': {
            errorMessage = 'Увага! Номер Паспорта або номер ВПО вже разєстрований.';
            break;
        }
        case 'Account is not active': {
            errorMessage = 'Увага! Користувач не активний.';
            break;
        }
        case 'Unprocessable Entity Exception': {
            errorMessage = 'Дані користувача невірні.';
            break;
        }
        case 'User already active': {
            errorMessage = 'Акаунт користвувача вже активований. Перейдіть на сторінку авторізації для входу.';
            break;
        }
        case 'User not found': {
            errorMessage = 'Дані користувача невірні.';
            break;
        }
        case 'Wrong email or password': {
            errorMessage = 'Логін або пароль невірний.';
            break;
        }
        case 'Token is expired': {
            errorMessage = 'Посилання застаріле. Спробуйте надіслати ще раз.';
            break;
        }
        case 'Profiles has same password or temporary ids': {
            errorMessage = 'Увага! Номер Паспорта або номер ВПО вже разєстрований.';
            break;
        }
        case 'Wrong email or password': {
            errorMessage = 'Логін або пароль невірний.';
            break;
        }
        case 'Already added opprotunity request': {
            errorMessage = 'Ви вже подали заявку на подію. Можливо тілько одна заявка від користувача.';
            break;
        }
        case 'Profiles has same password or temporary ids': {
            errorMessage = 'Увага! Номер Паспорта або номер ВПО вже разєстрований.';
            break;
        }
        case 'Opprotunity is not active': {
            errorMessage = 'Подія неактивна. Дозволяється подавати заявки тільки на активні події.';
            break;
        }
    }

    if (!errorMessage && response instanceof HttpErrorResponse) {
        return { error: 'Помилка. Спробуйте пізніше!', initialError: `${response.message}: ${response.statusText}` };
    } else if (!errorMessage) {
        errorMessage = 'Помилка. Спробуйте пізніше!';
    }

    return { error: errorMessage, initialError: errorMessageText ? errorMessageText : response.toString() };
};

export const exportSentryErrorMessage = (error: IInternalException, user: UserModel | null, area: ExceptionArea) => {
    let userName = '';
    if (user && user.getProfile()) {
        userName = `${user?.getProfile().firstName} ${user?.getProfile().lastName} `;
    } else if (user) {
        userName = `${user.baseUser.email} `;
    }
    const errorMessage = `Area: ${area} - expection: ${error.initialError}, message: ${error.error}, user: ${userName}`;
    return errorMessage;
};
