import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    EMPTY_OPPORTUNITY_REQUEST_SUGGESTION,
    IOpportunityRequestSuggestion,
    OpportunityRequestModel,
    OpportunityRequestSuggestionStatus,
} from 'src/app/modules/core/models/opportunity-request.model';
import { IOpportunitySuggestment } from 'src/app/modules/core/models/opportunity.model';

export enum OpportunityRequestSuggestionIconType {
    'Success' = '/assets/images/icon-success.png',
    'Question' = '/assets/images/icon-quiestion.png',
}

export enum OpportunityRequestSuggestionButtonType {
    'Button' = '0',
    'Text' = '1',
}

export interface OpportunityRequestSuggestionData {
    icon: OpportunityRequestSuggestionIconType;
    opportunityRequest: OpportunityRequestModel;
    callback: (value: OpportunityRequestModel) => {};
}

@Component({
    selector: 'app-opportunity-request-suggestion',
    templateUrl: './opportunity-request-suggestion.component.html',
    styleUrls: ['./opportunity-request-suggestion.component.scss'],
})
export class OpportunityRequestSuggestionComponent {
    public defaultActionText = 'Закрити';
    suggestionForm: FormGroup;
    opportunityRequest: OpportunityRequestModel;

    constructor(@Inject(MAT_DIALOG_DATA) public data: OpportunityRequestSuggestionData) {
        this.opportunityRequest = data.opportunityRequest.clone();
        const suggestion = this.opportunityRequest.getSuggestion() || EMPTY_OPPORTUNITY_REQUEST_SUGGESTION;

        this.suggestionForm = new FormGroup({
            id: new FormControl(suggestion.id),
            status: new FormControl(suggestion.status),
            opportunityRequest: new FormControl(suggestion.opportunityRequest),
            suggested: new FormControl(suggestion.suggested, [Validators.required]),
            demand: new FormControl(suggestion.demand, [Validators.required]),
            priority: new FormControl(suggestion.priority, [Validators.required]),
            solution: new FormControl(suggestion.solution || 1, [Validators.required]),
        });
    }

    onSubmit() {
        if (this.suggestionForm.valid) {
            const { id, status, opportunityRequest, suggested, demand, priority, solution } = this.suggestionForm.value;
            const suggestion: IOpportunityRequestSuggestion = {
                status,
                suggested,
                demand,
                priority,
                solution,
            };
            this.opportunityRequest.setSuggestion(suggestion);
            const updateOpportunityRequest = this.opportunityRequest.clone();

            this.data.callback(updateOpportunityRequest);
        }
    }
}
