export abstract class EntityModel {
    static getEmptyModel() {
        throw new Error('Implement getEmptyModel');
    }

    clone(){
        throw new Error('Implement getEmptyModel');
    }
}

