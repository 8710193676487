import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ShepherdService } from 'angular-shepherd';
import { IApplicationState } from 'src/app/app.reducer';
import { isMobile } from 'src/app/modules/core/helpers/mobile.helper';
import { NavTabType } from 'src/app/modules/core/models/nav-tabs.model';
import * as ClientActions from '../../store/client.actions';

@Component({
    selector: 'app-client-home',
    templateUrl: './client-home.component.html',
    styleUrls: ['./client-home.component.scss'],
})
export class ClientHomeComponent implements OnInit {
    constructor(private router: Router, private store: Store<IApplicationState>) {}
    ngOnInit(): void {
        this.store.dispatch(ClientActions.checkTutorial());
    }

    navigateToSettings() {
        this.router.navigate(['client', 'settings']);
    }

    navigateToOppotunities() {
        this.router.navigate(['client', 'opportunities']);
    }

    launchTutorial() {
        this.store.dispatch(ClientActions.launchTutorial());
    }
}
