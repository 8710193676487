<div class="generic-types container container--admin">
    <div>
        <h4>Пекрелік типів</h4>
        <app-error-message
            *ngIf="error"
            [errorMessage]="error"></app-error-message>
        <app-empty-table *ngIf="!dataSource.length && !isLoading"></app-empty-table>
        <table
            *ngIf="dataSource.length"
            mat-table
            [dataSource]="dataSource"
            class="mat-elevation-z8">
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

            <!-- name Column -->
            <ng-container matColumnDef="name">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Назва
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    (click)="onAddType(element)">
                    {{ element.baseType.name }}
                </td>
            </ng-container>

            <!-- source Column -->
            <ng-container matColumnDef="source">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Категорія
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    (click)="onAddType(element)">
                    {{ element.baseType.source | translateEnum : 'GenericTypeSource' }}
                </td>
            </ng-container>

            <!-- description Column -->
            <ng-container matColumnDef="description">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Опис
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    (click)="onAddType(element)">
                    {{ element.baseType.description }}
                </td>
            </ng-container>

            <!-- action Column -->
            <ng-container matColumnDef="action">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    <button
                        mat-flat-button
                        color="primary"
                        class="generic-types__button-header"
                        (click)="onAddType()">
                        <mat-icon class="generic-types__button-icon">add</mat-icon>
                        <span class="generic-types__button-text"> Додати</span>
                    </button>
                </th>
                <td
                    mat-cell
                    *matCellDef="let element">
                    <button
                        mat-stroked-button
                        color="primary"
                        class="button-light-blue generic-types__button"
                        (click)="onDeleteHandler(element)">
                        <mat-icon class="generic-types__button-icon">delete</mat-icon>
                        <span class="generic-types__button-text"> видалити </span>
                    </button>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="getColumns()"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: getColumns()"></tr>
        </table>
    </div>
</div>
