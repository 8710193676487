<div class="users container container--admin">
    <div>
        <h4>Користувачі</h4>
        <div class="users-filter">
            <form
                [formGroup]="filterForm"
                (ngSubmit)="onSubmit()">
                <div
                    fxLayoutAlign="start"
                    fxLayoutAlign.xs="space-evenly"
                    fxLayout="row"
                    fxLayout.xs="column">
                    <div
                        fxLayoutAlign="start"
                        fxLayout="row"
                        class="users-filter-item users-filter-item--statuses">
                        <div class="users-filter-item-controls">
                            <h5>Статус</h5>
                            <mat-chip-listbox
                                formControlName="userStates"
                                aria-label="Статус"
                                multiple="true">
                                <mat-chip-option
                                    color="primary"
                                    *ngFor="let item of userStatuses"
                                    [value]="item.value">
                                    {{ item.key }}
                                </mat-chip-option>
                            </mat-chip-listbox>
                        </div>
                    </div>
                    <div
                        fxLayoutAlign="start"
                        fxLayout="row"
                        class="users-filter-item users-filter-item--statuses">
                        <div class="users-filter-item-controls">
                            <h5>Властивість</h5>

                            <div fxLayout="row">
                                <mat-form-field class="users-filter-item users-filter-item__search">
                                    <input
                                        matInput
                                        placeholder="E-mail, телефон чи прізвище"
                                        formControlName="searchPattern" />
                                    <mat-icon
                                        matSuffix
                                        class="users-filter-item__search-icon-text">
                                        search
                                    </mat-icon>
                                </mat-form-field>
                                <button
                                    mat-flat-button
                                    color="primary"
                                    class="users-filter-item__button users-filter-item__button--search">
                                    <span class="users-filter-item__search-text"> Пошук </span>
                                    <mat-icon
                                        matSuffix
                                        class="users-filter-item__search-icon">
                                        search
                                    </mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <app-error-message
            *ngIf="error"
            [errorMessage]="error"></app-error-message>
        <app-empty-table *ngIf="!dataSource.length && !isLoading"></app-empty-table>
        <table
            *ngIf="dataSource.length"
            mat-table
            [dataSource]="dataSource"
            matSort
            (matSortChange)="sortData($event)">
            <!--- Note that these columns can be defined in any order. class="mat-elevation-z8"
                  The actual rendered columns are set as a property on the row definition" -->

            <!-- name Column -->
            <ng-container matColumnDef="name">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header="profile.lastName">
                    <span *ngIf="!isMobile()">Користувач</span>
                    <div
                        *ngIf="isMobile()"
                        fxLayout="row"
                        fxLayoutAlign="space-between center">
                        <div>Користувачі</div>
                        <div>
                            <button
                                mat-flat-button
                                color="primary"
                                class="users__button-header"
                                (click)="onAddUser()">
                                <mat-icon class="users__button-icon">add</mat-icon>
                                <span class="users__button-text">Додати користувача</span>
                            </button>
                        </div>
                    </div>
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    (click)="onNavigateToUser(element)">
                    <span *ngIf="!isMobile()">{{ element.getProfile().firstName }}&nbsp;{{ element.getProfile().lastName }}</span>
                    <div *ngIf="isMobile()">
                        <div
                            fxLayoutAlign="start"
                            fxLayout="row">
                            <div class="users__mobile-title users__mobile-title--bold">Користувач</div>
                        </div>
                        <div
                            fxLayoutAlign="start"
                            fxLayout="row"
                            *ngIf="element.getProfile().firstName">
                            <div class="users__mobile-info">
                                <div class="users__mobile-value">{{ element.getProfile().firstName }}&nbsp;{{ element.getProfile().lastName }}</div>
                            </div>
                        </div>
                        <div
                            fxLayoutAlign="start"
                            fxLayout="row"
                            *ngIf="element.getProfile().email">
                            <div class="users__mobile-info">
                                <div class="users__mobile-title">Email</div>
                                <div class="users__mobile-value">{{ element.getProfile().email }}</div>
                            </div>
                        </div>
                        <div
                            fxLayoutAlign="start"
                            fxLayout="row"
                            *ngIf="element.getProfile().phone">
                            <div class="users__mobile-info">
                                <div class="users__mobile-title">Телефон</div>
                                <div class="users__mobile-value">{{ element.getProfile().phone }}</div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <!-- source Column -->
            <ng-container matColumnDef="email">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Email
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    (click)="onNavigateToUser(element)">
                    <span>{{ element.getProfile().email }}</span>
                </td>
            </ng-container>

            <!-- description Column -->
            <ng-container matColumnDef="phone">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    <div
                        fxLayout="row"
                        fxLayoutAlign="space-between center">
                        <div>Телефон</div>
                        <div>
                            <button
                                *ngIf="!isMobile()"
                                mat-flat-button
                                color="primary"
                                class="users__button-header"
                                (click)="onAddUser()">
                                <mat-icon class="users__button-icon">add</mat-icon>
                                <span class="users__button-text">Додати користувача</span>
                            </button>
                        </div>
                    </div>
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    (click)="onNavigateToUser(element)">
                    <span>{{ element.getProfile().phone }}</span>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="getColumns()"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: getColumns()"></tr>
        </table>
        <mat-paginator
            class="paginator"
            (page)="onPageChange($event)"
            [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            [pageIndex]="pageIndex"
            aria-label="Вибрати сторніку">
        </mat-paginator>
    </div>
</div>
