import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';
import * as AuthActions from '../../store/auth.actions';

@Component({
    selector: 'app-auth-activate-account',
    templateUrl: './auth-activate-account.component.html',
    styleUrls: ['./auth-activate-account.component.scss'],
})
export class AuthActivateAccountComponent implements OnInit, OnDestroy, AfterContentInit {
    isLoading = false;
    error: string | null = '';
    private storeSubscription: Subscription = new Subscription();
    private routeSubscription: Subscription = new Subscription();

    constructor(private currentRoute: ActivatedRoute, private store: Store<IApplicationState>) {}

    ngAfterContentInit(): void {}

    ngOnInit(): void {
        this.storeSubscription = this.store.select('auth').subscribe((authState) => {
            if (authState) {
                this.isLoading = authState.requestState === LoadingState.LOADING;
                this.error = getError(authState.requestState);
            }
        });

        this.routeSubscription = this.currentRoute.params.subscribe((currentParams) => {
            const token = currentParams['token'];
            this.store.dispatch(AuthActions.signUpActivateAccount({ token }));
        });
    }
    ngOnDestroy(): void {
        this.store.dispatch(AuthActions.clearError());
        this.storeSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }
}
