import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpParams, HttpHeaders, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { take, exhaustMap, map, catchError, filter, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { IApplicationState } from '../../../app.reducer';
import { Observable, throwError } from 'rxjs';
import * as AuthActions from '../../auth/store/auth.actions';
import { RefresTokenState } from '../models/request-state.model';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    private isRefreshing = false;

    constructor(private store: Store<IApplicationState>) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return this.store.select('auth').pipe(
            take(1),
            map((authState) => {
                return authState.user;
            }),
            exhaustMap((user) => {
                // return next.handle(req);
                // console.log('exhaustMap');
                // if (!user) {
                //     return next.handle(req);
                // } else if (req.url.indexOf('solver_wrappe') > -1) {
                //     return next.handle(req);
                // }
                // const modifiedReq = req.clone({
                //     params: new HttpParams().set('auth', user.getToken())
                // });
                // const modifiedReq = req.clone({
                //     headers: new HttpHeaders().set('add_header', `'Access-Control-Allow-Origin' 'http://localhost:4200' always;`),
                // });
                // return next.handle(modifiedReq);

                if (req.url.indexOf('googleapis') > -1 || req.url.indexOf('cloudfunctions') > -1) {
                    return next.handle(req);
                }

                req = req.clone({
                    withCredentials: true,
                    headers: req.headers.set('Content-Type', 'application/json'),
                });

                console.log('exhaustMap');
                if (!user) {
                    return next.handle(req);
                } else {
                    const token = (user && user.getToken()) || '';
                    const modifiedReq = this.addTokenHeader(req, token);

                    return next.handle(modifiedReq).pipe(
                        catchError((err, outer) => {
                            if (err instanceof HttpErrorResponse && !modifiedReq.url.includes('auth/register') && err.status === 401) {
                                //console.log('intercept 401', req.url.includes('refresh'));
                                if (modifiedReq.url.includes('refresh')) {
                                    console.log('logout');
                                    this.store.dispatch(AuthActions.signOut());
                                }
                                return this.handle401Error(modifiedReq, next);
                            }
                            return throwError(() => err);
                        })
                    );
                }
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('isRefreshing', this.isRefreshing);
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.store.dispatch(AuthActions.refreshToken());
        }

        return this.store.select('auth').pipe(
            tap(() => console.log('refreshTokenSubject')),
            filter((state) => state.tokenState === RefresTokenState.REFRESHED),
            take(1),
            switchMap((state) => {
                console.log('token refreshed', state);
                const { user } = state;
                const token = (user && user.getToken()) || '';
                this.isRefreshing = false;
                return next.handle(this.addTokenHeader(request, token));
            }),
            catchError((err) => {
                this.isRefreshing = false;
                // this.checkAuthService.logout();
                return throwError(() => err);
            })
        );
    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        const headerValue = `Bearer ${token}`;
        return request.clone({
            headers: new HttpHeaders().set(TOKEN_HEADER_KEY, headerValue).set('Content-Type', 'application/json'),
        });
    }
}
