import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import * as AdminActions from 'src/app/modules//admin/store/admin.actions';
import { NavTabType } from 'src/app/modules/core/models/nav-tabs.model';
import { OpportunityModel } from 'src/app/modules/core/models/opportunity.model';
import { LoadingState } from 'src/app/modules/core/models/request-state.model';

@Component({
    selector: 'app-admin-opportunity',
    templateUrl: './admin-opportunity.component.html',
    styleUrls: ['./admin-opportunity.component.scss'],
})
export class AdminOpportunityComponent implements OnInit, OnDestroy {
    isLoading = false;
    @ViewChild('opportunnityTabs') opportunnityTabs?: MatTabGroup
    private storeSubscription: Subscription = new Subscription();
    opportunity: OpportunityModel = new OpportunityModel();
    selectedTab: NavTabType = NavTabType.OpportunitySettings

    constructor(private store: Store<IApplicationState>, private currentRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.storeSubscription = this.store.select('admin').subscribe((adminState) => {
            this.isLoading = adminState.requestState === LoadingState.LOADING;

            if (adminState.opportunity) {
                this.opportunity = adminState.opportunity.clone();
            }
        });

        this.store.dispatch(AdminActions.genericTypesGet());

        this.currentRoute.queryParams.subscribe((currentParams) => {
            const currentType = currentParams['type']
            if (currentType) {
                this.selectedTab = currentType;
                this.processViewTypeChange()
            }
        })
    }

    processViewTypeChange() {
        setTimeout(() => {
            const selectedIndex = this.selectedTab === NavTabType.OpportunitySettings ? 0 : 1
            if (this.opportunnityTabs) {
                this.opportunnityTabs.selectedIndex = selectedIndex
            }
        }, 200)
    }

    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }
}
