<div class="requests">
    <div
        class="requests-title"
        fxLayoutAlign="start"
        fxLayout="row">
        <h4>Заявки на подію (загалом: {{ getTotalCount() }}, схвалено: {{ getApprovedCount() }})</h4>
    </div>
    <app-error-message
        *ngIf="error"
        [errorMessage]="error"></app-error-message>
    <div class="requests-filter">
        <form
            [formGroup]="filterForm"
            (ngSubmit)="onSubmit()">
            <div
                fxLayoutAlign="start"
                fxLayoutAlign.xs="space-evenly"
                fxLayout="row"
                fxLayout.xs="column">
                <div
                    fxLayoutAlign="start"
                    fxLayout="row"
                    class="requests-filter-item requests-filter-item--statuses">
                    <div class="requests-filter-item-controls">
                        <h5>Статус</h5>
                        <mat-chip-listbox
                            formControlName="id"
                            aria-label="Статус"
                            multiple="true"
                            formControlName="statuses">
                            <mat-chip-option
                                color="primary"
                                *ngFor="let item of opportunityRequestStatuses"
                                [value]="item.value">
                                {{ item.key }}
                            </mat-chip-option>
                        </mat-chip-listbox>
                    </div>
                </div>
                <div
                    fxLayoutAlign="start"
                    fxLayout="row"
                    class="requests-filter-item requests-filter-item--statuses">
                    <div class="requests-filter-item-controls">
                        <h5>Властивість</h5>
                        <mat-chip-listbox
                            formControlName="id"
                            aria-label="Властивість"
                            multiple="true"
                            formControlName="states">
                            <mat-chip-option
                                color="primary"
                                *ngFor="let item of opportunityRequestStates"
                                [value]="item.value">
                                {{ item.key }}
                            </mat-chip-option>
                        </mat-chip-listbox>
                    </div>
                </div>
                <div
                    fxLayoutAlign="start"
                    fxLayout="row"
                    class="requests-filter-item requests-filter-item--statuses">
                    <div class="requests-filter-item-controls">
                        <h5>&nbsp;</h5>
                        <button
                            mat-flat-button
                            color="primary"
                            class="requests-filter-item-controls__button"
                            (click)="onGetSuggestmentHelp()">
                            Рекомендація
                        </button>
                        <button
                            mat-flat-button
                            color="primary"
                            class="requests-filter-item-controls__button"
                            (click)="onExcelExport()">
                            Экспорт в Excel
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div
        class="requests-mass-update mat-elevation-z8"
        *ngIf="selection.selected.length">
        <h5>Масове оновлення статусу</h5>
        <div
            fxLayoutAlign="start"
            fxLayoutAlign.xs="space-evenly"
            fxLayout="row">
            <div class="requests-mass-update__controls">
                <mat-form-field
                    appearance="fill"
                    [floatLabel]="'always'"
                    class="requests-mass-update__input select-filter">
                    <mat-label>Статус</mat-label>
                    <mat-select [(value)]="massUpdateStatusId">
                        <mat-option
                            *ngFor="let status of opportunityRequestElementStatuses"
                            [value]="status.value">
                            {{ status.value | translateEnum : 'OpportunityRequestStatus' }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="requests-mass-update__controls">
                <button
                    mat-flat-button
                    color="primary"
                    class="requests-mass-updatet__button-header"
                    (click)="onMassUpdate('status-change')">
                    <mat-icon class="requests-mass-update__button-icon">done_all</mat-icon>
                    <span class="requests-mass-update__button-text"> Змінити статус</span>
                </button>
            </div>
        </div>

        <h5>Масове оновлення показників</h5>
        <div
            fxLayoutAlign="start"
            fxLayoutAlign.xs="space-evenly"
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign.xs="start"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start"            
            >
            <div class="requests-mass-update__controls">
                <button
                    mat-flat-button
                    color="primary"
                    class="requests-mass-update__button button-green"
                    (click)="onMassUpdate('confirm-presence')">
                    <mat-icon class="requests-mass-update__button-icon">person_pin</mat-icon>
                    <span>Підтвердити присутність </span>
                </button>
            </div>
            <div class="requests-mass-update__controls">
                <button
                    mat-flat-button
                    color="accent"
                    class="requests-mass-update__button"
                    (click)="onMassUpdate('cancel-presence')">
                    <mat-icon class="requests-mass-update__button-icon">person_pin</mat-icon>
                    <span>Скасувати присутність</span>
                </button>
            </div>
            <div class="requests-mass-update__controls">
                <button
                    mat-flat-button
                    color="primary"
                    class="requests-mass-update__button button-green"
                    (click)="onMassUpdate('confirm-fulfillment')">
                    <mat-icon class="requests-mass-update__button-icon">beenhere</mat-icon>
                    <span>Підтвердити видачу </span>
                </button>
            </div>
            <div class="requests-mass-update__controls">
                <button
                    mat-flat-button
                    color="accent"
                    class="requests-mass-update__button"
                    (click)="onMassUpdate('cancel-fulfillment')">
                    <mat-icon class="requests-mass-update__button-icon">beenhere</mat-icon>
                    <span>Скасувати видачу</span>
                </button>
            </div>
        </div>
    </div>
    <div class="requests-grid">
        <h4>Перелік заявок</h4>
        <app-empty-table *ngIf="!filteredOpportunityRequests.length && !isLoading"></app-empty-table>
        <div
            fxLayout="row"
            fxLayoutAlign="start">
            <table
                *ngIf="filteredOpportunityRequests.length"
                mat-table
                [dataSource]="tableDataSource"
                multiTemplateDataRows
                class="opportunity-requests">
                <ng-container matColumnDef="select">
                    <th
                        mat-header-cell
                        *matHeaderCellDef>
                        <mat-checkbox
                            (change)="$event ? toggleAllRows() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let row">
                        <mat-checkbox
                            (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="username">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        class="opportunity-requests__cell">
                        Власник заявки
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        (click)="expandedElement = expandedElement === element ? null : element"
                        class="opportunity-requests__cell">
                        <span *ngIf="!isMobile()">
                            {{ element.getProfile().firstName }}&nbsp;{{ element.getProfile().lastName }}<br />
                            <span class="opportunity-requests__city"> {{ element.getProfile().addresses[0].region }},{{ element.getProfile().addresses[0].city }} </span>
                        </span>
                        <div
                            *ngIf="isMobile()"
                            [ngClass]="{ 'opportunity-requests__mobile-container': opportunityRequestIsExpanded(element) }">
                            <div
                                fxLayoutAlign="start"
                                fxLayout="row">
                                <div class="opportunity-requests__mobile-info">
                                    <div class="opportunity-requests__mobile-title">Власник</div>
                                    <div class="opportunity-requests__mobile-value">
                                        {{ element.getProfile().firstName }}&nbsp;{{ element.getProfile().lastName }}
                                        <span class="opportunity-requests__city"> {{ element.getProfile().addresses[0].region }},{{ element.getProfile().addresses[0].city }} </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                fxLayoutAlign="start"
                                fxLayout="row"
                                class="opportunity-requests__mobile-container">
                                <div class="opportunity-requests__mobile-info">
                                    <div class="opportunity-requests__mobile-title">Присутність</div>
                                    <div class="opportunity-requests__mobile-value">
                                        {{ element.baseRequest.presenceDate ? 'Підтвердив' : '&#8212;' }}&nbsp;
                                        {{ element.baseRequest.attendanceDate ? 'Присутній' : '&#8212;' }}
                                    </div>
                                </div>
                                <div class="opportunity-requests__mobile-info">
                                    <div class="opportunity-requests__mobile-title">Допомога</div>
                                    <div class="opportunity-requests__mobile-value">
                                        {{ element.baseRequest.fulfillDate ? 'Отримав' : '&#8212;' }}
                                    </div>
                                </div>
                            </div>
                            <div
                                fxLayoutAlign="start"
                                fxLayout="row"
                                class="opportunity-requests__mobile-container">
                                <div class="opportunity-requests__mobile-info">
                                    <div
                                        class="opportunity-requests__mobile-title"
                                        (click)="onEditSuggestment(element)">
                                        Рекоменд.
                                        <mat-icon
                                            matSuffix
                                            class="opportunity-requests__suggestion-icon">
                                            edit
                                        </mat-icon>
                                    </div>
                                    <div class="opportunity-requests__mobile-value">
                                        {{
                                            element.getSuggestion()?.suggested
                                                ? 'Видати: ' + (element.getSuggestion()?.solution || '-') + ', Приорітет: ' + (roundSuggestion(element.getSuggestion()?.priority) || '-')
                                                : '&#8212;'
                                        }}
                                    </div>
                                </div>
                                <div class="opportunity-requests__mobile-info">
                                    <div class="opportunity-requests__mobile-title">Номер</div>
                                    <div class="opportunity-requests__mobile-value">
                                        {{ element.baseRequest.orderId ? element.baseRequest.orderId : '&#8212;' }}
                                    </div>
                                </div>
                            </div>
                            <div
                                fxLayoutAlign="start"
                                fxLayout="row">
                                <div class="opportunity-requests__mobile-info">
                                    <div class="opportunity-requests__mobile-title">Статус</div>
                                    <div class="opportunity-requests__mobile-value requests__select">
                                        <mat-select
                                            class="requests__select-element"
                                            [value]="element.baseRequest.status"
                                            (selectionChange)="onOpportunityRequestStatusChange($event, element)"
                                            [disabled]="isLoading">
                                            <mat-option
                                                *ngFor="let status of opportunityRequestElementStatuses"
                                                [value]="status.value">
                                                {{ status.value | translateEnum : 'OpportunityRequestStatus' }}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="attendance">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        class="align-center">
                        Присутність
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        class="align-center">
                        <div [ngClass]="getPresenceStatusClasses(element.presenceStatus)">
                            {{ element.baseRequest.presenceDate ? 'Підтвердив' : '&#8212;' }}&nbsp;
                            {{ element.baseRequest.attendanceDate ? 'Присутній' : '&#8212;' }}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="fulfillment">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        class="align-center">
                        Допомога
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        class="align-center">
                        <div [ngClass]="getLabelClasses(element.fulfillDate)">
                            {{ element.baseRequest.fulfillDate ? 'Отримав' : '&#8212;' }}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="suggestment">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        class="align-center">
                        Рекомендовано
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        class="align-center">
                        <div
                            [ngClass]="getSuggestmentLabelClasses(element.suggestment)"
                            (click)="onEditSuggestment(element)">
                            {{
                                element.getSuggestion()?.suggested
                                    ? 'Видати: ' + (element.getSuggestion()?.solution || '-') + ', Приорітет: ' + (roundSuggestion(element.getSuggestion()?.priority) || '-')
                                    : '&#8212;'
                            }}
                            <mat-icon
                                matSuffix
                                class="opportunity-requests__suggestion-icon">
                                edit
                            </mat-icon>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="order">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        class="align-center">
                        Номер
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        class="align-center">
                        {{ element.baseRequest.orderId ? element.baseRequest.orderId : '&#8212;' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        class="align-center">
                        Статус
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                        class="align-center">
                        <div class="requests__select">
                            <mat-select
                                class="requests__select-element"
                                [value]="element.baseRequest.status"
                                (selectionChange)="onOpportunityRequestStatusChange($event, element)"
                                [disabled]="isLoading">
                                <mat-option
                                    *ngFor="let status of opportunityRequestElementStatuses"
                                    [value]="status.value">
                                    {{ status.value | translateEnum : 'OpportunityRequestStatus' }}
                                </mat-option>
                            </mat-select>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expand">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        aria-label="row actions">
                        &nbsp;
                        <div
                            *ngIf="isMobile()"
                            class="opportunity-requests__check--all">
                            <mat-checkbox
                                (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                                Всіх
                            </mat-checkbox>
                        </div>
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element">
                        <div
                            fxLayoutAlign="start"
                            fxLayoutAlign.xs="space-evenly"
                            fxLayout="row"
                            fxLayout.xs="column">
                            <div class="opportunity-requests__check--single" *ngIf="isMobile()">
                                <mat-checkbox
                                    (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(element) : null"
                                    [checked]="selection.isSelected(element)">
                                </mat-checkbox>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    mat-icon-button
                                    (click)="deleteToOpportunityRequest(element)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    mat-icon-button
                                    (click)="navigateToOpportunityRequestPage(element)">
                                    <mat-icon>visibility</mat-icon>
                                </button>
                            </div>
                            <div>
                                <button
                                    mat-icon-button
                                    aria-label="expand row"
                                    (click)="toggleExpandButton($event, element)">
                                    <mat-icon *ngIf="!opportunityRequestIsExpanded(element)">keyboard_arrow_down</mat-icon>
                                    <mat-icon *ngIf="opportunityRequestIsExpanded(element)">keyboard_arrow_up</mat-icon>
                                </button>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td
                        mat-cell
                        *matCellDef="let element"
                        [attr.colspan]="opportunityRequestsColumns.length + 1"
                        class="opportunity-requests__cell">
                        <div
                            class="opportunity-requests__detail"
                            [@detailExpand]="opportunityRequestIsExpanded(element) ? 'expanded' : 'collapsed'">
                            <div *ngIf="opportunityRequestIsExpanded(element)">
                                <div *ngIf="getProfile(element); else elseBlock">
                                    <!-- <app-user-personal-data [userPersonalData]="getUserPersonalData(element)"> </app-user-personal-data> -->
                                    <app-user-profile-info [profile]="getProfile(element)"></app-user-profile-info>
                                    <app-admin-oppotunity-request-operations [opportunityRequest]="element"></app-admin-oppotunity-request-operations>
                                </div>
                                <ng-template #elseBlock>
                                    <div
                                        fxLayout="row"
                                        fxLayoutAlign="center"
                                        class="personal-data-loading">
                                        <mat-spinner></mat-spinner>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr
                    mat-header-row
                    *matHeaderRowDef="opportunityRequestsColumnsWithExpand"></tr>
                <tr
                    mat-row
                    *matRowDef="let element; columns: opportunityRequestsColumnsWithExpand"
                    class="requests__element-row"
                    [class.opportunity-requests__expanded-row]="expandedElement === element"
                    [ngClass]="{ 'opportunity-requests__expanded-row': opportunityRequestIsExpanded(element) }"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: ['expandedDetail']"
                    class="requests__detail-row"
                    [ngClass]="{ 'opportunity-requests__expanded-row': opportunityRequestIsExpanded(row) }"></tr>
            </table>
        </div>
        <mat-paginator
            class="paginator"
            (page)="onPageChange($event)"
            [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            [pageIndex]="pageIndex"
            aria-label="Вибрати сторніку">
        </mat-paginator>
    </div>
</div>
