import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { LoadingState } from 'src/app/modules/core/models/request-state.model';
import * as AuthActions from '../../store/auth.actions';

@Component({
    selector: 'app-auth-main',
    templateUrl: './auth-main.component.html',
    styleUrls: ['./auth-main.component.scss'],
})
export class AuthMainComponent implements OnInit, OnDestroy {
    storeSubscription = new Subscription();
    routeSubscription = new Subscription();
    isLoading = false;

    constructor(private currentRoute: ActivatedRoute, private store: Store<IApplicationState>) {}
    ngOnInit(): void {
        this.routeSubscription = this.currentRoute.queryParams.subscribe((currentParams) => {
            this.store.dispatch(AuthActions.clearError());
        });

        setTimeout(() => {
            this.storeSubscription = this.store.select('auth').subscribe((state) => {
                this.isLoading = state.requestState === LoadingState.LOADING;
            });
        }, 100);
    }

    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }
}
