<div class="container container--main">
    <div class="home-logo">
        <app-logo></app-logo>
    </div>

    <div class="home-content">
        <h2>
            Сайт оновлюється<br />
            <span class="text-highlight">Будь ласка, завітайте пізніше</span>
        </h2>

        
</div>
