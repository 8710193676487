<div
    fxLayout="row"
    class="opportunity-request-actions"
    *ngIf="opportunityRequest.baseRequest.id">
    <div
        *ngIf="!opportunityRequest.baseRequest.fulfillDate">
        <button
            mat-flat-button
            color="primary"
            class="opportunity-request-actions__button button-green button-small button-high"
            (click)="onConfirmFulfillment()">
            Підтвердити видачу
        </button>
    </div>
    <div
        *ngIf="opportunityRequest.baseRequest.fulfillDate">
        <button
            mat-flat-button
            color="accent"
            class="opportunity-request-actions__button button-small button-high"
            (click)="onCancelFulfillment()">
            Скасувати видачу
        </button>
    </div>
    <div
        *ngIf="!opportunityRequest.baseRequest.attendanceDate">
        <button
            mat-flat-button
            color="primary"
            class="opportunity-request-actions__button button-green button-small button-high"
            (click)="onConfirmPresence()">
            Підтвердити присутність
        </button>
    </div>
    <div
        *ngIf="opportunityRequest.baseRequest.attendanceDate">
        <button
            mat-flat-button
            color="accent"
            class="opportunity-request-actions__button button-small button-high"
            (click)="onCancelPresence()">
            Скасувати присутність
        </button>
    </div>
</div>
