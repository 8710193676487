import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { exhaustMap, forkJoin, tap, of, EMPTY, first, switchMap, Observable } from 'rxjs';
import { catchError, map, take, withLatestFrom } from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { IAuthProfleResponseData, IAuthRequestData, IAuthResponseData } from '../models/auth.model';
import { switchMapCatchErrorOperator } from '../../core/operators/switchmap-catch.operator';
import { MatDialog } from '@angular/material/dialog';
import { CommonDialogButtonType, CommonDialogComponent, CommonDialogIconType } from '../../shared/components/common-dialog/common-dialog.component';
import { fromAuthResponseToUserModel, fromProfileToProfileRequest } from './auth.utils';
import { IApplicationState } from 'src/app/app.reducer';
import { IUserBase, IUserProfile } from '../../core/models/user.model';
import { exportSentryErrorMessage, extractErrorFromResponse } from '../../core/helpers/exception.helper';
import * as Sentry from '@sentry/angular-ivy';
import { ExceptionArea } from '../../core/models/exception.model';
import { CommonPromptComponent, CommonPromptIconType } from '../../shared/components/common-prompt/common-prompt.component';

const LOCAL_STORAGE_AUTH_KEY = 'userData';

const handleSwitchMapError = (errorRes: any) => {
    const error = extractErrorFromResponse(errorRes, false);
    if (error.initialError === 'Account is not active') {
        return AuthActions.signUpVerificationLetterRequest();
    }
    return AuthActions.authenticateFailed({ error: error.error, initialError: error.initialError });
};

@Injectable()
export class AuthEffects {
    constructor(
        private actions: Actions,
        private http: HttpClient,
        private router: Router, // private authService: AuthService, // private store: Store<IApplicationState>,
        public dialog: MatDialog,
        private store: Store<IApplicationState>
    ) {}

    authSignup = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.signUp),
                switchMap(({ email, password }) => {
                    console.log('authSignup');
                    const requestData: IAuthRequestData = {
                        email,
                        password,
                    };

                    const registrationRequest = this.http.post<IAuthResponseData>(`${environment.apiUrl}/api/v1/auth/registration`, requestData);

                    return forkJoin([registrationRequest]);
                }),
                map(([registrationResponse]) => {
                    console.log(registrationResponse);
                    return AuthActions.signUpVerificationLetterSent({
                        email: registrationResponse.user.id,
                    });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    authSigin = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.signIn),
                switchMap(({ email, password }) => {
                    console.log('authSignin');
                    const requestData: IAuthRequestData = {
                        email,
                        password,
                    };

                    const loginRequest = this.http.post<IAuthResponseData>(`${environment.apiUrl}/api/v1/auth/login`, requestData);
                    return forkJoin([loginRequest]);
                }),
                map(([loginResponse]) => {
                    console.log(loginResponse);
                    const user = fromAuthResponseToUserModel(loginResponse);

                    if (user.isActive()) {
                        localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify(loginResponse));
                        return AuthActions.signInRedirect({
                            user,
                        });
                    } else {
                        const email = user.getProfile().email ?? '';
                        return AuthActions.signUpVerificationLetterSent({ email: email });
                    }
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    profileUpdate = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.profileUpdate),
                switchMap(({ user }) => {
                    const profile = user.getProfile();
                    const profleRequest = fromProfileToProfileRequest(profile);
                    console.log('profileUpdate');
                    const profileUpdateRequestData = {
                        ...profleRequest,
                    };

                    const profileUpdateRequest = this.http.patch<IAuthProfleResponseData>(
                        `${environment.apiUrl}/api/v1/profile/${profile.id}?join=addresses,additionalProfiles`,
                        profileUpdateRequestData
                    );
                    return forkJoin([of(user), profileUpdateRequest]);
                }),
                map(([user, profileUpdateResponse]) => {
                    console.log(profileUpdateResponse);
                    const profile = profileUpdateResponse as IUserProfile;

                    const storedUserString = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
                    if (storedUserString) {
                        const storedUser = JSON.parse(storedUserString);
                        storedUser.profile = profile;
                        localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify(storedUser));
                    }

                    return AuthActions.profileUpdateSuccess({ profile });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    profileGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.profileGet),
                withLatestFrom(this.store.select('auth')),
                switchMap(([, { user }]) => {
                    console.log('profileGet');
                    const profileGetRequest = this.http.get<IAuthProfleResponseData>(`${environment.apiUrl}/api/v1/profile/${user?.baseUser.id}?join=addresses,additionalProfiles`);
                    return forkJoin([profileGetRequest]);
                }),
                map(([profileGetResponse]) => {
                    console.log(profileGetResponse);
                    const profile = profileGetResponse as IUserProfile;
                    const storedUserString = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
                    if (storedUserString) {
                        const storedUser = JSON.parse(storedUserString);
                        storedUser.profile = profile;
                        localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify(storedUser));
                    }

                    return AuthActions.profileGetSuccess({ profile });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    profileUpdateSuccess = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.profileUpdateSuccess),
                map(() => {
                    this.dialog.open(CommonDialogComponent, {
                        data: {
                            header: 'Редагування профілю',
                            content: 'Ви успішно оновили свій профіль. Тепер можна почати користуватись порталом.',
                            icon: CommonDialogIconType.Success,
                            buttonType: CommonDialogButtonType.Text,
                            navigate: '/client',
                            actionText: 'Перейти на головну',
                        },
                    });
                })
            ),
        { dispatch: false }
    );

    recoverPassword = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.recoverPassword),
                switchMap(({ email }) => {
                    console.log('recoverPassword');
                    const requestData: IAuthRequestData = {
                        email,
                    };

                    const recoverPasswordRequest = this.http.post<IAuthResponseData>(`${environment.apiUrl}/api/v1/account/send-reset-password-token`, requestData);
                    return forkJoin([recoverPasswordRequest]);
                }),
                map(([recoverPasswordResponse]) => {
                    console.log(recoverPasswordResponse);

                    return AuthActions.recoverPasswordSuccess();
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    recoverPasswordReset = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.recoverPasswordReset),
                switchMap(({ token, password, repassword }) => {
                    console.log('recoverPasswordReset');
                    const requestData: IAuthRequestData = {
                        password: password,
                        confirmPassword: repassword,
                    };

                    const recoverPasswordResetRequest = this.http.post<IAuthResponseData>(`${environment.apiUrl}/api/v1/account/reset-password/${token}`, requestData);
                    return forkJoin([recoverPasswordResetRequest]);
                }),
                map(([recoverPasswordResetResponse]) => {
                    console.log(recoverPasswordResetResponse);

                    return AuthActions.recoverPasswordResetSuccess();
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    signUpActivateAccount = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.signUpActivateAccount),
                switchMap(({ token }) => {
                    console.log('signUpActivateAccount');

                    const signUpActivateAccountRequest = this.http.get<IAuthResponseData>(`${environment.apiUrl}/api/v1/account/activate/${token}`);
                    return forkJoin([signUpActivateAccountRequest]);
                }),
                map(([signUpActivateAccountResponse]) => {
                    console.log(signUpActivateAccountResponse);

                    return AuthActions.signUpActivateAccountSuccess();
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    signinRedirect = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.signInRedirect),
                tap((action) => {
                    if (!action.user.isProfileFulfilled()) {
                        this.router.navigate(['client', 'settings']);
                    } else if (this.router.url.indexOf('/auth') > -1) {
                        this.router.navigate(['client']);
                    }
                })
            ),
        { dispatch: false }
    );

    signInAutoFailedRedirect = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.signInAutoFailedRedirect),
                tap(({ skipRedirect }) => {
                    if (!skipRedirect) {
                        this.router.navigate(['/']);
                    }
                })
            ),
        { dispatch: false }
    );

    signOut = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.signOut),
                tap(() => {
                    localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
                    this.router.navigate(['/']);
                })
            ),
        { dispatch: false }
    );

    authSendVerificationLetter = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.signUpVerificationLetterSent),
                map(() => {
                    this.dialog.open(CommonDialogComponent, {
                        data: {
                            header: 'Вам надіслано листа з верифікацією',
                            content: 'Ми надіслали Вам листа з верифікацією. Перейдіть, будь ласка, за посиланням в тексті, щоб підтвердити Ваш email, після цього зайдіть в систему.',
                            icon: CommonDialogIconType.Success,
                            buttonType: CommonDialogButtonType.Text,
                            navigate: '/',
                            actionText: 'На головну',
                        },
                    });
                })
            ),
        { dispatch: false }
    );

    signUpVerificationLetterRequest = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.signUpVerificationLetterRequest),
                map(() => {
                    this.dialog.open(CommonPromptComponent, {
                        data: {
                            header: 'Ваш акаунт ще не активовано',
                            content: 'Для активація подрібно перейти за посиланням з листа. Натисніть кнопку, ящко потрібно переслати листа з посиланням на активацію .',
                            actionText: 'Надіслати',
                            icon: CommonPromptIconType.Question,
                            callback: () => {
                                this.store.dispatch(AuthActions.signupResendVerificationLetter());
                            }   
                        },
                    });
                })
            ),
        { dispatch: false }
    );

    signUpActivateAccountSuccess = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.signUpActivateAccountSuccess),
                map(() => {
                    this.dialog.open(CommonDialogComponent, {
                        data: {
                            header: 'Активація акаунту',
                            content: 'Ви успішно активували свій акаунт. Тепер ви можете увійти в систему.',
                            icon: CommonDialogIconType.Success,
                            buttonType: CommonDialogButtonType.Text,
                            navigate: '/',
                            actionText: 'На головну',
                        },
                    });
                })
            ),
        { dispatch: false }
    );

    recoverPasswordSuccess = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.recoverPasswordSuccess),
                map(() => {
                    this.dialog.open(CommonDialogComponent, {
                        data: {
                            header: 'Відновлення паролю',
                            content:
                                'На Вашу електронну адресу надіслано повідомлення з інструкцією для відновлення паролю. Перейдіть за вказаним у повідомленні посиланням, після чого поверніться до сторінки входу до облікового запису.',
                            icon: CommonDialogIconType.Success,
                            buttonType: CommonDialogButtonType.Text,
                            navigate: '/',
                            actionText: 'На головну',
                        },
                    });
                })
            ),
        { dispatch: false }
    );

    recoverPasswordResetSuccess = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.recoverPasswordResetSuccess),
                map(() => {
                    this.dialog.open(CommonDialogComponent, {
                        data: {
                            header: 'Пароль успішно змінено',
                            content: 'Ваш пароль було успішно змінено.',
                            icon: CommonDialogIconType.Success,
                            buttonType: CommonDialogButtonType.Text,
                            navigate: '/',
                            actionText: 'На головну',
                        },
                    });
                })
            ),
        { dispatch: false }
    );

    signInAuto = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.signInAuto),
                map(() => {
                    const savedAuthResponse = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
                    if (savedAuthResponse) {
                        const authResponse = JSON.parse(savedAuthResponse) as IAuthResponseData;
                        const user = fromAuthResponseToUserModel(authResponse);

                        if (user.isActive()) {
                            return AuthActions.signInRedirect({
                                user,
                            });
                        } else {
                            return AuthActions.signInAutoFailedRedirect({ skipRedirect: false });
                        }
                    }

                    const isAuthLocation = window.location.href.indexOf('/auth') > -1;
                    return AuthActions.signInAutoFailedRedirect({ skipRedirect: isAuthLocation });
                })
            )
        // { dispatch: false }
    );

    refreshToken = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.refreshToken),
                withLatestFrom(this.store.select('auth')),
                switchMap(([action, { user }]) => {
                    console.log('refreshToken', user);

                    const token = (user && user.getRefreshToken()) || '';
                    const refreshTokenRequest = this.http.get<IAuthResponseData>(`${environment.apiUrl}/api/v1/auth/refresh/${token}`);

                    return forkJoin([refreshTokenRequest]);
                }),
                map(([refreshTokenResponse]) => {
                    console.log(refreshTokenResponse);
                    const user = fromAuthResponseToUserModel(refreshTokenResponse);

                    if (user && user.isActive()) {
                        localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify(refreshTokenResponse));
                        return AuthActions.refreshTokenSuccess({ user });
                    } else {
                        throw 'No User';
                    }
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    signupResendVerificationLetter = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.signupResendVerificationLetter),
                withLatestFrom(this.store.select('auth')),
                switchMap(([, { verificationEmail }]) => {
                    console.log('signupResendVerificationLetter');
                    const signupResendVerificationLetterRequest = this.http.post<IAuthResponseData>(`${environment.apiUrl}/api/v1/account/resend-email-confirm`, { email: verificationEmail });
                    return forkJoin([signupResendVerificationLetterRequest]);
                }),
                map(([refreshTokenResponse]) => {
                    console.log(refreshTokenResponse);
                    const user = fromAuthResponseToUserModel(refreshTokenResponse);

                    this.dialog.open(CommonDialogComponent, {
                        data: {
                            header: 'Ваш акаунт не активний',
                            content: 'Вам надіслано листа з посиланням на активацию.',
                            icon: CommonDialogIconType.Success,
                            buttonType: CommonDialogButtonType.Text,
                            navigate: '/',
                            actionText: 'На головну',
                        },
                    });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            ),
        { dispatch: false }
    );

    authenticateFailed = createEffect(
        () =>
            this.actions.pipe(
                ofType(AuthActions.authenticateFailed),
                withLatestFrom(this.store.select('auth')),
                tap(([action, { user }]) => {
                    const errorMessage = exportSentryErrorMessage(action, user, ExceptionArea.auth);
                    Sentry.captureException(JSON.stringify(errorMessage));
                })
            ),
        { dispatch: false }
    );
}
