<app-navigation>
    <div class="container container--client">
        <app-loading *ngIf="isLoading"></app-loading>
        <app-top-banner
            [title]="currentData.baseRoute.title"
            [subtitle]="currentData.baseRoute.description"></app-top-banner>
        <router-outlet></router-outlet>
        <!-- Add Content Here -->
    </div>
</app-navigation>
