import { retry } from 'rxjs';
import { EntityModel } from './entity.model';
import { EMPTY_BASE_ADDRESS, IAddress } from './address.model';
import { CloneHelper } from '../helpers/clone.helper';

export enum Gender {
    male = 'male',
    female = 'female',
}

export enum UserStatus {
    new = 'new',
    pending = 'pending',
    active = 'active',
    inactive = 'inactive',
    blocked = 'blocked',
    deleted = 'deleted',
    recovered = 'recovered',
    approved = 'approved',
    canceled = 'canceled',
}

export enum UserRole {
    admin = 'admin',
    user = 'user',
    member = 'member',
}

export enum ProfileStatus {
    new = 'new',
    active = 'active',
}

export interface IUserProfile {
    hasInjury: boolean;
    id?: string;
    status: ProfileStatus;
    createdAt?: Date | null;
    updatedAt?: Date | null;
    parent?: string | null;
    gender: Gender;
    firstName: string | null;
    lastName: string | null;
    phone?: string | null;
    email?: string;
    birthday: Date | null;
    passport: string;
    temporaryId: string;
    additionalProfiles?: IUserProfile[];
    addresses: IAddress[];
    type?: string | null;
}

export interface IUserSource {
    id: string;
    status: UserStatus;
    createdAt: Date;
    updatedAt: Date;
    type: string;
    value: {
        email: string;
    };
}

export interface IUserBase {
    email: string;
    id: string;
    refreshToken: string;
    accessToken: string;
    createdAt: Date;
    updatedAt: Date;
    sources: IUserSource[];
    roles: UserRole[];
    status: UserStatus;
}

export const EMPTY_BASE_USER = {
    email: '',
    id: '',
    refreshToken: '',
    accessToken: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    sources: [],
    roles: [],
    status: UserStatus.new,
};

export const EMPTY_PROFILE: IUserProfile = {
    hasInjury: false,
    id: '',
    status: ProfileStatus.new,
    createdAt: new Date(),
    updatedAt: new Date(),
    parent: null,
    gender: Gender.male,
    firstName: null,
    lastName: null,
    phone: null,
    email: '',
    birthday: null,
    passport: '',
    temporaryId: '',
    additionalProfiles: [],
    addresses: [EMPTY_BASE_ADDRESS],
    type: null,
};

export class UserModel extends EntityModel {
    private profile: IUserProfile = EMPTY_PROFILE;

    constructor(public baseUser: IUserBase = EMPTY_BASE_USER) {
        super();
    }

    getEmail() {
        if (this.profile && this.profile.email) {
            return this.profile.email;
        } else {
            return '';
        }
    }

    getToken() {
        return this.baseUser.accessToken;
    }

    getRefreshToken() {
        return this.baseUser.refreshToken;
    }

    getProfile() {
        return this.profile;
    }

    setProfile(profile: IUserProfile) {
        this.profile = profile;
    }

    getSources() {
        return this.baseUser.sources;
    }

    setSources(sources: IUserSource[]) {
        this.baseUser.sources = sources;
    }

    getRoles() {
        return this.baseUser.roles;
    }

    setRoles(roles: UserRole[]) {
        this.baseUser.roles = roles;
    }

    isAdmin() {
        return this.baseUser.roles.some((r) => r === UserRole.admin);
    }

    isMember() {
        return this.baseUser.roles.some((r) => r === UserRole.member);
    }

    isActive() {
        return this.baseUser.status !== UserStatus.new;
    }

    isProfileFulfilled() {
        return this.profile.status !== ProfileStatus.new;
    }

    override clone(): UserModel {
        const baseUserClone = CloneHelper.cloneData(this.baseUser);
        const clonedModel = new UserModel(baseUserClone);
        if (this.profile) {
            clonedModel.setProfile(this.profile);
        }
        return clonedModel;
    }
}
