<div class="settings">
    <div class="error-wrapper">
        <app-error-message
            *ngIf="error"
            [errorMessage]="error"></app-error-message>
    </div>
    <form
        [formGroup]="opportunityForm"
        (ngSubmit)="onSubmit()">
        <div fxLayout="row">
            <div class="settings-controls settings-controls--ultra-long">
                <mat-form-field
                    class="settings-controls__input"
                    [floatLabel]="'always'"
                    appearance="fill">
                    <mat-label>Назва</mat-label>
                    <input
                        matInput
                        placeholder="Назва"
                        formControlName="name"
                        class="settings-controls__input" />
                    <mat-error *ngIf="opportunityForm.controls['name'].hasError('required')"> Назва <strong>Обов'язкова</strong> </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div
            fxLayoutAlign="start"
            fxLayoutAlign.xs="space-evenly"
            fxLayout="row"
            fxLayout.xs="column">
            <div class="settings-controls">
                <mat-form-field
                    appearance="fill"
                    [floatLabel]="'always'"
                    class="settings-controls__input">
                    <mat-label>Статус події</mat-label>
                    <mat-select
                        formControlName="status"
                        (valueChange)="onStatusChange($event)">
                        <mat-option
                            *ngFor="let status of opportunityStatuses"
                            [value]="status.value">
                            {{ status.value | translateEnum : 'OpportunityStatus' }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="settings-controls">
                <input
                    type="hidden"
                    formControlName="addressId" />
                <mat-form-field
                    class="settings-controls__input"
                    [floatLabel]="'always'"
                    appearance="fill">
                    <mat-label>Область</mat-label>
                    <input
                        matInput
                        placeholder="Область"
                        formControlName="region"
                        class="settings-controls__input" />
                    <mat-error *ngIf="opportunityForm.controls['region'].hasError('required')"> Область <strong>Обов'язкова</strong> </mat-error>
                </mat-form-field>
            </div>
            <div class="settings-controls">
                <mat-form-field
                    class="settings-controls__input"
                    [floatLabel]="'always'"
                    appearance="fill">
                    <mat-label>Населений пункт</mat-label>
                    <input
                        matInput
                        placeholder="Населений пункт"
                        formControlName="city"
                        class="settings-controls__input" />
                    <mat-error *ngIf="opportunityForm.controls['region'].hasError('required')"> Населений пункт <strong>Обов'язковий</strong> </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row">
            <div class="settings-controls settings-controls--ultra-long">
                <mat-form-field
                    class="settings-controls__input"
                    appearance="fill"
                    [floatLabel]="'always'">
                    <mat-label>Тип допомоги</mat-label>
                    <mat-chip-grid
                        #opportunitySettingsStatusesList
                        aria-label="Тип допомоги">
                        <mat-chip-row
                            *ngFor="let oppType of selectedOpportunityTypes"
                            (removed)="removeOpportunityType(oppType)"
                            [editable]="false">
                            {{ oppType.baseType.name }}
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                        <input
                            placeholder="Додати тип допомоги..."
                            [formControl]="opportunityTypeCtrl"
                            [matChipInputFor]="opportunitySettingsStatusesList"
                            [matAutocomplete]="auto"
                            [matChipInputSeparatorKeyCodes]="getSeparatorKeysCodes()" />
                        <mat-autocomplete
                            #auto="matAutocomplete"
                            (optionSelected)="selectOpportunityHandler($event)">
                            <mat-option
                                *ngFor="let oppType of filteredOppotunityTypes | async"
                                [value]="oppType">
                                {{ oppType.baseType.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-chip-grid>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row">
            <div class="settings-controls settings-controls--ultra-long">
                <mat-form-field
                    class="settings-controls__input"
                    appearance="fill"
                    [floatLabel]="'always'">
                    <mat-label>Категорія людей, для кого допомога</mat-label>
                    <mat-chip-grid
                        #opportunitySettingsAvailableCategoriesList
                        aria-label="Категорія допомоги">
                        <mat-chip-row
                            *ngFor="let oppType of selectedOpportunityAvailableCategories"
                            (removed)="removeOpportunityAvailableCategory(oppType)"
                            [editable]="false">
                            {{ oppType.baseType.name }}
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                        <input
                            placeholder="Додати категорію людей..."
                            [formControl]="opportunityAvaiablecategoryCtrl"
                            [matChipInputFor]="opportunitySettingsAvailableCategoriesList"
                            [matAutocomplete]="autoAvailableCategories"
                            [matChipInputSeparatorKeyCodes]="getSeparatorKeysCodes()" />
                        <mat-autocomplete
                            #autoAvailableCategories="matAutocomplete"
                            (optionSelected)="selectOpportunityAvaiableCategoryHandler($event)">
                            <mat-option
                                *ngFor="let oppType of filteredOppotunityAvailableCategories | async"
                                [value]="oppType">
                                {{ oppType.baseType.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-chip-grid>
                </mat-form-field>
            </div>
        </div>
        <div
            fxLayoutAlign="start"
            fxLayoutAlign.xs="space-evenly"
            fxLayout="row"
            fxLayout.xs="column">
            <div class="settings-controls settings-controls">
                <mat-form-field
                    appearance="fill"
                    [floatLabel]="'always'"
                    class="settings-controls__input">
                    <mat-label>Період, коли подія доступна користувачам</mat-label>
                    <mat-date-range-input
                        formGroupName="dateVisible"
                        [rangePicker]="picker">
                        <input
                            matStartDate
                            formControlName="start"
                            placeholder="Початок" />
                        <input
                            matEndDate
                            formControlName="end"
                            placeholder="Закінчення" />
                    </mat-date-range-input>
                    <mat-hint>формат - 12/31/2022</mat-hint>
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>

                    <mat-error *ngIf="opportunityForm.controls['dateVisible'].get('start')?.hasError('matStartDateInvalid')"> Невірна дата початку </mat-error>
                    <mat-error *ngIf="opportunityForm.controls['dateVisible'].get('start')?.hasError('matEndDateInvalid')"> Невірна дата кінця </mat-error>
                </mat-form-field>
            </div>
            <div class="settings-controls settings-controls">
                <mat-form-field
                    appearance="fill"
                    [floatLabel]="'always'"
                    class="settings-controls__input">
                    <mat-label>Період, коли відбуваеється роздача допомоги</mat-label>
                    <mat-date-range-input
                        formGroupName="dateActive"
                        [rangePicker]="activePicker">
                        <input
                            matStartDate
                            formControlName="start"
                            placeholder="Початок" />
                        <input
                            matEndDate
                            formControlName="end"
                            placeholder="Закінчення" />
                    </mat-date-range-input>
                    <mat-hint>формат - 12/31/2022</mat-hint>
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="activePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #activePicker></mat-date-range-picker>

                    <mat-error *ngIf="opportunityForm.controls['dateActive'].get('start')?.hasError('matStartDateInvalid')"> Невірна дата початку </mat-error>
                    <mat-error *ngIf="opportunityForm.controls['dateActive'].get('start')?.hasError('matEndDateInvalid')"> Невірна дата кінця </mat-error>
                </mat-form-field>
            </div>
            <div class="settings-controls settings-controls">
                <mat-form-field
                    appearance="fill"
                    [floatLabel]="'always'"
                    class="settings-controls__input">
                    <mat-label>Проєкт</mat-label>
                    <mat-select formControlName="project">
                        <mat-option
                            *ngFor="let p of projects"
                            [value]="p.baseProject.id">
                            {{ p.baseProject.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row">
            <div class="settings-controls settings-controls--ultra-long settings-controls--vertical-space">
                <mat-form-field
                    class="settings-controls__input"
                    [floatLabel]="'always'"
                    appearance="fill">
                    <mat-label>Короткий опис</mat-label>
                    <textarea
                        matInput
                        placeholder="Короткий опис"
                        formControlName="shortDescription"
                        class="settings-controls__input short-description"
                        rows="4"
                        [maxlength]="getMaxShortDescription()"></textarea>
                    <mat-error *ngIf="opportunityForm.controls['shortDescription'].hasError('required')"> Короткий опис <strong>Обов'язковий</strong> </mat-error>
                    <mat-error *ngIf="opportunityForm.controls['shortDescription'].hasError('maxlength')">
                        Максимальна довжина <strong>{{ getMaxShortDescription() }} символів</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row">
            <div class="settings-controls settings-controls--ultra-long settings-controls--vertical-space">
                <h5>Інформація про подію</h5>
                <quill-editor
                    class="settings-controls__input"
                    formControlName="description"
                    [styles]="{ height: '200px', width: '100%' }"
                    placeholder="Інформація про подію...">
                </quill-editor>
            </div>
        </div>
        <div fxLayout="row">
            <div class="settings-controls settings-controls--ultra-long settings-controls--vertical-space">
                <h5>Інформація про місце, дату та час здійснення гуманітарної допомоги</h5>
                <quill-editor
                    class="settings-controls__input"
                    formControlName="activeDescription"
                    [styles]="{ height: '200px', width: '100%' }"
                    placeholder="Інформація про місце, дату та час здійснення гуманітарної допомоги...">
                </quill-editor>
            </div>
        </div>
        <div fxLayout="row">
            <input
                type="hidden"
                formControlName="suggestmentId" />
            <div class="settings-controls settings-controls--ultra-long settings-controls--vertical-space">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="opportunity-collapseable__header">
                        <mat-panel-title> Налаштування розподілення </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div
                        fxLayoutAlign="start"
                        fxLayoutAlign.xs="space-evenly"
                        fxLayout="row"
                        fxLayout.xs="column">
                        <div class="settings-controls settings-controls--suggestment">
                            <mat-form-field
                                class="settings-controls__input"
                                [floatLabel]="'always'">
                                <mat-label>Загалом досупно наборів</mat-label>
                                <input
                                    matInput
                                    placeholder="Загалом досупно наборів"
                                    formControlName="avail"
                                    class="settings-controls__input" />
                            </mat-form-field>
                        </div>
                        <div class="settings-controls settings-controls--suggestment">
                            <mat-form-field
                                class="settings-controls__input"
                                [floatLabel]="'always'">
                                <mat-label>Кількість людей, на яку розрахований один набір</mat-label>
                                <input
                                    matInput
                                    placeholder="Кількість людей, на яку розрахований один набір"
                                    formControlName="packetcap"
                                    class="settings-controls__input" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div
                        fxLayoutAlign="start"
                        fxLayoutAlign.xs="space-evenly"
                        fxLayout="row"
                        fxLayout.xs="column">
                        <div class="settings-controls settings-controls--suggestment">
                            <mat-form-field
                                class="settings-controls__input"
                                [floatLabel]="'always'">
                                <mat-label>Мінімальна кількість наборів для однієї групи</mat-label>
                                <input
                                    matInput
                                    placeholder="Мінімальна кількість наборів для однієї групи"
                                    formControlName="minpacket"
                                    class="settings-controls__input" />
                            </mat-form-field>
                        </div>
                        <div class="settings-controls settings-controls--suggestment">
                            <mat-form-field
                                class="settings-controls__input"
                                [floatLabel]="'always'">
                                <mat-label>Максимальна кількість наборів для однієї групи</mat-label>
                                <input
                                    matInput
                                    placeholder="Максимальна кількість наборів для однієї групи"
                                    formControlName="upperbound"
                                    class="settings-controls__input" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div
                        fxLayoutAlign="start"
                        fxLayoutAlign.xs="space-evenly"
                        fxLayout="row"
                        fxLayout.xs="column">
                        <div class="settings-controls settings-controls--suggestment">
                            <mat-form-field
                                class="settings-controls__input"
                                [floatLabel]="'always'">
                                <mat-label>Відносна/абсолютна приоритезація</mat-label>
                                <input
                                    matInput
                                    placeholder="Відносна/абсолютна приоритезація"
                                    formControlName="prioritystyle"
                                    class="settings-controls__input" />
                            </mat-form-field>
                        </div>
                        <div class="settings-controls settings-controls--suggestment">
                            <mat-form-field
                                class="settings-controls__input"
                                [floatLabel]="'always'">
                                <mat-label>Коефіцієнт зниження пріоритету</mat-label>
                                <input
                                    matInput
                                    type="number"
                                    placeholder="Загалом досупно наборів"
                                    formControlName="decay"
                                    class="settings-controls__input" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div>
                        <h5>Приорітети груп(Ч/Ж/ЧДит/ЖДит/ЧЛіт/ЖЛіт)</h5>
                        <div
                            fxLayoutAlign="start"
                            fxLayoutAlign.xs="space-evenly"
                            fxLayout="row"
                            fxLayout.xs="column"
                            formArrayName="priorityvec">
                            <div
                                class="settings-controls settings-controls--suggestment-small"
                                *ngFor="let segment of getPriorityvec().controls; let i = index"
                                [formGroupName]="i">
                                <mat-form-field class="settings-controls__input">
                                    <input
                                        matInput
                                        type="number"
                                        formControlName="sergmentValue" />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h5>Приорітети груп(М/Ж | Діт/Дор/Літ | Інв | ВПЛ )</h5>
                        <div formArrayName="priorityAdjVec">
                            <div
                                *ngFor="let segment of getPriorityTopAdjVec().controls; let topIndex = index"
                                [formGroupName]="topIndex"
                                fxLayoutAlign="start"
                                fxLayoutAlign.xs="space-evenly"
                                fxLayout="column">
                                <div
                                    formArrayName="priorityAdjVecSegments"
                                    fxLayoutAlign="start"
                                    fxLayoutAlign.xs="start"
                                    fxLayout="row">
                                    <div
                                        class="settings-controls settings-controls--suggestment-ultra-small"
                                        *ngFor="let segment of getPriorityAdjVec(topIndex).controls; let i = index"
                                        [formGroupName]="i">
                                        <mat-form-field class="settings-controls__input settings-controls__input">
                                            <input
                                                matInput
                                                type="number"
                                                formControlName="sergmentValue" />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h5>Потреби груп(М/Ж | Діт/Дор/Літ | Інв | ВПЛ )</h5>
                        <div formArrayName="demandVec">
                            <div
                                *ngFor="let segment of getDemandTopVec().controls; let topIndex = index"
                                [formGroupName]="topIndex"
                                fxLayoutAlign="start"
                                fxLayoutAlign.xs="space-evenly"
                                fxLayout="column">
                                <div
                                    formArrayName="demandVecSegments"
                                    fxLayoutAlign="start"
                                    fxLayoutAlign.xs="start"
                                    fxLayout="row">
                                    <div
                                        class="settings-controls settings-controls--suggestment-ultra-small"
                                        *ngFor="let segment of getDemandVec(topIndex).controls; let i = index"
                                        [formGroupName]="i">
                                        <mat-form-field class="settings-controls__input settings-controls__input">
                                            <input
                                                matInput
                                                type="number"
                                                formControlName="sergmentValue" />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h5>Процент присутніх/залишити запас</h5>
                        <div
                            fxLayoutAlign="start"
                            fxLayoutAlign.xs="space-evenly"
                            fxLayout="row"
                            fxLayout.xs="column"
                            formArrayName="protocol">
                            <div
                                class="settings-controls settings-controls--suggestment-small"
                                *ngFor="let segment of getProtocol().controls; let i = index"
                                [formGroupName]="i">
                                <mat-form-field class="settings-controls__input">
                                    <input
                                        matInput
                                        type="number"
                                        formControlName="sergmentValue" />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h5>Приріст приорітету (ВПО/Інвалідність)</h5>
                        <div
                            fxLayoutAlign="start"
                            fxLayoutAlign.xs="space-evenly"
                            fxLayout="row"
                            fxLayout.xs="column"
                            formArrayName="propFactors">
                            <div
                                class="settings-controls settings-controls--suggestment-small"
                                *ngFor="let segment of getPropFactors().controls; let i = index"
                                [formGroupName]="i">
                                <mat-form-field class="settings-controls__input">
                                    <input
                                        matInput
                                        type="number"
                                        formControlName="sergmentValue" />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
        </div>
        <div fxLayout="row">
            <div class="settings-controls settings-controls--ultra-long settings-controls--vertical-space">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="opportunity-collapseable__header">
                        <mat-panel-title>Налаштування Фільтру </mat-panel-title>
                    </mat-expansion-panel-header>
                    <h5 class="settings-controls__subheader">Стать</h5>
                    <div
                        fxLayoutAlign="start"
                        fxLayoutAlign.xs="space-evenly"
                        fxLayout="row"
                        fxLayout.xs="column">
                        <div class="settings-controls settings-controls--suggestment-small">
                            <mat-checkbox
                                color="primary"
                                formControlName="filterGenderMale">
                                <span class="settings-controls__checkbox-label">Чоловіча</span>
                            </mat-checkbox>
                        </div>
                        <div class="settings-controls settings-controls--suggestment-small">
                            <mat-checkbox
                                color="primary"
                                formControlName="filterGenderFemale">
                                <span class="settings-controls__checkbox-label">Жіноча</span>
                            </mat-checkbox>
                        </div>
                    </div>
                    <h5 class="settings-controls__subheader">Вік</h5>
                    <div
                        fxLayoutAlign="start"
                        fxLayoutAlign.xs="space-evenly"
                        fxLayout="row"
                        fxLayout.xs="column">
                        <div class="settings-controls settings-controls--suggestment-small">
                            <mat-checkbox
                                color="primary"
                                formControlName="filterAgeChildren">
                                <span class="settings-controls__checkbox-label">Діти</span>
                            </mat-checkbox>
                        </div>
                        <div class="settings-controls settings-controls--suggestment-small">
                            <mat-checkbox
                                color="primary"
                                formControlName="filterAgeAdult">
                                <span class="settings-controls__checkbox-label">Дорослі</span>
                            </mat-checkbox>
                        </div>
                        <div class="settings-controls settings-controls--suggestment-small">
                            <mat-checkbox
                                color="primary"
                                formControlName="filterAgeOld">
                                <span class="settings-controls__checkbox-label">Похилого віку</span>
                            </mat-checkbox>
                        </div>
                    </div>
                    <h5 class="settings-controls__subheader">Ознаки</h5>
                    <div
                        fxLayoutAlign="start"
                        fxLayoutAlign.xs="space-evenly"
                        fxLayout="row"
                        fxLayout.xs="column">
                        <div class="settings-controls settings-controls--suggestment-small">
                            <mat-checkbox
                                color="primary"
                                formControlName="filterIsTemporary">
                                <span class="settings-controls__checkbox-label">ВПО</span>
                            </mat-checkbox>
                        </div>
                        <div class="settings-controls settings-controls--suggestment-small">
                            <mat-checkbox
                                color="primary"
                                formControlName="filterIsInjury">
                                <span class="settings-controls__checkbox-label">Інвалідність</span>
                            </mat-checkbox>
                        </div>
                    </div>
                    <div
                        fxLayoutAlign="start"
                        fxLayoutAlign.xs="space-evenly"
                        fxLayout="row"
                        fxLayout.xs="column">
                        <div class="settings-controls settings-controls--suggestment-ultra-long">
                            <mat-form-field
                                class="settings-controls__input"
                                [floatLabel]="'always'"
                                appearance="fill">
                                <mat-label>Поштові Індекси</mat-label>
                                <textarea
                                    matInput
                                    placeholder="Поштові Індекси"
                                    formControlName="filterAddressZips"
                                    class="settings-controls__input short-description"
                                    rows="4"
                                    [maxlength]="getMaxDescription()"></textarea>
                                <mat-error *ngIf="opportunityForm.controls['filterAddressZips'].hasError('maxlength')">
                                    Поштові Індекси <strong>{{ getMaxDescription() }} символів</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
        </div>
        <div fxLayout="row">
            <div class="settings__footer">
                <div class="settings-controls settings-controls--vertical-space settings-controls--horizontal-centred">
                    <button
                        mat-flat-button
                        color="primary"
                        class="settings-controls__input"
                        type="submit">
                        Зберегти
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
