import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import * as AdminActions from 'src/app/modules/admin/store/admin.actions';
import { enumToObjectsList } from 'src/app/modules/core/helpers/enum.helper';
import { isMobile } from 'src/app/modules/core/helpers/mobile.helper';
import { OpportunityModel } from 'src/app/modules/core/models/opportunity.model';
import { IProject, ProjectModel, ProjectStatus } from 'src/app/modules/core/models/project.model';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';
import { CommonPromptComponent, CommonPromptIconType } from 'src/app/modules/shared/components/common-prompt/common-prompt.component';

const PROJECT_SETTINGS = {
    maxDescription: 2000,
};

@Component({
    selector: 'app-admin-project',
    templateUrl: './admin-project.component.html',
    styleUrls: ['./admin-project.component.scss'],
})
export class AdminProjectComponent implements OnInit, OnDestroy {
    error: string | null = '';
    isLoading = false;
    projectId = '';
    project: ProjectModel = new ProjectModel();
    projectForm: FormGroup;
    opportunities: OpportunityModel[] = [];

    opportunitiesColumns = ['name', 'distribution'];
    opportunitiesColumnsMobile = ['username'];
    opportunitiesColumnsWithExpand: string[] = [...this.getColumns(), 'expand'];
    projectStatuses = enumToObjectsList(ProjectStatus);
    private storeSubscription: Subscription = new Subscription();

    constructor(private router: Router, private store: Store<IApplicationState>, private currentRoute: ActivatedRoute, private dialog: MatDialog) {
        this.projectForm = this.getProjectFormGroup();
    }

    ngOnInit(): void {
        this.currentRoute.params.subscribe((currentParams) => {
            this.projectId = currentParams['projectId'];
            if (this.projectId) {
                this.store.dispatch(AdminActions.projectGet({ projectId: this.projectId }));
                // this.store.dispatch(AdminActions.opportunityRequestsGetByUser({ userId: this.userId }));

                this.storeSubscription = this.store.select('admin').subscribe((adminState) => {
                    this.isLoading = adminState.requestState === LoadingState.LOADING;
                    this.error = getError(adminState.requestState);
                    if (!this.project.baseProject.id && adminState.project) {
                        this.project = adminState.project.clone();
                        this.opportunities = adminState.project.getOpportunities();
                        this.projectForm = this.getProjectFormGroup();

                        console.log('project', this.project);
                    }

                    // if (!this.opportunityRequests.length && adminState.userOpportunityRequests) {
                    //     this.opportunityRequests = adminState.userOpportunityRequests;
                    // }
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.projectId = '';
        this.storeSubscription.unsubscribe();
    }

    onProjectSubmit(): void {
        this.dialog.open(CommonPromptComponent, {
            data: {
                icon: CommonPromptIconType.Question,
                header: this.isNewProject() ? 'Створити проєкт?' : 'Оновити проєкт?',
                callback: this.processUpdateProject.bind(this),
            },
        });
    }

    processUpdateProject() {
        console.log('projectForm', this.projectForm.value);
        const { id, status, name, description, male, female, childMale, childFemale, seniorMale, seniorFemale } = this.projectForm.value;
        const projectDataToProcess: IProject = {
            id,
            status,
            name,
            description,
            male,
            female,
            childMale,
            childFemale,
            seniorMale,
            seniorFemale,
        };
        const projectToProcess = new ProjectModel(projectDataToProcess);
        if (this.isNewProject()) {
            this.store.dispatch(AdminActions.projectCreate({ project: projectToProcess }));
        } else {
            this.store.dispatch(AdminActions.projectUpdate({ project: projectToProcess }));
        }
    }

    navigateToOpportunityPage(opportunity: OpportunityModel) {
        this.router.navigate(['admin', 'opportunity', opportunity.baseOpportunity.id]);
    }

    getProjectFormGroup(): FormGroup {
        console.log('getUserFormGroup');

        const projectFormGroup = new FormGroup({
            id: new FormControl(this.project.baseProject.id || ''),
            status: new FormControl(this.project.baseProject.status, [Validators.required]),
            name: new FormControl(this.project.baseProject.name || '', [Validators.required]),
            description: new FormControl(this.project.baseProject.description || ''),
            male: new FormControl(this.project.baseProject.male, [Validators.required]),
            female: new FormControl(this.project.baseProject.female, [Validators.required]),
            childMale: new FormControl(this.project.baseProject.childMale, [Validators.required]),
            childFemale: new FormControl(this.project.baseProject.childFemale, [Validators.required]),
            seniorMale: new FormControl(this.project.baseProject.seniorMale, [Validators.required]),
            seniorFemale: new FormControl(this.project.baseProject.seniorFemale, [Validators.required]),
        });

        return projectFormGroup;
    }

    getProjectsUrl() {
        const url = `/admin/projects`;
        return url;
    }

    getProjectTitle() {
        let result = '';

        if (this.isNewProject()) {
            result = 'Створення нового проєкту';
        } else {
            result = `Проєкт ${this.project.baseProject.name}`;
        }

        return result;
    }

    getButtonUpdateProjectTitle() {
        let result = '';

        if (this.isNewProject()) {
            result = 'Створити проєкт';
        } else {
            result = 'Оновити проєкт';
        }

        return result;
    }

    getProjectsTitle() {
        const result = `Проєкти`;
        return result;
    }

    isNewProject() {
        return !this.projectId;
    }

    getMaxDescription() {
        return PROJECT_SETTINGS.maxDescription;
    }

    getColumns() {
        return isMobile() ? this.opportunitiesColumnsMobile : this.opportunitiesColumns;
    }

    isMobile() {
        return isMobile();
    }
}
