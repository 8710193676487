import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { IApplicationState } from 'src/app/app.reducer';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute } from '@angular/router';
import * as AdminActions from 'src/app/modules//admin/store/admin.actions';
import { getError } from 'src/app/modules/core/models/request-state.model';
import { fromGenericTypesToOpportunityAvailableCategories, fromGenericTypesToOpportunityTypes } from '../../store/admin.utils';
import { GenericTypeModel } from 'src/app/modules/core/models/generic-type.model';
import {
    EMPTY_OPPORTUNITY_FILTER_FLAT,
    EMPTY_OPPORTUNITY_SUGGESTMENT,
    IOpportunity,
    IOpportunityFilterFlat,
    IOpportunitySuggestment,
    OpportunityModel,
    OpportunityStatus,
    OpportunitySuggestmentStatus,
} from 'src/app/modules/core/models/opportunity.model';
import { enumToObjectsList } from 'src/app/modules/core/helpers/enum.helper';
import { DEFAULT_ADDRESS_COUNTRY } from 'src/app/modules/auth/store/auth.utils';
import { IAddress } from 'src/app/modules/core/models/address.model';
import { ProjectModel } from 'src/app/modules/core/models/project.model';
import { CommonPromptComponent, CommonPromptIconType } from 'src/app/modules/shared/components/common-prompt/common-prompt.component';
import { DateHelper } from 'src/app/modules/core/helpers/date.helper';

const OPPORTUNITY_SETTINGS = {
    suggestmentPriorityvec: [0.1, 0.1, 0.341, 0.341, 0.148, 0.148],
    suggestmentPriorityAdjVec: [
        [1, 1],
        [3.41, 1, 2],
        [1, 1.2],
        [1, 1.5],
    ],
    suggestmentDemandVec: [
        [1, 1],
        [1, 1, 1],
        [1, 1],
        [1, 1],
    ],
    suggestmentProtocol: [0.8, 0.02],
    suggestmentPropFactors: [0, 0],
    suggestmentAvail: 50,
    suggestmentPacketcap: 1,
    suggestmentMinpacket: 0,
    suggestmentUpperbound: 0,
    suggestmentPrioritystyle: 0,
    decay: 0.3,
    api: 'v2',
    maxShortDescription: 250,
    maxDescription: 2000,
    separatorKeysCodes: [ENTER, COMMA],
};

@Component({
    selector: 'app-admin-opportunity-settings',
    templateUrl: './admin-opportunity-settings.component.html',
    styleUrls: ['./admin-opportunity-settings.component.scss'],
})
export class AdminOpportunitySettingsComponent implements OnInit, OnDestroy {
    isLoading = false;
    error: string | null = '';
    private storeSubscription: Subscription = new Subscription();
    opportunityId = '';
    opportunity: OpportunityModel | null = null;

    opportunityForm: FormGroup;
    opportunityStatuses = enumToObjectsList(OpportunityStatus);
    opportunityTypes: GenericTypeModel[] = [];
    opportunityAvaiableCategorties: GenericTypeModel[] = [];

    projects: ProjectModel[] = [];

    opportunityTypeCtrl = new FormControl('');
    filteredOppotunityTypes: Observable<GenericTypeModel[]> = new Observable<GenericTypeModel[]>();
    selectedOpportunityTypes: GenericTypeModel[] = [];
    @ViewChild('opportunityTypeInput') opportunityTypeInput?: ElementRef<HTMLInputElement>;

    opportunityAvaiablecategoryCtrl = new FormControl('');
    filteredOppotunityAvailableCategories: Observable<GenericTypeModel[]> = new Observable<GenericTypeModel[]>();
    selectedOpportunityAvailableCategories: GenericTypeModel[] = [];
    @ViewChild('opportunityAvailableCategoryInput') opportunityAvailableCategoryInput?: ElementRef<HTMLInputElement>;

    constructor(public dialog: MatDialog, private store: Store<IApplicationState>, private currentRoute: ActivatedRoute) {
        this.opportunityForm = this.getOpportunitySettingsFormGroup();
        // this.opportunityForm = this.getInitalOpportunitySettingsFormGroup();
    }

    ngOnInit(): void {
        console.log('ngOnInit');
        this.currentRoute.params.subscribe((currentParams) => {
            this.opportunityId = currentParams['opportunityId'];
            if (this.opportunityId) {
                this.store.dispatch(AdminActions.opportunityGetOne({ opportunityId: this.opportunityId }));
                this.store.dispatch(AdminActions.projectsGet());
            } else {
                this.opportunityForm = this.getOpportunitySettingsFormGroup();
            }

            this.storeSubscription = this.store.select('admin').subscribe((adminState) => {
                this.error = getError(adminState.requestState);
                if (adminState.opportunity && this.opportunityId) {
                    this.opportunity = adminState.opportunity.clone();
                    this.opportunityForm = this.getOpportunitySettingsFormGroup(this.opportunity);

                    this.selectedOpportunityAvailableCategories = [...this.opportunity.baseOpportunity.availableCategories];
                    this.selectedOpportunityTypes = [...this.opportunity.baseOpportunity.types];

                    this.opportunityAvaiablecategoryCtrl.setValue(null);
                    this.opportunityTypeCtrl.setValue(null);

                    console.log('opportunityForm - opportunity', this.opportunity);
                }
                if (!this.opportunityTypes.length && adminState.genericTypes.length) {
                    this.opportunityTypes = fromGenericTypesToOpportunityTypes(adminState.genericTypes);
                    this.opportunityAvaiableCategorties = fromGenericTypesToOpportunityAvailableCategories(adminState.genericTypes);
                    this.initFilterBlocks();
                }

                if (!this.projects.length && adminState.projects.length) {
                    this.projects = [new ProjectModel(), ...adminState.projects.map((p) => p.clone())];
                    console.log('opportunityForm - projects', this.projects);
                }
            });
        });
    }

    ngOnDestroy(): void {
        this.opportunity = null;
        this.opportunityId = '';
        this.storeSubscription.unsubscribe();
    }

    onSubmit(): void {
        if (this.opportunityForm.valid) {
            const opportunity = this.getUserOpportunityData();
            console.log(opportunity);
            if (this.opportunityId) {
                this.store.dispatch(AdminActions.opportunityUpdate({ opportunity }));
            } else {
                this.store.dispatch(AdminActions.opportunityCreate({ opportunity }));
            }
        }
    }

    initFilterBlocks() {
        this.filteredOppotunityTypes = this.opportunityTypeCtrl.valueChanges.pipe(
            startWith(null),
            map((oppType: string | null) => {
                console.log('this.filteredOppotunityTypes', oppType);
                let filteredOpportunities = oppType ? this._filterOpportunityProperties(oppType, this.opportunityTypes) : this.opportunityTypes.slice();
                filteredOpportunities = filteredOpportunities.filter((opp) => {
                    return !this.selectedOpportunityTypes.some((selOpp) => selOpp.baseType.id === opp.baseType.id);
                });
                return filteredOpportunities;
            })
        );

        this.filteredOppotunityAvailableCategories = this.opportunityAvaiablecategoryCtrl.valueChanges.pipe(
            startWith(null),
            map((oppType: string | null) => {
                let filteredCategories = oppType ? this._filterOpportunityProperties(oppType, this.opportunityAvaiableCategorties) : this.opportunityAvaiableCategorties.slice();
                filteredCategories = filteredCategories.filter((opp) => {
                    return !this.selectedOpportunityAvailableCategories.some((selOpp) => selOpp.baseType.id === opp.baseType.id);
                });
                return filteredCategories;
            })
        );
    }

    selectOpportunityHandler(event: MatAutocompleteSelectedEvent): void {
        this.selectedOpportunityTypes.push(event.option.value);
        if (this.opportunityTypeInput) {
            this.opportunityTypeInput.nativeElement.value = '';
        }
        this.opportunityTypeCtrl.setValue(null);
    }

    selectOpportunityAvaiableCategoryHandler(event: MatAutocompleteSelectedEvent): void {
        this.selectedOpportunityAvailableCategories.push(event.option.value);
        if (this.opportunityAvailableCategoryInput) {
            this.opportunityAvailableCategoryInput.nativeElement.value = '';
        }
        this.opportunityAvaiablecategoryCtrl.setValue(null);
    }

    removeOpportunityType(opportunityType: GenericTypeModel) {
        this._removeOpportunityHandler(opportunityType, this.selectedOpportunityTypes);
    }

    removeOpportunityAvailableCategory(opportunityAvailableCategory: GenericTypeModel) {
        this._removeOpportunityHandler(opportunityAvailableCategory, this.selectedOpportunityAvailableCategories);
    }

    _removeOpportunityHandler(item: GenericTypeModel, source: GenericTypeModel[]): void {
        const index = source.indexOf(item);
        if (index >= 0) {
            source.splice(index, 1);
        }
        this.opportunityTypeCtrl.setValue(null);
        this.opportunityAvaiablecategoryCtrl.setValue(null);
    }

    private _filterOpportunityProperties(value: string | GenericTypeModel, source: GenericTypeModel[]): GenericTypeModel[] {
        if (typeof value === 'string') {
            return source.filter((oppType) => oppType.baseType.id === value);
        } else {
            return source.filter((oppType) => oppType.baseType.id === value.baseType.id);
        }
    }

    getSeparatorKeysCodes() {
        return OPPORTUNITY_SETTINGS.separatorKeysCodes;
    }

    getMaxShortDescription() {
        return OPPORTUNITY_SETTINGS.maxShortDescription;
    }

    getMaxDescription() {
        return OPPORTUNITY_SETTINGS.maxDescription;
    }

    getPriorityvec(): FormArray {
        const items = this.opportunityForm.get('priorityvec') as FormArray;
        return items;
    }

    getPriorityTopAdjVec(): FormArray {
        const items = this.opportunityForm.get('priorityAdjVec') as FormArray;
        return items;
    }

    getPriorityAdjVec(index: number): FormArray {
        const priorityAdjVecFormArary = this.opportunityForm.get('demandVec') as FormArray;
        const items = priorityAdjVecFormArary.controls[index].get('demandVecSegments') as FormArray;
        return items;
    }

    getDemandTopVec(): FormArray {
        const items = this.opportunityForm.get('demandVec') as FormArray;
        return items;
    }

    getDemandVec(index: number): FormArray {
        const demandVecFormArary = this.opportunityForm.get('demandVec') as FormArray;
        const items = demandVecFormArary.controls[index].get('demandVecSegments') as FormArray;
        return items;
    }

    getProtocol(): FormArray {
        const items = this.opportunityForm.get('protocol') as FormArray;
        return items;
    }

    getPropFactors(): FormArray {
        const items = this.opportunityForm.get('propFactors') as FormArray;
        return items;
    }

    getInitalOpportunitySettingsFormGroup(): FormGroup {
        const priorityvec: FormGroup[] = [];
        const priorityvecSegments = new FormArray(priorityvec);
        for (let segment of OPPORTUNITY_SETTINGS.suggestmentPriorityvec) {
            const priorityvecFormControl = new FormGroup({
                sergmentValue: new FormControl(segment.toString(), [Validators.required]),
            });
            priorityvecSegments.push(priorityvecFormControl);
        }

        const protocol: FormGroup[] = [];
        const protocolSegments = new FormArray(protocol);
        for (let segment of OPPORTUNITY_SETTINGS.suggestmentProtocol) {
            const protocolFormControl = new FormGroup({
                sergmentValue: new FormControl(segment.toString(), [Validators.required]),
            });
            protocolSegments.push(protocolFormControl);
        }

        const propFactors: FormGroup[] = [];
        const propfactorsSegments = new FormArray(propFactors);
        for (let segment of OPPORTUNITY_SETTINGS.suggestmentPropFactors) {
            const propfactorsFormControl = new FormGroup({
                sergmentValue: new FormControl(segment.toString(), [Validators.required]),
            });
            propfactorsSegments.push(propfactorsFormControl);
        }

        const opportunityGroup = new FormGroup({
            id: new FormControl(''),
            name: new FormControl('', [Validators.required]),
            shortDescription: new FormControl('', [Validators.required, Validators.max(OPPORTUNITY_SETTINGS.maxShortDescription)]),
            description: new FormControl(''),
            activeDescription: new FormControl(''),
            addressId: new FormControl(''),
            city: new FormControl('', [Validators.required]),
            region: new FormControl('', [Validators.required]),
            status: new FormControl(OpportunityStatus.new, [Validators.required]),
            dateVisible: new FormGroup({
                start: new FormControl<Date | null>(null),
                end: new FormControl<Date | null>(null),
            }),
            dateActive: new FormGroup({
                start: new FormControl<Date | null>(null),
                end: new FormControl<Date | null>(null),
            }),
            opportunityTypes: new FormControl(''),
            opportunityAvailableCategories: new FormControl(''),
            suggestmentId: new FormControl(''),
            avail: new FormControl(OPPORTUNITY_SETTINGS.suggestmentAvail, [Validators.required]),
            priorityvec: priorityvecSegments,
            packetcap: new FormControl(OPPORTUNITY_SETTINGS.suggestmentPacketcap, [Validators.required]),
            minpacket: new FormControl(OPPORTUNITY_SETTINGS.suggestmentMinpacket, [Validators.required]),
            upperbound: new FormControl(OPPORTUNITY_SETTINGS.suggestmentUpperbound, [Validators.required]),
            prioritystyle: new FormControl(OPPORTUNITY_SETTINGS.suggestmentPrioritystyle, [Validators.required]),
            protocol: protocolSegments,
            propFactors: propfactorsSegments,
        });

        return opportunityGroup;
    }

    getOpportunitySettingsFormGroup(opportunity: OpportunityModel = new OpportunityModel()): FormGroup {
        const flatFilters = opportunity.getFlatFilters();

        let suggestment = opportunity.getSuggestment();
        if (!opportunity.getSuggestment() || !opportunity.baseOpportunity.id) {
            suggestment = { ...EMPTY_OPPORTUNITY_SUGGESTMENT };
            suggestment.propFactors = [...OPPORTUNITY_SETTINGS.suggestmentPropFactors];
            suggestment.priorityVec = [...OPPORTUNITY_SETTINGS.suggestmentPriorityvec];
            suggestment.protocol = [...OPPORTUNITY_SETTINGS.suggestmentProtocol];
            suggestment.decay = OPPORTUNITY_SETTINGS.decay;
            suggestment.api = OPPORTUNITY_SETTINGS.api;
        }

        if (!suggestment.priorityAdjVec || !suggestment.priorityAdjVec.length) {
            suggestment.priorityAdjVec = [...OPPORTUNITY_SETTINGS.suggestmentPriorityAdjVec];
            suggestment.demandVec = [...OPPORTUNITY_SETTINGS.suggestmentDemandVec];
            suggestment.decay = OPPORTUNITY_SETTINGS.decay;
            suggestment.api = OPPORTUNITY_SETTINGS.api;
        }

        const baseOpportunity = opportunity.baseOpportunity;
        const address = opportunity.getAddress();
        const project = opportunity.getProject() || new ProjectModel();

        const priorityvec: FormGroup[] = [];
        const priorityvecSegments = new FormArray(priorityvec);
        for (let segment of suggestment.priorityVec) {
            const priorityvecFormControl = new FormGroup({
                sergmentValue: new FormControl(segment.toString(), [Validators.required]),
            });
            priorityvecSegments.push(priorityvecFormControl);
        }

        const priorityTopAdjvec: FormGroup[] = [];
        const priorityTopAdjVecSegments = new FormArray(priorityTopAdjvec);
        for (let segTopCounter = 0; segTopCounter < suggestment.priorityAdjVec.length; segTopCounter++) {
            const topSegment = suggestment.priorityAdjVec[segTopCounter];

            const priorityAdjVec: FormGroup[] = [];
            const priorityAdjVecSegments = new FormArray(priorityAdjVec);

            for (let segment of topSegment) {
                const priorityAdjVecFormControl = new FormGroup({
                    sergmentValue: new FormControl(segment.toString(), [Validators.required]),
                });
                priorityAdjVecSegments.push(priorityAdjVecFormControl);
            }

            const priorityTopAdjvecFormGroup = new FormGroup({
                priorityAdjVecSegments: priorityAdjVecSegments,
            });
            priorityTopAdjVecSegments.push(priorityTopAdjvecFormGroup);
        }

        const demandTopVec: FormGroup[] = [];
        const demandTopVecSegments = new FormArray(demandTopVec);
        for (let segTopCounter = 0; segTopCounter < suggestment.demandVec.length; segTopCounter++) {
            const topSegment = suggestment.demandVec[segTopCounter];

            const demandVec: FormGroup[] = [];
            const demandVecSegments = new FormArray(demandVec);

            for (let segment of topSegment) {
                const demandVecFormControl = new FormGroup({
                    sergmentValue: new FormControl(segment.toString(), [Validators.required]),
                });
                demandVecSegments.push(demandVecFormControl);
            }

            const demandTopVecFormGroup = new FormGroup({
                demandVecSegments: demandVecSegments,
            });
            demandTopVecSegments.push(demandTopVecFormGroup);
        }

        const protocol: FormGroup[] = [];
        const protocolSegments = new FormArray(protocol);
        for (let segment of suggestment.protocol) {
            const protocolFormControl = new FormGroup({
                sergmentValue: new FormControl(segment.toString(), [Validators.required]),
            });
            protocolSegments.push(protocolFormControl);
        }

        const propFactors: FormGroup[] = [];
        const propfactorsSegments = new FormArray(propFactors);
        for (let segment of suggestment.propFactors) {
            const propfactorsFormControl = new FormGroup({
                sergmentValue: new FormControl(segment.toString(), [Validators.required]),
            });
            propfactorsSegments.push(propfactorsFormControl);
        }

        const opportunityGroup = new FormGroup({
            id: new FormControl(baseOpportunity.id),
            name: new FormControl(baseOpportunity.name, [Validators.required]),
            shortDescription: new FormControl(baseOpportunity.shortDescription, [Validators.required, Validators.max(OPPORTUNITY_SETTINGS.maxShortDescription)]),
            description: new FormControl(baseOpportunity.description),
            activeDescription: new FormControl(baseOpportunity.activeDescription),
            project: new FormControl(project.baseProject.id),
            addressId: new FormControl(address.id),
            city: new FormControl(address.city, [Validators.required]),
            region: new FormControl(address.region, [Validators.required]),
            status: new FormControl(baseOpportunity.status, [Validators.required]),
            dateVisible: new FormGroup({
                start: new FormControl<Date | null>(baseOpportunity.id ? baseOpportunity.visible.startDate : null),
                end: new FormControl<Date | null>(baseOpportunity.id ? baseOpportunity.visible.endDate : null),
            }),
            dateActive: new FormGroup({
                start: new FormControl<Date | null>(baseOpportunity.id ? baseOpportunity.active.startDate : null),
                end: new FormControl<Date | null>(baseOpportunity.id ? baseOpportunity.active.endDate : null),
            }),
            opportunityTypes: new FormControl(baseOpportunity.types),
            opportunityAvailableCategories: new FormControl(baseOpportunity.availableCategories),
            suggestmentId: new FormControl(suggestment.id),
            avail: new FormControl(suggestment.avail, [Validators.required]),
            decay: new FormControl(parseFloat(suggestment.decay.toString()), [Validators.required]),
            priorityvec: priorityvecSegments,
            packetcap: new FormControl(suggestment.priorityCap, [Validators.required]),
            minpacket: new FormControl(suggestment.minPacket, [Validators.required]),
            upperbound: new FormControl(suggestment.upperBound, [Validators.required]),
            prioritystyle: new FormControl(suggestment.priorityStyle, [Validators.required]),
            protocol: protocolSegments,
            propFactors: propfactorsSegments,
            priorityAdjVec: priorityTopAdjVecSegments,
            demandVec: demandTopVecSegments,
            filterGenderMale: new FormControl(flatFilters.GenderMale),
            filterGenderFemale: new FormControl(flatFilters.GenderFemale),
            filterAgeChildren: new FormControl(flatFilters.AgeChildren),
            filterAgeAdult: new FormControl(flatFilters.AgeAdult),
            filterAgeOld: new FormControl(flatFilters.AgeOld),
            filterIsTemporary: new FormControl(flatFilters.IsTemporary),
            filterIsInjury: new FormControl(flatFilters.IsInjury),
            filterAddressZips: new FormControl(flatFilters.AddressZips),
        });

        return opportunityGroup;
    }

    getUserOpportunityData(): OpportunityModel {
        const {
            id,
            name,
            shortDescription,
            description,
            activeDescription,
            project,
            addressId,
            city,
            region,
            status,
            dateVisible,
            dateActive,
            suggestmentId,
            avail,
            priorityvec,
            packetcap,
            minpacket,
            upperbound,
            prioritystyle,
            protocol,
            propFactors,
            priorityAdjVec,
            demandVec,
            decay,
            filterGenderMale,
            filterGenderFemale,
            filterAgeChildren,
            filterAgeAdult,
            filterAgeOld,
            filterIsTemporary,
            filterIsInjury,
            filterAddressZips,
        } = this.opportunityForm.value;

        const flatFilter: IOpportunityFilterFlat = EMPTY_OPPORTUNITY_FILTER_FLAT;

        flatFilter.GenderMale = filterGenderMale;
        flatFilter.GenderFemale = filterGenderFemale;
        flatFilter.AgeChildren = filterAgeChildren;
        flatFilter.AgeAdult = filterAgeAdult;
        flatFilter.AgeOld = filterAgeOld;
        flatFilter.IsTemporary = filterIsTemporary;
        flatFilter.IsInjury = filterIsInjury;
        flatFilter.AddressZips = filterAddressZips;

        const address: IAddress = {
            country: DEFAULT_ADDRESS_COUNTRY,
            region,
            city,
            state: '',
            address1: '',
            zip: '',
        };

        if (addressId) {
            address.id = addressId;
        }

        const opportunity: IOpportunity = {
            status,
            name,
            shortDescription,
            description,
            activeDescription,
            types: [...this.selectedOpportunityTypes],
            availableCategories: [...this.selectedOpportunityAvailableCategories],
            visible: {
                startDate: DateHelper.GetDate(dateVisible.start),
                endDate: DateHelper.GetDate(dateVisible.end),
            },
            active: {
                startDate: DateHelper.GetDate(dateActive.start),
                endDate: DateHelper.GetDate(dateActive.end),
            },
            addresses: [address],
        };

        const suggestment: IOpportunitySuggestment = {
            status: OpportunitySuggestmentStatus.new,
            avail,
            priorityVec: priorityvec.map((seg: { sergmentValue: string }) => parseFloat(seg.sergmentValue)),
            priorityCap: packetcap,
            minPacket: minpacket,
            upperBound: upperbound,
            priorityStyle: prioritystyle,
            protocol: protocol.map((seg: { sergmentValue: string }) => parseFloat(seg.sergmentValue)),
            propFactors: propFactors.map((seg: { sergmentValue: string }) => parseFloat(seg.sergmentValue)),
            priorityAdjVec: priorityAdjVec.map((topSeg: { priorityAdjVecSegments: [] }) => {
                return topSeg.priorityAdjVecSegments.map((seg: { sergmentValue: string }) => parseFloat(seg.sergmentValue));
            }),
            demandVec: demandVec.map((topSeg: { demandVecSegments: [] }) => {
                return topSeg.demandVecSegments.map((seg: { sergmentValue: string }) => parseFloat(seg.sergmentValue));
            }),
            decay,
            api: OPPORTUNITY_SETTINGS.api,
        };

        if (suggestmentId) {
            suggestment.id = suggestmentId;
        }

        if (id) {
            suggestment.opportunityId = id;
            opportunity.id = id;
        }

        const opportunityModel = new OpportunityModel(opportunity);
        opportunityModel.setSuggestment(suggestment);
        opportunityModel.setFiltersFromFlat(flatFilter);

        const assignedProject = this.projects.find((p) => p.baseProject.id === project);
        if (project && assignedProject) {
            opportunityModel.setProject(assignedProject.clone());
        }

        return opportunityModel;
    }

    onStatusChange(status: OpportunityStatus) {
        const oldStatus = this.opportunity?.baseOpportunity.status || OpportunityStatus.new;
        if (status === OpportunityStatus.pending && oldStatus !== OpportunityStatus.pending) {
            this.dialog.open(CommonPromptComponent, {
                data: {
                    icon: CommonPromptIconType.Question,
                    header: 'Перевести подію у статус "Анонс"?',
                    content:
                        'УВАГА! Коли подію буде переведено у статус "Анонс" та подію буде збережено, усім користувачам у яких схвалена заявка буде видправлено листа з деталями та часом події. Акція буде не доступна іншим користувачам.',
                    data: {},
                    callback: this.processStatusChange.bind(this, status),
                    cancelCallback: this.processStatusChange.bind(this, oldStatus),
                },
            });
        }
    }

    processStatusChange(status: OpportunityStatus) {
        this.opportunityForm.patchValue({
            status,
        });
    }
}
