import { CloneHelper } from '../helpers/clone.helper';
import { ProfileHelper } from '../helpers/profile.helper';
import { EMPTY_BASE_ADDRESS, IAddress } from './address.model';
import { EntityModel } from './entity.model';
import { GenericTypeModel } from './generic-type.model';
import { OpportunityRequestModel } from './opportunity-request.model';
import { EMPTY_PERSON_TYPE_DISTRIBUTION, IPersonTypeDistribution, ProjectModel } from './project.model';

export enum OpportunitySource {
    profile = 'profile',
    opportunity = 'opportunity',
    opportunityFulfill = 'opportunity-fulfill',
    opportunityAvailableCategories = 'opportunity-available-categories',
}

export enum OpportunitySuggestmentStatus {
    new = 'new',
}

export enum OpportunityStatus {
    new = 'new',
    active = 'active',
    approved = 'approved',
    pending = 'pending',
    inactive = 'inactive',
}

export interface IOpportunitySuggestment {
    id?: string;
    opportunityId?: string;
    status: OpportunitySuggestmentStatus;
    avail: number;
    priorityVec: number[];
    priorityCap: number;
    minPacket: number;
    upperBound: number;
    priorityStyle: number;
    protocol: number[];
    propFactors: number[];
    api: string;
    decay: number;
    demandVec: number[][];
    priorityAdjVec: number[][];
}

export const EMPTY_OPPORTUNITY_SUGGESTMENT: IOpportunitySuggestment = {
    status: OpportunitySuggestmentStatus.new,
    avail: 0,
    priorityVec: [],
    priorityCap: 0,
    minPacket: 0,
    upperBound: 0,
    priorityStyle: 0,
    protocol: [],
    propFactors: [],
    api: 'v2',
    decay: 0.3,
    demandVec: [
        [1, 1],
        [1, 1, 1],
        [1, 1],
        [1, 1],
    ],
    priorityAdjVec: [
        [1, 1],
        [3.41, 1, 2],
        [1, 1.2],
        [1, 1.5],
    ],
};

export interface IOpportunityFilter {
    compose?: string;
    field?: string;
    condition?: string;
    value?: string | string[] | boolean;
}

export interface IOpportunityFilterFlat {
    GenderMale: boolean;
    GenderFemale: boolean;
    AgeChildren: boolean;
    AgeAdult: boolean;
    AgeOld: boolean;
    IsTemporary: boolean;
    IsInjury: boolean;
    AddressZips: string;
}

export const EMPTY_OPPORTUNITY_FILTER_FLAT: IOpportunityFilterFlat = {
    GenderMale: false,
    GenderFemale: false,
    AgeChildren: false,
    AgeAdult: false,
    AgeOld: false,
    IsTemporary: false,
    IsInjury: false,
    AddressZips: '',
};

export interface IOpportunityBase {
    id?: string;
    status: OpportunityStatus;
    name: string;
    shortDescription: string;
    description: string;
    activeDescription: string;
    addresses: IAddress[];
    visible: { startDate: Date; endDate: Date };
    active: { startDate: Date; endDate: Date };
    imageUrl?: string;
    hasAccess?: boolean;
}

export interface IOpportunity extends IOpportunityBase {
    types: GenericTypeModel[];
    availableCategories: GenericTypeModel[];
}

export const EMPTY_OPPORTUNITY: IOpportunity = {
    status: OpportunityStatus.new,
    name: '',
    shortDescription: '',
    description: '',
    activeDescription: '',
    addresses: [],
    types: [],
    availableCategories: [],
    visible: { startDate: new Date(), endDate: new Date() },
    active: { startDate: new Date(), endDate: new Date() },
    hasAccess: false
};

export class OpportunityModel extends EntityModel {
    private suggestment: IOpportunitySuggestment = EMPTY_OPPORTUNITY_SUGGESTMENT;
    private filters: IOpportunityFilter[] = [];
    // private flatFilters: IOpportunityFilterFlat = EMPTY_OPPORTUNITY_FILTER_FLAT;
    private project: ProjectModel | null = null;
    private opportunityRequest: OpportunityRequestModel | null = null;
    private opportunityRequests: OpportunityRequestModel[] = [];

    constructor(public baseOpportunity: IOpportunity = EMPTY_OPPORTUNITY) {
        super();
    }

    getAvailableCategoriesData() {
        const data = this.baseOpportunity.availableCategories.map((cat) => {
            const result = { ...cat.baseType };
            return { ...cat.baseType };
        });
        return data;
    }

    getTypesData() {
        const data = this.baseOpportunity.types.map((type) => {
            return { ...type.baseType };
        });
        return data;
    }

    getSuggestment() {
        return this.suggestment;
    }

    setProject(project: ProjectModel) {
        this.project = project;
    }

    getProject() {
        return this.project;
    }

    setSuggestment(suggestment: IOpportunitySuggestment) {
        this.suggestment = suggestment;
    }

    getOpportunityRequest() {
        return this.opportunityRequest;
    }

    setFilters(filters: IOpportunityFilter[]) {
        this.filters = filters;
    }

    getFilters() {
        return this.filters;
    }

    getFlatFilters(): IOpportunityFilterFlat {
        const flatFilter = CloneHelper.cloneData(EMPTY_OPPORTUNITY_FILTER_FLAT);
        const zipField = this.filters.find((f) => f.field && f.field === 'addresses.zip');

        flatFilter.IsTemporary = this.filters.some((f) => f.field && f.field === 'temporaryId');
        flatFilter.IsInjury = this.filters.some((f) => f.field && f.field === 'hasInjury');
        flatFilter.GenderMale = this.filters.some((f) => f.field && f.field === 'gender' && f.value && f.value === 'male');
        flatFilter.GenderFemale = this.filters.some((f) => f.field && f.field === 'gender' && f.value && f.value === 'female');
        flatFilter.AgeChildren = this.filters.some((f) => f.field && f.field === 'birthday' && f.condition && f.condition === '$gte');
        flatFilter.AgeOld = this.filters.some((f) => f.field && f.field === 'birthday' && f.condition && f.condition === '$lte');
        flatFilter.AgeAdult = this.filters.some((f) => f.field && f.field === 'birthday' && f.condition && f.condition === '$between');

        if (zipField && zipField.value && Array.isArray(zipField.value)) {
            flatFilter.AddressZips = zipField.value.filter(zip => zip.trim().length).join(',');
        }

        return flatFilter;
    }

    setFiltersFromFlat(flatFilter: IOpportunityFilterFlat) {
        const filters: IOpportunityFilter[] = [];
        const oppotunityStartDate = this.baseOpportunity.visible.startDate;

        if (flatFilter.GenderMale) {
            filters.push({
                field: 'gender',
                condition: '$eq',
                value: 'male',
            });
        }
        if (flatFilter.GenderFemale) {
            filters.push({
                field: 'gender',
                condition: '$eq',
                value: 'female',
            });
        }
        if (flatFilter.IsTemporary) {
            filters.push({
                field: 'temporaryId',
                condition: '$notnull',
            });
        }
        if (flatFilter.IsInjury) {
            filters.push({
                field: 'hasInjury',
                value: true,
            });
        }
        if (flatFilter.AgeAdult) {
            filters.push({
                field: 'birthday',
                condition: '$between',
                value: [
                    ProfileHelper.substructYearsFromDate(oppotunityStartDate, 60),
                    ProfileHelper.substructYearsFromDate(oppotunityStartDate, 18)
                ],
            });
        }
        if (flatFilter.AgeChildren) {
            filters.push({
                field: 'birthday',
                condition: '$gte',
                value: ProfileHelper.substructYearsFromDate(oppotunityStartDate, 18),
            });
        }
        if (flatFilter.AgeOld) {
            filters.push({
                field: 'birthday',
                condition: '$lte',
                value: ProfileHelper.substructYearsFromDate(oppotunityStartDate, 60),
            });
        }
        if (flatFilter.AddressZips.trim()) {
            filters.push({
                field: 'addresses.zip',
                condition: '$in',
                value: flatFilter.AddressZips.split(',').filter(s => s),
            });
        }

        this.setFilters(filters);
    }

    setOpportunityRequest(opportunityRequest: OpportunityRequestModel) {
        this.opportunityRequest = opportunityRequest;
    }

    getOpportunityRequests() {
        return this.opportunityRequests;
    }

    setOpportunityRequests(opportunityRequests: OpportunityRequestModel[]) {
        this.opportunityRequests = opportunityRequests;
    }

    getAddress() {
        return this.baseOpportunity.addresses.length ? this.baseOpportunity.addresses[0] : EMPTY_BASE_ADDRESS;
    }

    setAddress(address: IAddress) {
        this.baseOpportunity.addresses = [address];
    }

    override clone(): OpportunityModel {
        const baseOpportunityClone = CloneHelper.cloneData(this.baseOpportunity);
        const clonedModel = new OpportunityModel(baseOpportunityClone);
        clonedModel.setSuggestment(CloneHelper.cloneData(this.getSuggestment()));
        if (this.opportunityRequest) {
            clonedModel.setOpportunityRequest(this.opportunityRequest.clone());
        }
        if (this.opportunityRequests.length) {
            clonedModel.setOpportunityRequests(this.opportunityRequests.map((or) => or.clone()));
        }
        if (this.project) {
            clonedModel.setProject(this.project.clone());
        }

        if (this.filters) {
            clonedModel.setFilters(CloneHelper.cloneData(this.getFilters()));
        }
        return clonedModel;
    }
}
