<div class="profile-info">
    <div
        fxLayoutAlign="start"
        fxLayoutAlign.xs="space-evenly"
        fxLayout="row"
        fxLayout.xs="column">
        <div class="profile-info__info-container">
            <h3>Власник заявки</h3>
            <div class="profile-info__title">Прізвище, ім'я та рік народження</div>
            <div class="profile-info__value">
                <span>
                    {{ profile.firstName }}&nbsp; {{ profile.lastName }},
                    {{ profile.birthday | date : 'shortDate' }}
                </span>
            </div>
            <div class="profile-info__title">Email та телефон</div>
            <div class="profile-info__value">
                <span> {{ profile.email }}&nbsp;{{ profile.phone }} </span>
            </div>
            <div class="profile-info__title">Паспорт, номер ВПО та ознака</div>
            <div class="profile-info__value">
                <span> {{ profile.passport }}&nbsp;{{ profile.temporaryId }}&nbsp;{{ profile.hasInjury ? 'Інвалідність' : '' }} </span>
            </div>
            <div class="profile-info__title">Адреса</div>
            <div class="profile-info__value">
                <span>
                    {{ profile.addresses[0].region }}, {{ profile.addresses[0].city }}, {{ profile.addresses[0].state }}, {{ profile.addresses[0].address1 }}&nbsp;
                    {{ profile.addresses[0].zip }}
                </span>
            </div>
        </div>
        <div class="profile-info__info-container">
            <h3>Члени родини/громади</h3>
            <div
                [ngClass]="{ 'profile-info__additional-profile--other': i !== 0 }"
                *ngFor="let item of profile.additionalProfiles; index as i"
                class="profile-info__additional-profile">
                <div class="profile-info__title">Прізвище, ім'я та рік народження</div>
                <div class="profile-info__value">
                    <span>
                        {{ item.firstName }}&nbsp; {{ item.lastName }},
                        {{ item.birthday | date : 'shortDate' }}
                    </span>
                </div>
                <div class="profile-info__title">Паспорт, номер ВПО та ознака</div>
                <div class="profile-info__value">
                    <span> {{ item.passport }}&nbsp;{{ item.temporaryId }}&nbsp;{{ item.hasInjury ? 'Інвалідність' : '' }} </span>
                </div>
                <div class="profile-info__title">Паспорт, номер ВПО та ознака</div>
                <div class="profile-info__value">
                    <span> {{ profile.passport }}&nbsp;{{ profile.temporaryId }}&nbsp;{{ profile.hasInjury ? 'Інвалідність' : '' }} </span>
                </div>
            </div>
        </div>
    </div>
</div>
