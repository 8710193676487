import { CloneHelper } from '../helpers/clone.helper';
import { EntityModel } from './entity.model';

export enum GenericTypeSource {
    profile = 'profile',
    opportunity = 'opportunity',
    opportunityFulfill = 'opportunity-fulfill',
    opportunityAvailableCategories = 'opportunity-available-categories',
}

export enum GenericTypeStatus {
    new = 'new',
}

export interface IGenericType {
    id?: string;
    status: GenericTypeStatus;
    name: string;
    description: string;
    shortDescription: string;
    source: GenericTypeSource;
}

export const EMPTY_GENERIC_TYPE: IGenericType = {
    status: GenericTypeStatus.new,
    name: '',
    description: '',
    shortDescription: '',
    source: GenericTypeSource.opportunity,
};

export class GenericTypeModel extends EntityModel {
    baseType: IGenericType = EMPTY_GENERIC_TYPE;
    constructor(public genericType: IGenericType = EMPTY_GENERIC_TYPE) {
        super();

        this.baseType = {
            id: genericType.id,
            status: genericType.status,
            name: genericType.name,
            description: genericType.description,
            shortDescription: genericType.shortDescription,
            source: genericType.source,
        };
    }

    override clone(): GenericTypeModel {
        const baseTypeClone = CloneHelper.cloneData(this.baseType);
        const clonedModel = new GenericTypeModel(baseTypeClone);
        return clonedModel;
    }
}
