import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { IEnumToObject, enumToObjectsList } from 'src/app/modules/core/helpers/enum.helper';
import { isMobile } from 'src/app/modules/core/helpers/mobile.helper';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';
import { IUserProfile, UserModel, UserStatus } from 'src/app/modules/core/models/user.model';
import * as AdminActions from 'src/app/modules//admin/store/admin.actions';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';

@Component({
    selector: 'app-admin-users',
    templateUrl: './admin-users.component.html',
    styleUrls: ['./admin-users.component.scss'],
})
export class AdminUsersComponent implements OnInit, OnDestroy {
    isLoading = false;
    error: string | null = '';
    displayedColumns: string[] = ['name', 'email', 'phone'];
    displayedColumnsMobile: string[] = ['name'];
    dataSource: UserModel[] = [];
    filterForm: FormGroup;
    userStatuses: IEnumToObject[] = [
        { value: 'active', key: 'Активні' },
        { value: 'inactive', key: 'Неактивні' },
        { value: 'blocked', key: 'Заблоковані' },
    ];

    defaultSortField = 'createdAt';
    defaultSortDirection: SortDirection = 'desc';

    length = 0;
    pageSize = 20;
    pageIndex = 0;
    sortField = this.defaultSortField;
    sortDirection: SortDirection = this.defaultSortDirection;
    pageSizeOptions = [20, 50, 100];

    private storeSubscription: Subscription = new Subscription();

    constructor(private store: Store<IApplicationState>, private dialog: MatDialog, private router: Router) {
        this.filterForm = this.getInitalUsersFilterFormGroup();
        this.pageIndex = 0;
    }

    ngOnInit(): void {
        this.storeSubscription = this.store.select('admin').subscribe((adminState) => {
            this.isLoading = adminState.requestState === LoadingState.LOADING;
            this.error = getError(adminState.requestState);

            this.dataSource = adminState.users;
            this.length = adminState.usersTotal;

            console.log(this.dataSource);
        });

        this.store.dispatch(
            AdminActions.usersGet({ statuses: [], searchPattern: '', limit: this.pageSize, page: this.pageIndex, sortDirection: this.sortDirection.toUpperCase(), sortField: this.sortField })
        );
    }
    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }

    getColumns() {
        return isMobile() ? this.displayedColumnsMobile : this.displayedColumns;
    }

    onNavigateToUser(user: UserModel) {
        this.router.navigate(['admin', 'users', user.baseUser.id]);
    }

    onSubmit(): void {
        this.processSearch(0);
    }

    processSearch(page?: number) {
        const { userStates, searchPattern } = this.filterForm.value;
        const userStatuses = [...userStates];
        if (userStatuses.indexOf(UserStatus.inactive) > -1) {
            userStatuses.push(UserStatus.new);
        }

        if(page !== undefined) {
            this.pageIndex = page;
        }

        this.store.dispatch(
            AdminActions.usersGet({
                statuses: userStatuses,
                searchPattern,
                limit: this.pageSize,
                page: this.pageIndex,
                sortDirection: this.sortDirection.toUpperCase(),
                sortField: this.sortField,
            })
        );
    }

    getInitalUsersFilterFormGroup(): FormGroup {
        const opportunityRequestsFilterFormGroup = new FormGroup({
            userStates: new FormControl(''),
            searchPattern: new FormControl(''),
        });

        return opportunityRequestsFilterFormGroup;
    }

    onAddUser() {
        this.router.navigate(['admin', 'users', 'new']);
    }

    isMobile() {
        return isMobile();
    }

    onPageChange(event: PageEvent) {
        this.length = event.length;
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;

        this.processSearch();
    }

    onExport(){
        console.log('On Export');
    }

    sortData(sort: Sort) {
        console.log(sort);

        if (this.sortField === this.defaultSortField) {
            this.sortField = sort.active;
            this.sortDirection = sort.direction;
        } else {
            this.sortField = this.defaultSortField;
            this.sortDirection = this.defaultSortDirection;
        }

        this.processSearch();
    }
}
