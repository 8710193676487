<div
    class="notification-message-wrapper"
    [ngClass]="getNotificateMessageWrapperClassModifier()">
    <mat-icon
        class="notification-icon"
        matSuffix>
        {{ getIcon() }}
    </mat-icon>
    <div class="notification-message">{{ notificationMessage }}</div>
</div>
