export const enum RefresTokenState {
    INIT = 'INIT',
    REFRESHING = 'REFRESHING',
    REFRESHED = 'REFRESHED',
}

export const enum LoadingState {
    INIT = 'INIT',
    LOADING = 'LOADING',
    LOADED = 'LOADED',
}
export interface ErrorState {
    error: string;
}

export type RequestState = LoadingState | ErrorState;

export function getError(callState: RequestState): string | null { 
    if ((callState as ErrorState).error !== undefined) { 
        return (callState as ErrorState).error;
    } 
    return null;
}