import { GenericTypeSource } from '../models/generic-type.model';

export type enums = 'GenericTypeSource' | 'GenericTypeStatus' | 'OpportunityStatus' | 'UserListStates' | 'OpportunityRequestStatus' | 'ProjectStatus' | 'DataExportStatus';
export interface IEnumToObject {
    key: string;
    value: string;
}

export const getEnumByEnumValue = (enumToProcess: any, value: string) => {
    const key = Object.keys(enumToProcess).find((key) => enumToProcess[key] == value) || '';
    return enumToProcess[key];
};

export const enumToObjectsList = (input: any): IEnumToObject[] => {
    const result = Object.entries(input)
        .filter(([key, value]) => typeof value === 'string')
        .map(([key, value]) => {
            let enumValue: IEnumToObject;
            if (typeof value === 'string' || typeof value === 'string') {
                enumValue = { key, value: value.toString() };
            } else {
                enumValue = { key, value: '' };
            }

            return { key, value };
        }) as IEnumToObject[];

    return result;
};

export const getEnumTranslation = (enumValue: enums, key: string) => {
    interface ITranslations {
        [key: string]: {
            [key: string]: string;
        };
    }
    const translations: ITranslations = {
        GenericTypeSource: {
            profile: 'Профіль',
            opportunity: 'Тип допомоги',
            'opportunity-fulfill': 'Статус отримання',
            'opportunity-available-categories': 'Бенефеціари',
        },
        GenericTypeStatus: {
            new: 'Новий',
        },
        OpportunityStatus: {
            new: 'Нова',
            pending: 'Анонс',
            active: 'Активна',
            inactive: 'Закінчена',
            blocked: 'blocked',
            deleted: 'Видалена',
            recovered: 'recovered',
            approved: 'Прийом завершився',
            canceled: 'canceled',
        },
        UserListStates: {
            active: 'активні',
            inactive: 'неактивні',
            blocked: 'заблоковані',
        },
        OpportunityRequestStatus: {
            new: 'Нова',
            active: 'Активна',
            inactive: 'Неактивна',
            deleted: 'Видалена',
            approved: 'Схвалена',
            canceled: 'Відхилена',
        },
        ProjectStatus: {
            new: 'Новий',
            active: 'Активний',
            inactive: 'Неактивний',
        },
        DataExportStatus: {
            new: 'Новий',
            pending: 'Готується',
            approved: 'Готовий',
        },
    };

    return translations[enumValue][key];
};
