<div class="container container--auth">
    <div class="auth-logo">
        <app-logo></app-logo>
    </div>

    <div class="auth-content">
        <!-- <app-inner-dialog></app-inner-dialog> -->
        <app-loading *ngIf="isLoading"></app-loading>
        <router-outlet></router-outlet>
        <!-- <app-auth-form></app-auth-form> -->
        <!-- <h2>
            Портал соціальної<br />
            <span class="text-highlight">допомоги</span>
        </h2>
        
        <div>
            Для того, щоб користуватися системою,<br />
            увійдіть або зареєструйтесь
        </div>
        <div class="home-buttons">
            <button mat-stroked-button class="home-buttons__button">Вхід</button>
            <button mat-flat-button color="primary" class="home-buttons__button">Реєстрація</button>
        </div> -->
    </div>
</div>
