import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import * as AdminActions from 'src/app/modules//admin/store/admin.actions';
import { IEnumToObject } from 'src/app/modules/core/helpers/enum.helper';
import { isMobile } from 'src/app/modules/core/helpers/mobile.helper';
import { ProjectModel } from 'src/app/modules/core/models/project.model';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';
import { IUserProfile, UserStatus } from 'src/app/modules/core/models/user.model';

@Component({
    selector: 'app-admin-projects',
    templateUrl: './admin-projects.component.html',
    styleUrls: ['./admin-projects.component.scss'],
})
export class AdminProjectsComponent implements OnInit, OnDestroy {
    isLoading = false;
    error: string | null = '';
    displayedColumns: string[] = ['name', 'description', 'status'];
    displayedColumnsMobile: string[] = ['name'];
    dataSource: ProjectModel[] = [];

    private storeSubscription: Subscription = new Subscription();

    constructor(private store: Store<IApplicationState>, private dialog: MatDialog, private router: Router) {
        
    }

    ngOnInit(): void {
        this.storeSubscription = this.store.select('admin').subscribe((adminState) => {
            this.isLoading = adminState.requestState === LoadingState.LOADING;
            this.error = getError(adminState.requestState);

            this.dataSource = adminState.projects;
        });

        this.store.dispatch(AdminActions.projectsGet());
    }
    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }

    getColumns() {
        return isMobile() ? this.displayedColumnsMobile : this.displayedColumns;
    }

    onNavigateToProject(project: ProjectModel) {
        this.router.navigate(['admin', 'projects', project.baseProject.id]);
    }

    onAddProject() {
        this.router.navigate(['admin', 'projects', 'new']);
    }

    isMobile() {
        return isMobile();
    }
}
