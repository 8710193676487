import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

export enum CommonDialogIconType {
    'Success' = '/assets/images/icon-success.png',
    'Question' = '/assets/images/icon-quiestion.png',
}

export enum CommonDialogButtonType {
    'Button' = '0',
    'Text' = '1',
}

export interface CommonDialogData {
    icon: CommonDialogIconType;
    content: string | null;
    errors: string | null;
    header: string | null;
    buttonType: CommonDialogButtonType;
    navigate: string | null;
    actionText: string | null;
}

@Component({
    selector: 'app-common-dialog',
    templateUrl: './common-dialog.component.html',
    styleUrls: ['./common-dialog.component.scss'],
})
export class CommonDialogComponent {
    public defaultActionText = 'Закрити';
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: CommonDialogData,
        private router: Router
    ) {}

    buttonClickHandler() {
        if(this.data.navigate) {
            this.router.navigate([this.data.navigate]);
        }        
    }

    isTextButton() {
        return this.data.buttonType === CommonDialogButtonType.Text;
    }

    isButton() {
        return this.data.buttonType === CommonDialogButtonType.Button;
    }
}
