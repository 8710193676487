import { CloneHelper } from '../helpers/clone.helper';
import { EntityModel } from './entity.model';
import { OpportunityModel } from './opportunity.model';

export enum ProjectStatus {
    new = 'new',
    active = 'active',
    inactive = 'inactive',
}

export interface IPersonTypeDistribution {
    male: number;
    female: number;
    childMale: number;
    childFemale: number;
    seniorMale: number;
    seniorFemale: number;
}

export const EMPTY_PERSON_TYPE_DISTRIBUTION: IPersonTypeDistribution = {
    male: 0,
    female: 0,
    childMale: 0,
    childFemale: 0,
    seniorMale: 0,
    seniorFemale: 0,
};

export interface IProject extends IPersonTypeDistribution {
    id?: string;
    status: ProjectStatus;
    name: string;
    description: string;
}

export const EMPTY_PROJECT: IProject = {
    status: ProjectStatus.new,
    name: '',
    description: '',
    ...EMPTY_PERSON_TYPE_DISTRIBUTION,
};

export class ProjectModel extends EntityModel {
    private opportunities: OpportunityModel[] = [];

    baseProject: IProject = EMPTY_PROJECT;
    constructor(public Project: IProject = EMPTY_PROJECT) {
        super();

        this.baseProject = {
            id: Project.id,
            status: Project.status,
            name: Project.name,
            description: Project.description,
            male: Project.male,
            female: Project.female,
            childMale: Project.childMale,
            childFemale: Project.childFemale,
            seniorMale: Project.seniorMale,
            seniorFemale: Project.seniorFemale,
        };
    }

    getOpportunities() {
        return this.opportunities;
    }

    setOpportunities(opportunities: OpportunityModel[]) {
        this.opportunities = opportunities;
    }

    override clone(): ProjectModel {
        const baseProjectClone = CloneHelper.cloneData(this.baseProject);
        const clonedModel = new ProjectModel(baseProjectClone);
        if (this.opportunities.length) {
            clonedModel.setOpportunities(this.opportunities.map((o) => o.clone()));
        }

        return clonedModel;
    }
}
