import { Component, Input } from '@angular/core';
import { OpportunityRequestFulfillType, OpportunityRequestModel } from 'src/app/modules/core/models/opportunity-request.model';
import * as AdminActions from 'src/app/modules/admin/store/admin.actions';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { IApplicationState } from 'src/app/app.reducer';
import { CommonPromptComponent, CommonPromptIconType } from 'src/app/modules/shared/components/common-prompt/common-prompt.component';

@Component({
    selector: 'app-admin-oppotunity-request-operations',
    templateUrl: './admin-oppotunity-request-operations.component.html',
    styleUrls: ['./admin-oppotunity-request-operations.component.scss'],
})
export class AdminOppotunityRequestOperationsComponent {
    @Input() opportunityRequest = new OpportunityRequestModel();

    constructor(private store: Store<IApplicationState>, private dialog: MatDialog, private currentRoute: ActivatedRoute) {}

    onConfirmFulfillment() {
        this.dialog.open(CommonPromptComponent, {
            data: {
                icon: CommonPromptIconType.Question,
                header: 'Підтвердити видачу"?',
                content: '',
                data: {},
                callback: () => {
                    console.log('onConfirmFulfillment');
                    const opportunityRequestToUpdate = this.opportunityRequest.clone();
                    opportunityRequestToUpdate.confirmFulfill();

                    this.store.dispatch(AdminActions.opportunityRequestUpdate({ opportunityRequest: opportunityRequestToUpdate }));
                },
            },
        });
    }

    onConfirmPresence() {
        this.dialog.open(CommonPromptComponent, {
            data: {
                icon: CommonPromptIconType.Question,
                header: 'Підтвердити присутність"?',
                content: '',
                data: {},
                callback: () => {
                    console.log('onConfirmPresence');
                    const opportunityRequestToUpdate = this.opportunityRequest.clone();
                    opportunityRequestToUpdate.confirmPresence()

                    this.store.dispatch(AdminActions.opportunityRequestUpdate({ opportunityRequest: opportunityRequestToUpdate }));
                },
            },
        });
    }

    onCancelFulfillment() {
        this.dialog.open(CommonPromptComponent, {
            data: {
                icon: CommonPromptIconType.Question,
                header: 'Скасувати підтвердження видачі"?',
                content: '',
                data: {},
                callback: () => {
                    console.log('onCancelFulfillment');
                    const opportunityRequestToUpdate = this.opportunityRequest.clone();
                    opportunityRequestToUpdate.cancelFulfill();

                    this.store.dispatch(AdminActions.opportunityRequestUpdate({ opportunityRequest: opportunityRequestToUpdate }));
                },
            },
        });
    }

    onCancelPresence() {
        this.dialog.open(CommonPromptComponent, {
            data: {
                icon: CommonPromptIconType.Question,
                header: 'Скасувати підтвердження присутності"?',
                content: '',
                data: {},
                callback: () => {
                    console.log('onCancelPresence');
                    const opportunityRequestToUpdate = this.opportunityRequest.clone();
                    opportunityRequestToUpdate.cancelPresence();

                    this.store.dispatch(AdminActions.opportunityRequestUpdate({ opportunityRequest: opportunityRequestToUpdate }));
                },
            },
        });
    }
}
