import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-empty-table',
    templateUrl: './empty-table.component.html',
    styleUrls: ['./empty-table.component.scss'],
})
export class EmptyTableComponent {
    @Input() white = false;
}
