import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function validateDocument(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null;
        }

        // const hasUpperCase = /[A-Z]+/.test(value);
        const documentValid = /(^[A-Z]{2}[0-9]{6}$)|(^[А-Я]{2}[0-9]{6}$)|(^[1-5]?\-[А-Я]{2}[0-9]{6}$)|(^[0-9]{9}$)/.test(value);

        return !documentValid ? { documentInvalid: true } : null;
    };
}

export function validateTemporaryId(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) {
            return null;
        }
        // const hasUpperCase = /[A-Z]+/.test(value);
        const documentValid = /(^[0-9]{4}\-[0-9]{10}$)/.test(value);
        return !documentValid ? { documentInvalid: true } : null;
    };
}

export function validateZip(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null;
        }

        // const documentValid = /^\d{5}(?:[-\s]\d{4})?$/.test(value);
        const documentValid = /^[0-9]{5}$/.test(value);
        return !documentValid ? { zipInvalid: true } : null;
    };
}

export function validateMainBirthday(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return { dateInvalid: true };
        }

        const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
        return value > maxDate ?  { dateInvalid: true } : null;
    };
}
