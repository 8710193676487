<mat-sidenav-container class="mian-sidenav-container">
    <mat-sidenav
        #drawer
        [ngClass]="{ hidden: !user }"
        class="main-sidenav"
        fixedInViewport
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false">
        <mat-toolbar class="main-top-toolbar">
            <div class="main-top-toolbar__header">
                <div class="main-top-toolbar__logo">
                    <app-logo></app-logo>
                </div>
                <div class="main-top-toolbar__title">Меню</div>
            </div>
        </mat-toolbar>
        <mat-nav-list class="main-sidenav-menu">
            <a
                class="main-sidenav-menu-item-wrapper shepherd-events"
                mat-list-item
                (click)="isMobile() ? drawer.close() : null"
                [routerLink]="['/client/opportunities']"
                [disabled]="!isFulfilledUser()"
                routerLinkActive="main-sidenav-menu-item-wrapper__active-link">
                <span class="main-sidenav-menu-item">
                    <span class="main-sidenav-menu-item__hint-block">
                        <mat-icon class="main-sidenav-menu-item__icon main-sidenav-menu-item__icon--hint"> list </mat-icon>
                        <span> Події </span>
                    </span>
                    <span class="main-sidenav-menu-item__hint-block main-sidenav-menu-item__hint-block--right">
                        <mat-icon
                            class="main-sidenav-menu-item__icon main-sidenav-menu-item__icon--hint"
                            matTooltip="Щоб переглянути Події необхідно заповнити інфомацію про родину"
                            *ngIf="!isFulfilledUser()">
                            error
                        </mat-icon>
                    </span>
                </span>
            </a>
            <a
                class="main-sidenav-menu-item-wrapper shepherd-requests"
                mat-list-item
                (click)="isMobile() ? drawer.close() : null"
                [routerLink]="['/client/opportunity-requests']"
                [disabled]="!isFulfilledUser()"
                routerLinkActive="main-sidenav-menu-item-wrapper__active-link">
                <span class="main-sidenav-menu-item">
                    <span class="main-sidenav-menu-item__hint-block">
                        <mat-icon
                            class="main-sidenav-menu-item__icon main-sidenav-menu-item__icon--hint"
                            matTooltip="Щоб переглянути Події необхідно заповнити інфомацію про родину">
                            check_circle
                        </mat-icon>
                        <span> Заявки на події </span>
                    </span>
                    <span class="main-sidenav-menu-item__hint-block main-sidenav-menu-item__hint-block--right">
                        <mat-icon
                            class="main-sidenav-menu-item__icon main-sidenav-menu-item__icon--hint"
                            matTooltip="Щоб переглянути Події необхідно заповнити інфомацію про родину"
                            *ngIf="!isFulfilledUser()">
                            error
                        </mat-icon>
                        <span
                            class="main-sidenav-menu-item__opportunities-counter"
                            [matBadge]="opportunityRequests.length"
                            matBadgeColor="primary"
                            matBadgePosition="after"
                            matBadgeOverlap="true"
                            *ngIf="opportunityRequests.length">
                        </span>
                    </span>
                </span>
            </a>
            <a
                class="main-sidenav-menu-item-wrapper shepherd-settings"
                mat-list-item
                (click)="isMobile() ? drawer.close() : null"
                [routerLink]="['/client/settings']"
                routerLinkActive="main-sidenav-menu-item-wrapper__active-link">
                <span class="main-sidenav-menu-item">
                    <mat-icon
                        class="main-sidenav-menu-item__icon"
                        matTooltip="Щоб переглянути Події необхідно заповнити інфомацію про родину">
                        settings
                    </mat-icon>
                    Налаштування
                </span>
            </a>
        </mat-nav-list>
        <mat-toolbar
            *ngIf="isAdmin()"
            class="main-top-toolbar main-top-toolbar--small">
            <div class="main-top-toolbar__header">
                <div class="main-top-toolbar__title">Меню Адміністратора</div>
            </div>
        </mat-toolbar>
        <mat-nav-list
            *ngIf="isAdmin()"
            class="main-sidenav-menu">
            <a
                class="main-sidenav-menu-item-wrapper"
                mat-list-item
                [routerLink]="['/admin/opportunities']"
                routerLinkActive="main-sidenav-menu-item-wrapper__active-link">
                <span class="main-sidenav-menu-item">
                    <mat-icon
                        class="main-sidenav-menu-item__icon"
                        matTooltip="Щоб переглянути Події необхідно заповнити інфомацію про родину">
                        supervisor_account
                    </mat-icon>
                    Події
                </span>
            </a>
            <a
                class="main-sidenav-menu-item-wrapper"
                mat-list-item
                [routerLink]="['/admin/generic-types']"
                routerLinkActive="main-sidenav-menu-item-wrapper__active-link">
                <span class="main-sidenav-menu-item">
                    <mat-icon
                        class="main-sidenav-menu-item__icon"
                        matTooltip="Щоб переглянути Події необхідно заповнити інфомацію про родину">
                        event
                    </mat-icon>
                    Налаштування категорій
                </span>
            </a>
            <a
                class="main-sidenav-menu-item-wrapper"
                mat-list-item
                [routerLink]="['/admin/users']"
                routerLinkActive="main-sidenav-menu-item-wrapper__active-link">
                <span class="main-sidenav-menu-item">
                    <mat-icon class="main-sidenav-menu-item__icon"> account_circle </mat-icon>
                    Користувачі
                </span>
            </a>
            <a
                class="main-sidenav-menu-item-wrapper"
                mat-list-item
                [routerLink]="['/admin/projects']"
                routerLinkActive="main-sidenav-menu-item-wrapper__active-link">
                <span class="main-sidenav-menu-item">
                    <mat-icon class="main-sidenav-menu-item__icon"> local_florist </mat-icon>
                    Проєкти
                </span>
            </a>
            <a
                class="main-sidenav-menu-item-wrapper"
                mat-list-item
                [routerLink]="['/admin/data-export']"
                routerLinkActive="main-sidenav-menu-item-wrapper__active-link">
                <span class="main-sidenav-menu-item">
                    <mat-icon class="main-sidenav-menu-item__icon"> import_export </mat-icon>
                    Експорт
                </span>
            </a>
        </mat-nav-list>
        <app-logout></app-logout>
        <div
            *ngIf="isAdmin()"
            class="main-sidenav-version">
            {{ version() }}
        </div>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="{ 'side-panel__open': !user }">
        <mat-toolbar
            class="mobile-toolbar"
            *ngIf="isHandset$ | async">
            <div class="mobile-toolbar__logo">
                <app-logo></app-logo>
            </div>
            <div>
                <button
                    class="mobile-toolbar__button"
                    type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    (click)="drawer.toggle()">
                    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                </button>
            </div>

            <!-- <div *ngIf="user">
          
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" class="toolbar-container">

            </div> -->
        </mat-toolbar>

        <div>
            <ng-content></ng-content>
            <!-- Add Content Here -->
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
