import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';
import * as AuthActions from '../../store/auth.actions';

@Component({
    selector: 'app-auth-recover',
    templateUrl: './auth-recover.component.html',
    styleUrls: ['./auth-recover.component.scss'],
})
export class AuthRecoverComponent implements OnInit, OnDestroy {
    isLoading = false;
    error: string | null = '';
    hidePassword = true;
    private storeSubscription: Subscription = new Subscription();

    recoverPasswordForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
    });

    constructor(private fb: FormBuilder, private store: Store<IApplicationState>) {}

    onSubmit(): void {
        if (this.recoverPasswordForm.valid) {
            const email = this.recoverPasswordForm.value.email!;

            this.isLoading = true;
            this.store.dispatch(AuthActions.recoverPassword({ email }));
        }
    }
    ngOnInit(): void {
        this.storeSubscription = this.store.select('auth').subscribe((authState) => {
            this.isLoading = authState.requestState === LoadingState.LOADING;
            this.error = getError(authState.requestState);
        });
    }
    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }
}
