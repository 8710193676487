import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, map, shareReplay } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { UserModel } from 'src/app/modules/core/models/user.model';
import * as AdminActions from 'src/app/modules//admin/store/admin.actions';
import { MatDialog } from '@angular/material/dialog';
import { GenericTypeItemComponent } from '../generic-type-item/generic-type-item.component';
import { EMPTY_GENERIC_TYPE, GenericTypeModel, GenericTypeSource, GenericTypeStatus } from 'src/app/modules/core/models/generic-type.model';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isMobile } from 'src/app/modules/core/helpers/mobile.helper';
import { CommonPromptComponent, CommonPromptIconType } from 'src/app/modules/shared/components/common-prompt/common-prompt.component';

@Component({
    selector: 'app-admin-generic-types',
    templateUrl: './admin-generic-types.component.html',
    styleUrls: ['./admin-generic-types.component.scss'],
})
export class AdminGenericTypesComponent implements OnInit, OnDestroy {
    isLoading = false;
    error: string | null = '';
    displayedColumns: string[] = ['name', 'source', 'description', 'action'];
    displayedColumnsMobile: string[] = ['name', 'source', 'action'];
    dataSource: GenericTypeModel[] = [];

    private storeSubscription: Subscription = new Subscription();

    constructor(private store: Store<IApplicationState>, private dialog: MatDialog) {}

    ngOnInit(): void {
        this.storeSubscription = this.store.select('admin').subscribe((adminState) => {
            this.isLoading = adminState.requestState === LoadingState.LOADING;
            this.error = getError(adminState.requestState);

            this.dataSource = adminState.genericTypes;
            console.log('adminState: ', adminState);
            // this.user = authState.user;
        });

        this.store.dispatch(AdminActions.genericTypesGet());
    }
    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }

    getColumns() {
        return isMobile() ? this.displayedColumnsMobile : this.displayedColumns;
    }

    onAddType(genericType?: GenericTypeModel) {
        this.dialog.open(GenericTypeItemComponent, {
            data: {
                genericType: genericType ? genericType : new GenericTypeModel(),
                callback: this.onEditGenericType.bind(this),
            },
        });
    }

    onEditGenericType(genericType: GenericTypeModel) {
        console.log(genericType);
        this.dialog.closeAll();
        if (!genericType.baseType.id) {
            this.store.dispatch(AdminActions.genericTypesCreate({ genericType }));
        } else {
            this.store.dispatch(AdminActions.genericTypesUpdate({ genericType }));
        }
    }

    onDeleteHandler(genericType: GenericTypeModel) {
        this.dialog.open(CommonPromptComponent, {
            data: {
                icon: CommonPromptIconType.Question,
                header: 'Видалити тип?',
                data: genericType,
                callback: this.processDelete.bind(this),
            },
        });
    }

    processDelete(genericType: GenericTypeModel) {
        if (genericType) {
            this.store.dispatch(AdminActions.genericTypesDelete({ genericType }));
        }
    }
}
