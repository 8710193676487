import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { checkPasswords } from 'src/app/modules/core/validators/passwords.validator';
import * as AuthActions from '../../store/auth.actions';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';
import { MatDialog } from '@angular/material/dialog';
import { CommonDialogButtonType, CommonDialogComponent, CommonDialogIconType } from 'src/app/modules/shared/components/common-dialog/common-dialog.component';

@Component({
    selector: 'app-auth-registration',
    templateUrl: './auth-registration.component.html',
    styleUrls: ['./auth-registration.component.scss'],
})
export class AuthRegistrationComponent implements OnInit, OnDestroy {
    isLoading = false;
    error: string | null = '';

    hidePassword = true;
    hideRePassword = true;

    private storeSubscription: Subscription = new Subscription();

    registrationForm = this.fb.group(
        {
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            repassword: ['', [Validators.required, Validators.minLength(6)]],
        },
        {
            validators: [checkPasswords],
        }
    );

    constructor(private fb: FormBuilder, private store: Store<IApplicationState>, public dialog: MatDialog) {}

    onSubmit(): void {
        if (this.registrationForm.valid) {
            const email = this.registrationForm.value.email || '';
            const password = this.registrationForm.value.password || '';

            this.isLoading = true;
            this.store.dispatch(AuthActions.signUp({ email, password }));
        }
    }
    ngOnInit(): void {
        this.storeSubscription = this.store.select('auth').subscribe((authState) => {
            this.isLoading = authState.requestState === LoadingState.LOADING;
            this.error = getError(authState.requestState);
        });
    }

    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }
}
