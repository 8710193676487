import { createReducer, on } from '@ngrx/store';
import { LoadingState, RequestState } from '../../core/models/request-state.model';
import { IUserProfile } from '../../core/models/user.model';
import {
    clientFailed,
    getProfile,
    getProfileSuccess,
    opportunitiesGet,
    opportunitiesGetSuccess,
    opportunityRequestSubmit,
    opportunityRequestSuccess,
    opportunityGetOne,
    opportunityGetOneSuccess,
    opportunitiesRequestGet,
    opportunitiesRequestGetSuccess,
    opportunityRequestUpdateSuccess,
    opportunityRequestUpdate,
    initToggleMenu,
    cancelToggleMenu,
} from './client.actions';
import { EMPTY_OPPORTUNITY, OpportunityModel } from '../../core/models/opportunity.model';
import { OpportunityRequestModel } from '../../core/models/opportunity-request.model';
import { L } from '@angular/cdk/keycodes';

export interface IClientState {
    profile: IUserProfile | null;
    requestState: RequestState;
    redirectUrl: string;
    needToToggleMenu: boolean;
    opportunity: OpportunityModel | null;
    opportunities: OpportunityModel[];
    opportunitiesRequests: OpportunityRequestModel[];
}

const initialState: IClientState = {
    profile: null,
    requestState: LoadingState.INIT,
    redirectUrl: '',
    needToToggleMenu: false,
    opportunity: null,
    opportunities: [],
    opportunitiesRequests: []
};

export const clientReducer = createReducer(
    initialState,
    on(clientFailed, (state, props) => {
        return {
            ...state,
            requestState: { error: props.error },
        };
    }),
    on(getProfile, (state, props) => {
        return {
            ...state,
        };
    }),
    on(getProfileSuccess, (state, props) => {
        console.log('getProfileSuccess');
        return {
            ...state,
        };
    }),
    on(opportunitiesGet, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunitiesGetSuccess, (state, props) => {
        return {
            ...state,
            opportunities: props.opportunities,
            requestState: LoadingState.LOADED,
        };
    }),
    on(opportunityGetOne, (state) => {
        return {
            ...state,
            opportunity: new OpportunityModel(EMPTY_OPPORTUNITY),
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunityGetOneSuccess, (state, props) => {
        return {
            ...state,
            opportunity: props.opportunity,
            requestState: LoadingState.LOADED,
        };
    }),
    on(opportunityRequestSubmit, (state, props) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunityRequestSuccess, (state, props) => {
        return {
            ...state,
            opportunity: props.opportunity,
            requestState: LoadingState.LOADED,
        };
    }),
    on(opportunitiesRequestGet, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunitiesRequestGetSuccess, (state, props) => {
        return {
            ...state,
            opportunitiesRequests: props.opportunityRequests,
            requestState: LoadingState.LOADED,
        };
    }),
    on(opportunityRequestUpdate, (state) => {
        return {
            ...state,
            requestState: LoadingState.LOADING,
        };
    }),
    on(opportunityRequestUpdateSuccess, (state, props) => {
        return {
            ...state,
            opportunity: props.opportunity,
            requestState: LoadingState.LOADED,
        };
    }),
    on(initToggleMenu, (state) => {
        return {
            ...state,
            needToToggleMenu: true,
        };
    }),
    on(cancelToggleMenu, (state) => {
        return {
            ...state,
            needToToggleMenu: false,
        };
    }),
);
