import { Component, OnDestroy, OnInit } from '@angular/core';
import * as AdminActions from 'src/app/modules/admin/store/admin.actions';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IApplicationState } from 'src/app/app.reducer';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';
import { IUserProfile, EMPTY_PROFILE, IUserBase, EMPTY_BASE_USER, UserStatus, UserRole, UserModel } from 'src/app/modules/core/models/user.model';
import { Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { checkPasswords } from 'src/app/modules/core/validators/passwords.validator';
import { GenericTypeModel } from 'src/app/modules/core/models/generic-type.model';
import { CommonPromptComponent, CommonPromptIconType } from 'src/app/modules/shared/components/common-prompt/common-prompt.component';
import { OpportunityRequestModel } from 'src/app/modules/core/models/opportunity-request.model';
import { isMobile } from 'src/app/modules/core/helpers/mobile.helper';

@Component({
    selector: 'app-admin-user',
    templateUrl: './admin-user.component.html',
    styleUrls: ['./admin-user.component.scss'],
})
export class AdminUserComponent implements OnInit, OnDestroy {
    error: string | null = '';
    isLoading = false;
    userId = '';
    user: UserModel = new UserModel();
    profile: IUserProfile = EMPTY_PROFILE;
    hidePassword = true;
    private storeSubscription: Subscription = new Subscription();
    userForm: FormGroup;
    opportunityRequests: OpportunityRequestModel[] = [];
    opportunityRequestsColumns = ['username', 'attendance', 'fulfillment', 'suggestment', 'order', 'status'];
    opportunityRequestsColumnsMobile = ['username'];
    opportunityRequestsColumnsWithExpand: string[] = [...this.getColumns(), 'expand'];

    constructor(private router: Router, private store: Store<IApplicationState>, private dialog: MatDialog, private currentRoute: ActivatedRoute) {
        this.userForm = this.getUserFormGroup();
    }

    ngOnInit(): void {
        this.currentRoute.params.subscribe((currentParams) => {
            this.userId = currentParams['userId'];
            if (this.userId) {
                this.store.dispatch(AdminActions.userGet({ userId: this.userId }));
                this.store.dispatch(AdminActions.opportunityRequestsGetByUser({ userId: this.userId }));

                this.storeSubscription = this.store.select('admin').subscribe((adminState) => {
                    this.isLoading = adminState.requestState === LoadingState.LOADING;
                    this.error = getError(adminState.requestState);
                    if (!this.user.baseUser.id && adminState.user) {
                        this.user = adminState.user.clone();
                        this.profile = this.user.getProfile();
                        this.userForm = this.getUserFormGroup();
                    }

                    if (!this.opportunityRequests.length && adminState.userOpportunityRequests) {
                        this.opportunityRequests = adminState.userOpportunityRequests;
                    }
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.userId = '';
        this.user = new UserModel();
        this.profile = EMPTY_PROFILE;
        this.opportunityRequests = [];
        this.storeSubscription.unsubscribe();
    }

    onUserSubmit(): void {
        this.dialog.open(CommonPromptComponent, {
            data: {
                icon: CommonPromptIconType.Question,
                header: this.isNewUser() ? 'Створити користувача?' : 'Оновити пароль?',
                callback: this.processUpdateProfile.bind(this),
            },
        });
    }

    onSettingsUpdate(): void {
        if (!this.isNewUser()) {
            const { isActive, isAdmin, isUser, isMember } = this.userForm.value;
            const status = isActive ? UserStatus.active : UserStatus.blocked;
            const roles: UserRole[] = [];
            if (isAdmin) {
                roles.push(UserRole.admin);
            }
            if (isUser) {
                roles.push(UserRole.user);
            }
            if (isMember) {
                roles.push(UserRole.member);
            }
            this.store.dispatch(AdminActions.userUpdate({ userId: this.userId, status, roles }));
        }
    }

    getUserFormGroup(): FormGroup {
        console.log('getUserFormGroup');

        const isUser = this.user.baseUser.roles.some((r) => r === UserRole.user);
        const isAdmin = this.user.baseUser.roles.some((r) => r === UserRole.admin);
        const isMember = this.user.baseUser.roles.some((r) => r === UserRole.member);

        const opportunityRequestsFilterFormGroup = new FormGroup({
            email: new FormControl(this.profile.email || 'enter email', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required, Validators.minLength(6)]),
            isActive: new FormControl(this.user.baseUser.status === UserStatus.active),
            isUser: new FormControl(isUser),
            isAdmin: new FormControl(isAdmin),
            isMember: new FormControl(isMember),
        });

        return opportunityRequestsFilterFormGroup;
    }

    getUsersUrl() {
        const url = `/admin/users`;
        return url;
    }

    getUserTitle() {
        let result = '';

        if (this.isNewUser()) {
            result = 'Створення нового користувача';
        } else {
            result = `Користувач ${this.profile.firstName || '(Ще не заповнив профіль)'} ${this.profile.lastName || ''}`;
        }

        return result;
    }

    getButtonUpdateUserTitle() {
        let result = '';

        if (this.isNewUser()) {
            result = 'Створити користувача';
        } else {
            result = 'Оновити пароль';
        }

        return result;
    }

    getUsersTitle() {
        const result = `Користувачі`;
        return result;
    }

    isNewUser() {
        return !this.profile.id;
    }

    processUpdateProfile() {
        console.log('user', this.userForm.value);
        const { email, password } = this.userForm.value;
        if (this.isNewUser()) {
            this.store.dispatch(AdminActions.userCreate({ email, password }));
        } else {
            this.store.dispatch(AdminActions.userResetPassword({ userId: this.userId, password }));
        }
    }

    updateProfileEventHandler(profile: IUserProfile) {
        console.log(profile);
        if (!this.isNewUser()) {
            this.profile = profile;
            this.store.dispatch(AdminActions.userProfileUpdate({ profile }));
        }
    }

    navigateToOpportunityRequestPage(opportunityRequest: OpportunityRequestModel) {
        this.router.navigate(['admin', 'opportunity', 'request', opportunityRequest.getId()]);
    }

    getColumns() {
        return isMobile() ? this.opportunityRequestsColumnsMobile : this.opportunityRequestsColumns;
    }

    isMobile() {
        return isMobile();
    }
}
