import { Component, Input } from '@angular/core';

export type NorificationMessageType = 'success' | 'warning' | 'error';

@Component({
    selector: 'app-notification-message',
    templateUrl: './notification-message.component.html',
    styleUrls: ['./notification-message.component.scss'],
})
export class NotificationMessageComponent {
    @Input() notificationMessage: string = '';
    @Input() notificationType: NorificationMessageType = 'success';

    getNotificateMessageWrapperClassModifier() {
        return {
            [`notification-message-wrapper--${this.notificationType}`]: true,
        };
    }

    getIcon(){
        if(this.notificationType === 'error') {
            return 'error';
        }
        if(this.notificationType === 'success') {
            return 'check_circle';
        }
        if(this.notificationType === 'warning') {
            return 'warning';
        }
        return 'warning';
    }

    // isError(){
    //     return this.notificationType === NorificationMessageType.Error;
    // }

    // isSuccess(){
    //     return this.notificationType === NorificationMessageType.Success;
    // }

    // isWarning(){
    //     return this.notificationType === NorificationMessageType.Warning;
    // }
}
