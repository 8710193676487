import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { LoadingState } from 'src/app/modules/core/models/request-state.model';
import { RouteDataModel } from 'src/app/modules/core/models/route-data.model';
import { UserModel } from 'src/app/modules/core/models/user.model';

@Component({
    selector: 'app-admin-main',
    templateUrl: './admin-main.component.html',
    styleUrls: ['./admin-main.component.scss'],
})
export class AdminMainComponent implements OnInit, OnDestroy {
    isLoading = false;
    badgeText = '';
    user: null | UserModel = null;
    storeSubscription = new Subscription();
    routeSubscription = new Subscription();
    routeDataSubscription = new Subscription();
    currentData: RouteDataModel = new RouteDataModel();

    constructor(private store: Store<IApplicationState>, private cdr: ChangeDetectorRef, private currentRoute: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.storeSubscription = this.store.select('admin').subscribe((state) => {
            this.isLoading = state.requestState === LoadingState.LOADING;
            this.cdr.detectChanges();
        });

        this.routeDataSubscription = this.currentRoute.data.subscribe((data) => {
            this.currentData = new RouteDataModel((data as RouteDataModel).baseRoute);
        });

        this.routeSubscription = this.router.events.subscribe((event) => {
            if (event instanceof ActivationStart) {
                this.currentData = new RouteDataModel((event.snapshot.data as RouteDataModel).baseRoute);
            }
        });
    }

    isAdmin() {
        // const user = this.user;
        // if (user) {
        //     const result = user && user.getToken() && user.personalData && user.personalData.roles && user.personalData.roles.some((r) => r === 'admin');

        //     return result;
        // } else {
        //     return false;
        // }
        return true;
    }

    isFulfilledUser() {
        // const user = this.user;
        // if(user) {
        //     const result =
        //         user &&
        //         user.getToken() &&
        //         user.personalData &&
        //         user.personalData.storageKey;
        //     return  result;
        // } else {
        //     return false;
        // }
        return true;
    }

    onLogotHandler() {
        // this.store.dispatch(AuthActions.logout());
    }

    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
        this.routeDataSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }
}
