<app-navigation>
    <div class="container container--admin">
        <!-- <div class=""> -->
        <app-loading *ngIf="isLoading"></app-loading>
        <app-top-banner
            [title]="currentData.baseRoute.title"
            [subtitle]="currentData.baseRoute.description"></app-top-banner>
        <div class="admin-outlet">
            <router-outlet></router-outlet>
        </div>
        <!-- Add Content Here -->
    </div>
</app-navigation>
