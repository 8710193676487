<div class="auth-activate-account-container">
    <div class="auth-activate-account">
        <div class="auth-activate-account-header">
            <h3>Активація акаунту</h3>
            <span
                class="text-grey"
                *ngIf="!error">
                Ваш акаунт успішно активовано. Тепер ви можете увійти до свого акаунту!
            </span>
        </div>

        <div class="auth-activate-account-buttons">
            <app-error-message
                *ngIf="error"
                [errorMessage]="error"></app-error-message>
            <a
                [routerLink]="['/auth']"
                class="text-button">
                Повернутись на сторінку для входу
            </a>
        </div>
    </div>
</div>
