import { Observable, catchError, startWith } from 'rxjs';

export const switchMapCatchErrorOperator =
    (errorAction: (error: any) => any) =>
    <T>(source: Observable<T>) =>
        source.pipe(
            catchError((error, innerSource) =>
                innerSource.pipe(startWith(errorAction(error)))
            )
        );
