export class DateHelper {
    static GetDate(value: string | Date) {
        let newDate = new Date();
        if (value instanceof Date) {
            newDate = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0));
        } else {
            const date = new Date(value);
            newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
        }
        return newDate;
    }
}
