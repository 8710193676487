import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, Observable, map, shareReplay } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { OpportunityRequestModel, OpportunityRequestStatus } from 'src/app/modules/core/models/opportunity-request.model';
import { LoadingState } from 'src/app/modules/core/models/request-state.model';
import { EMPTY_BASE_USER, UserModel } from 'src/app/modules/core/models/user.model';
import * as ClientActions from 'src/app/modules/client/store/client.actions';
import { OpportunityStatus } from 'src/app/modules/core/models/opportunity.model';
import { MatDrawer } from '@angular/material/sidenav';
import { isMobile } from 'src/app/modules/core/helpers/mobile.helper';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
    isLoading = false;
    badgeText = '';
    user: UserModel = new UserModel(EMPTY_BASE_USER);
    storeSubscription = new Subscription();
    storeUserSubscription = new Subscription();
    storeOpportunitiesSubscription = new Subscription();
    routeSubscription = new Subscription();
    opportunityRequests: OpportunityRequestModel[] = [];
    @ViewChild('drawer') private drawer!: MatDrawer;

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
        map((result) => result.matches),
        shareReplay()
    );

    constructor(private breakpointObserver: BreakpointObserver, private store: Store<IApplicationState>) {}

    ngOnInit(): void {
        setTimeout(() => {
            this.storeSubscription = this.store.select('auth').subscribe((state) => {
                this.isLoading = state.requestState === LoadingState.LOADING;
            });
        }, 100);
        this.storeUserSubscription = this.store.select('auth').subscribe((authState) => {
            const currentUser = authState.user;
            if (currentUser && currentUser.getToken()) {
                this.user = currentUser;
            }
        });

        this.store.dispatch(ClientActions.opportunitiesRequestGet());
        this.storeOpportunitiesSubscription = this.store.select('client').subscribe((clientState) => {
            this.opportunityRequests = clientState.opportunitiesRequests.filter((or) => {
                return (
                    (or.baseRequest.status === OpportunityRequestStatus.approved || or.baseRequest.status === OpportunityRequestStatus.new) &&
                    or.getOpportunity()?.status !== OpportunityStatus.inactive
                );
            });
            if (clientState.needToToggleMenu) {
                this.drawer.toggle();
                this.store.dispatch(ClientActions.cancelToggleMenu());
            }
        });
    }

    isAdmin() {
        return this.user.baseUser.id && this.user.isAdmin();
    }

    isFulfilledUser() {
        return this.user.baseUser.id && this.user.isProfileFulfilled();
    }

    ngOnDestroy(): void {
        this.storeOpportunitiesSubscription.unsubscribe();
        this.storeUserSubscription.unsubscribe();
        this.storeSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }

    isMobile(){
        return isMobile();
    }

    version(){
        return environment.version;
    }
}
