import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IApplicationState } from './app.reducer';
import * as AuthActions from './modules/auth/store/auth.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    storeSubscription = new Subscription();

    title = 'IAC ISHR';

    constructor(
        private store: Store<IApplicationState>,
    ) {}
    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.store.dispatch(AuthActions.signInAuto());
    }
}
