import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

export enum CommonPromptIconType {
    'Success' = '/assets/images/icon-success.png',
    'Question' = '/assets/images/icon-quiestion.png',
}

export enum CommonPromptButtonType {
    'Button' = '0',
    'Text' = '1',
}

export interface CommonPromptData {
    icon: CommonPromptIconType;
    content: string | null;
    header: string | null;
    data: any | null;
    actionText: string | null;
    cancelCallback?: () => {};
    callback: (value: any) => {};
}

@Component({
    selector: 'app-common-prompt',
    templateUrl: './common-prompt.component.html',
    styleUrls: ['./common-prompt.component.scss'],
})
export class CommonPromptComponent {
    public defaultActionText = 'Продовжити';
    constructor(@Inject(MAT_DIALOG_DATA) public data: CommonPromptData, private router: Router) {}

    buttonCancelClickHandler() {
        if (this.data.cancelCallback) {
            this.data.cancelCallback();
        }
    }

    buttonProceedClickHandler() {
        this.data.callback(this.data.data);
    }
}
