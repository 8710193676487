import { CloneHelper } from '../helpers/clone.helper';
import { EntityModel } from './entity.model';

export interface IRouteData {
    title: string;
    description: string;
}

export const EMPTY_ROUTE_DATA: IRouteData = {
    title: '',
    description: '',
};

export class RouteDataModel extends EntityModel {
    baseRoute: IRouteData = EMPTY_ROUTE_DATA;
    constructor(route: IRouteData = EMPTY_ROUTE_DATA) {
        super();

        this.baseRoute = {
            title: route.title,
            description: route.description,
        };
    }

    override clone(): RouteDataModel {
        const baseRouteClone = CloneHelper.cloneData(this.baseRoute);
        const clonedModel = new RouteDataModel(baseRouteClone);
        return clonedModel;
    }
}
