import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';
import { checkPasswords } from 'src/app/modules/core/validators/passwords.validator';
import * as AuthActions from '../../store/auth.actions';

@Component({
    selector: 'app-auth-login',
    templateUrl: './auth-login.component.html',
    styleUrls: ['./auth-login.component.scss'],
})
export class AuthLoginComponent implements OnInit, OnDestroy {
    isLoading = false;
    error: string | null = '';
    hidePassword = true;
    private storeSubscription: Subscription = new Subscription();

    loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
    });

    constructor(private fb: FormBuilder, private store: Store<IApplicationState>) {}

    onSubmit(): void {
        if (this.loginForm.valid) {
            const email = this.loginForm.value.email!;
            const password = this.loginForm.value.password!;

            this.isLoading = true;
            this.store.dispatch(AuthActions.signIn({ email, password }));
        } else {
            console.log('RegistrationComponent valid');
        }
    }
    ngOnInit(): void {
        this.storeSubscription = this.store.select('auth').subscribe((authState) => {
            this.isLoading = authState.requestState === LoadingState.LOADING;
            this.error = getError(authState.requestState);
        });
    }
    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }
}
