import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { OpportunityModel, OpportunityStatus } from 'src/app/modules/core/models/opportunity.model';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';
import * as ClientActions from '../../store/client.actions';

@Component({
    selector: 'app-client-opprotunities',
    templateUrl: './client-opprotunities.component.html',
    styleUrls: ['./client-opprotunities.component.scss'],
})
export class ClientOpprotunitiesComponent implements OnInit, OnDestroy {
    isLoading = false;
    error: string | null = '';
    dataSource: OpportunityModel[] = [];

    private storeSubscription: Subscription = new Subscription();

    constructor(private store: Store<IApplicationState>, private dialog: MatDialog) {}

    ngOnInit(): void {
        this.storeSubscription = this.store.select('client').subscribe((clientState) => {
            this.isLoading = clientState.requestState === LoadingState.LOADING;
            this.error = getError(clientState.requestState);
            this.dataSource = clientState.opportunities;
        });
        this.store.dispatch(ClientActions.opportunitiesGet());
        this.store.dispatch(ClientActions.checkTutorial());
    }
    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }

    getActiveOpportnuties() {
        return this.dataSource.filter(
            (opp) =>
                opp.baseOpportunity.status === OpportunityStatus.active ||
                opp.baseOpportunity.status === OpportunityStatus.approved ||
                opp.baseOpportunity.status === OpportunityStatus.new ||
                opp.baseOpportunity.status === OpportunityStatus.pending
        );
    }

    getInactiveOpportnuties() {
        return this.dataSource.filter((opp) => opp.baseOpportunity.status === OpportunityStatus.inactive);
    }
}
