import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import * as AdminActions from 'src/app/modules//admin/store/admin.actions';
import { isMobile } from 'src/app/modules/core/helpers/mobile.helper';
import { DataExportModel, DataExportStatus } from 'src/app/modules/core/models/data-export.model';
import { EMPTY_OPPORTUNITY, OpportunityModel } from 'src/app/modules/core/models/opportunity.model';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';
import { CommonPromptComponent, CommonPromptIconType } from 'src/app/modules/shared/components/common-prompt/common-prompt.component';

@Component({
    selector: 'app-admin-data-export',
    templateUrl: './admin-data-export.component.html',
    styleUrls: ['./admin-data-export.component.scss'],
})
export class AdminDataExportComponent implements OnInit, OnDestroy {
    isLoading = false;
    error: string | null = '';
    displayedColumns: string[] = ['createdAt', 'status', 'action'];
    displayedColumnsMobile: string[] = ['createdAt', 'status', 'action'];
    dataSource: DataExportModel[] = [];
    opportunities: OpportunityModel[] = [];
    selectedOpportunityId = '';

    private storeSubscription: Subscription = new Subscription();

    constructor(private store: Store<IApplicationState>, private dialog: MatDialog) {}

    ngOnInit(): void {
        this.storeSubscription = this.store.select('admin').subscribe((adminState) => {
            this.isLoading = adminState.requestState === LoadingState.LOADING;
            this.error = getError(adminState.requestState);

            this.dataSource = adminState.dataExports;
            console.log('adminState: ', adminState);

            if (!this.opportunities.length && adminState.opportunities.length) {
                this.opportunities = [
                    new OpportunityModel({
                        ...EMPTY_OPPORTUNITY,
                        name: 'Всі',
                        id: '',
                    }),
                    ...adminState.opportunities.map((p) => p.clone()),
                ];
                this.selectedOpportunityId = '';
            }
        });

        this.store.dispatch(AdminActions.opportunitiesGet());
        this.store.dispatch(AdminActions.dataExportsGet());
    }
    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }

    getColumns() {
        return isMobile() ? this.displayedColumnsMobile : this.displayedColumns;
    }

    onAddType(dataExport?: DataExportModel) {
        if (!dataExport) {
            this.store.dispatch(AdminActions.dataExportGenerate({ opportunityId: this.selectedOpportunityId }));
        } else if (dataExport.baseExport.status === DataExportStatus.approved) {
            const dataExportId = dataExport.baseExport.id || '';
            this.store.dispatch(AdminActions.dataExportGet({ dataExportId }));
        }
    }

    onDeleteHandler(dataExport: DataExportModel) {
        this.dialog.open(CommonPromptComponent, {
            data: {
                icon: CommonPromptIconType.Question,
                header: 'Видалити звіт?',
                data: dataExport,
                callback: this.processDelete.bind(this),
            },
        });
    }

    processDelete(dataExport: DataExportModel) {
        const dataExportId = dataExport.baseExport.id || '';
        if (dataExport) {
            this.store.dispatch(AdminActions.dataExportDelete({ dataExportId }));
        }
        console.log('Deleted');
    }
}
