import { Component, Input } from '@angular/core';

export enum TopBannerType {
    'Children' = '/assets/images/top-banner-young.png',
    'Young' = '/assets/images/top-banner-children.png',
    'Goods' = '/assets/images/top-banner-goods.png',
}

@Component({
    selector: 'app-top-banner',
    templateUrl: './top-banner.component.html',
    styleUrls: ['./top-banner.component.scss'],
})
export class TopBannerComponent {
    @Input() icon: TopBannerType = TopBannerType.Children;
    @Input() title = '';
    @Input() subtitle = '';

    isChildren() {
        return this.icon === TopBannerType.Children;
    }

    isYoung() {
        return this.icon === TopBannerType.Young;
    }

    isGoods() {
        return this.icon === TopBannerType.Goods;
    }
}
