import { CloneHelper } from '../helpers/clone.helper';
import { EntityModel } from './entity.model';

export enum DataExportStatus {
    new = 'new',
    pending = 'pending',
    approved = 'approved',
}

export interface IDataExport {
    id?: string;
    status: DataExportStatus;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export const EMPTY_DATA_EXPORT: IDataExport = {
    status: DataExportStatus.new,
};

export class DataExportModel extends EntityModel {
    baseExport: IDataExport = EMPTY_DATA_EXPORT;
    constructor(public dataExport: IDataExport = EMPTY_DATA_EXPORT) {
        super();

        this.baseExport = {
            id: dataExport.id,
            status: dataExport.status,
            createdAt: dataExport.createdAt,
            updatedAt: dataExport.updatedAt,
        };
    }

    override clone(): DataExportModel {
        const baseTypeClone = CloneHelper.cloneData(this.baseExport);
        const clonedModel = new DataExportModel(baseTypeClone);
        return clonedModel;
    }
}
