import { Component, Input } from '@angular/core';

export interface IBreadcrumpRootItem {
    text: string;
    href: string;
    type?: string;
}

@Component({
    selector: 'app-breadcrumps',
    templateUrl: './breadcrumps.component.html',
    styleUrls: ['./breadcrumps.component.scss'],
})
export class BreadcrumpsComponent {
    @Input() rootItems: IBreadcrumpRootItem[] = [];
    @Input() currentItem: string = '';

    getQueryParams(item: IBreadcrumpRootItem) {
        if (item.type) {
            return { type: item.type };
        } else {
            return {};
        }
    }
}
