<div class="client-home container container--client">
    <h2 class="client-home__title"></h2>
    <h3>Тепер трішки пояснимо, як це працює:</h3>
    <div
        class="client-home__content"
        fxLayout="row"
        fxLayout.xs="column">
        <div class="client-home__left">
            <div class="client-home__image"></div>
            <div class="client-home__buttons">
                <button
                    mat-flat-button
                    color="primary"
                    class="client-home__button"
                    (click)="navigateToOppotunities()">
                    Події
                </button>
                <button
                    mat-flat-button
                    color="primary"
                    class="client-home__button"
                    (click)="navigateToSettings()">
                    Налаштування
                </button>
            </div>
            <div class="client-home__buttons">
                <button
                    mat-stroked-button
                    class="client-home__button client-home__button--wide"
                    (click)="launchTutorial()">
                    Переглянути поміник
                </button>
            </div>
        </div>
        <div class="client-home__right">
            <h4 class="client-home__title">Саме тут Ви зможете подати заявку на отримання гуманітарної допомоги та відслідкувати її статус.</h4>
            <ol>
                <li>Для початку потрібно пройти реєстрацію на нашій платформі, уважно вказавши всі необхідні дані у розділі <b>Налаштування.</b></li>
                <li>Слідкувати за відкритими реєстраціями на розповсюдження гуманітарної допомоги та подати заявку Ви можете у розділі <b>Події.</b></li>
                <li>
                    Зібрані заявки передаються на алгоритм (автоматизований розподіл), який на основі обрахунків видає результат. Вибір отримувачів гуманітарної допомоги відбувається після
                    вираховування багатьох факторів, у тому числі, але не виключно:
                    <ul>
                        <li>Склад сімʼї та кількість її членів;</li>
                        <li>Місцезнаходження особи/сімʼї;</li>
                        <li>Категорії (ВПО, люди похилого віку, люди з інвалідністю і т.д.);</li>
                        <li>Наявні приоритети категорій.</li>
                    </ul>
                </li>
                <li>
                    Статус поданої заявки Ви можете відслідкувати у розділі <b>Заявки на події</b>. Звертаємо Вашу увагу, що прийняття заявки означає тільки те, що ми її отримали. Лише після її
                    схвалення адміністратором (статус заявки <b>Схвалено</b>) дає можливість отримати допомогу і Ви побачите інформацію про дату, час та місце розповсюдження гуманітарної допомоги.
                </li>
            </ol>
            <p>У разі виникнення технічних питань Ви можете звернутися за електронною адресою iac.ishr@gmail.com.</p>
            <p>Залишайся добрим!</p>
            <p>Будь з IAC ISHR💙</p>
        </div>
    </div>
</div>
