<div class="opportunity-request-suggestment-wrapper">
    <form
        [formGroup]="suggestionForm"
        novalidate
        (ngSubmit)="onSubmit()">
        <div class="opportunity-request-suggestment">
            <div class="opportunity-request-suggestment__icon">
                <img
                    [src]="data.icon"
                    alt="success" />
            </div>
            <div class="opportunity-request-suggestment__header">
                <h3>Редагувати Рекомендацію</h3>
            </div>
            <div class="opportunity-request-suggestment__content">
                <div class="form-row">
                    <mat-checkbox formControlName="suggested">Рекомендовано</mat-checkbox>
                    <input
                        formControlName="demand"
                        class="full-width"
                        type="hidden" />
                    <input
                        formControlName="opportunityRequest"
                        class="full-width"
                        type="hidden" />
                    <input
                        formControlName="id"
                        class="full-width"
                        type="hidden" />
                </div>
                <div class="form-row">
                    <mat-form-field
                        [floatLabel]="'always'"
                        appearance="fill"
                        class="opportunity-request-suggestment__input">
                        <mat-label>Видати наборів</mat-label>
                        <input
                            matInput
                            placeholder="Кількість наборів щоб видати"
                            formControlName="solution"
                            type="number" />
                        <mat-icon matSuffix>supervisor_account</mat-icon>
                        <mat-error *ngIf="suggestionForm.controls['solution'].hasError('required')"> Кількість <strong>Обов'язкова</strong> </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-row">
                    <mat-form-field
                        [floatLabel]="'always'"
                        appearance="fill"
                        class="opportunity-request-suggestment__input">
                        <mat-label>Приорітет</mat-label>
                        <input
                            matInput
                            placeholder="Приорітет"
                            formControlName="priority"
                            type="number" />
                        <mat-icon matSuffix>supervisor_account</mat-icon>
                        <mat-error *ngIf="suggestionForm.controls['priority'].hasError('required')"> Приорітет <strong>Обов'язковий</strong> </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div
                class="form-row"
                fxLayout="row"
                fxLayoutAlign="center">
                <button
                    mat-stroked-button
                    class="opportunity-request-suggestment__buttons button-grey"
                    mat-dialog-close>
                    Скасувати
                </button>
                <button
                    mat-stroked-button
                    color="primary"
                    class="opportunity-request-suggestment__buttons button-light-blue"
                    mat-dialog-close
                    type="submit"
                    [disabled]="!suggestionForm.valid">
                    Продовжити
                </button>
            </div>
        </div>
    </form>
</div>
