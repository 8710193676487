import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';
import * as AuthActions from '../../store/auth.actions';
import { checkPasswords } from 'src/app/modules/core/validators/passwords.validator';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-auth-reset-password',
    templateUrl: './auth-reset-password.component.html',
    styleUrls: ['./auth-reset-password.component.scss'],
})
export class AuthResetPasswordComponent implements OnInit, OnDestroy {
    token = '';
    isLoading = false;
    error: string | null = '';
    hidePassword = true;
    hideRePassword = true;
    private storeSubscription: Subscription = new Subscription();
    private routeSubscription: Subscription = new Subscription();

    resetPasswordForm = this.fb.group(
        {
            password: ['', [Validators.required, Validators.minLength(6)]],
            repassword: ['', [Validators.required, Validators.minLength(6)]],
        },
        {
            validators: [checkPasswords],
        }
    );

    constructor(private currentRoute: ActivatedRoute, private fb: FormBuilder, private store: Store<IApplicationState>) {}

    onSubmit(): void {
        if (this.resetPasswordForm.valid) {
            const password = this.resetPasswordForm.value.password!;
            const repassword = this.resetPasswordForm.value.repassword!;

            this.isLoading = true;
            this.store.dispatch(AuthActions.recoverPasswordReset({ password, repassword, token: this.token }));
        } else {
            console.log('RegistrationComponent valid');
        }
    }
    ngOnInit(): void {
        this.storeSubscription = this.store.select('auth').subscribe((authState) => {
            this.isLoading = authState.requestState === LoadingState.LOADING;
            this.error = getError(authState.requestState);
        });

        this.routeSubscription = this.currentRoute.params.subscribe((currentParams) => {
            this.token = currentParams['token'];
        });
    }
    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }
}
