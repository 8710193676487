import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeMainComponent } from './modules/home/components/home-main/home-main.component';
import { AuthMainComponent } from './modules/auth/components/auth-main/auth-main.component';
import { AuthFormComponent } from './modules/auth/components/auth-form/auth-form.component';
import { AuthRegistrationComponent } from './modules/auth/components/auth-registration/auth-registration.component';
import { AuthRecoverComponent } from './modules/auth/components/auth-recover/auth-recover.component';
import { ClientMainComponent } from './modules/client/components/client-main/client-main.component';
import { ClientOpprotunitiesComponent } from './modules/client/components/client-opprotunities/client-opprotunities.component';
import { ClientSettingsComponent } from './modules/client/components/client-settings/client-settings.component';
import { AuthActivateAccountComponent } from './modules/auth/components/auth-activate-account/auth-activate-account.component';
import { AuthResetPasswordComponent } from './modules/auth/components/auth-reset-password/auth-reset-password.component';
import { AdminMainComponent } from './modules/admin/components/admin-main/admin-main.component';
import { AdminOpportunityComponent } from './modules/admin/components/admin-opportunity/admin-opportunity.component';
import { ClientHomeComponent } from './modules/client/components/client-home/client-home.component';
import { AdminGenericTypesComponent } from './modules/admin/components/admin-generic-types/admin-generic-types.component';
import { AdminOpportunitiesComponent } from './modules/admin/components/admin-opportunities/admin-opportunities.component';
import { ClientOpportunityComponent } from './modules/client/components/client-opportunity/client-opportunity.component';
import { AdminUsersComponent } from './modules/admin/components/admin-users/admin-users.component';
import { AdminUserComponent } from './modules/admin/components/admin-user/admin-user.component';
import { AdminOpportunityRequestComponent } from './modules/admin/components/admin-opportunity-request/admin-opportunity-request.component';
import { ClientOpportunityRequestsComponent } from './modules/client/components/client-opportunity-requests/client-opportunity-requests.component';
import { AdminProjectComponent } from './modules/admin/components/admin-project/admin-project.component';
import { AdminProjectsComponent } from './modules/admin/components/admin-projects/admin-projects.component';
import { RouteDataModel } from './modules/core/models/route-data.model';
import { AdminAuthGuard } from './modules/core/guards/admin.guard';
import { UserAuthGuard } from './modules/core/guards/user.guard';
import { HomeMaintenanceComponent } from './modules/home/components/home-maintenance/home-maintenance.component';
import { MaintanceGuard } from './modules/core/guards/maintance.guard';
import { AdminDataExportComponent } from './modules/admin/components/admin-data-export/admin-data-export.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: HomeMainComponent,
        canActivate: [MaintanceGuard],
        data: new RouteDataModel({ title: 'Головна', description: 'Отримати допомогу легко! Звертайтесь ♡' }),
    },
    {
        path: 'maintenance',
        pathMatch: 'full',
        component: HomeMaintenanceComponent,
        data: new RouteDataModel({ title: 'Оновлення сайту', description: 'Ми оновлюємо сайт. Завітайте, будь ласка, пізніше.' }),
    },
    {
        path: 'auth',
        component: AuthMainComponent,
        canActivate: [MaintanceGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: AuthFormComponent,
            },
            {
                path: 'recover',
                component: AuthRecoverComponent,
            },
            {
                path: 'activate/:token',
                component: AuthActivateAccountComponent,
            },
            {
                path: 'reset/:token',
                component: AuthResetPasswordComponent,
            },
        ],
    },
    {
        path: 'client',
        component: ClientMainComponent,
        data: new RouteDataModel({ title: 'Головна', description: 'Отримати допомогу легко! Звертайтесь ♡' }),
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: ClientHomeComponent,
                data: new RouteDataModel({ title: 'Головна', description: 'Отримати допомогу легко! Звертайтесь ♡' }),
                canActivate: [UserAuthGuard]
            },
            {
                path: 'opportunities',
                component: ClientOpprotunitiesComponent,
                data: new RouteDataModel({ title: 'Події', description: 'Тут можна ознайомитися зі списком подій' }),
                canActivate: [UserAuthGuard]
            },
            {
                path: 'opportunity-requests',
                component: ClientOpportunityRequestsComponent,
                data: new RouteDataModel({ title: 'Заявки', description: 'Тут переглянути подані заявки' }),
                canActivate: [UserAuthGuard]
            },
            {
                path: 'opportunity/:opportunityId',
                component: ClientOpportunityComponent,
                data: new RouteDataModel({ title: 'Заявка', description: 'Переглянути деталі заявки та події' }),
                canActivate: [UserAuthGuard]
            },
            {
                path: 'settings',
                component: ClientSettingsComponent,
                data: new RouteDataModel({ title: 'Налаштування', description: 'Необхідно заповнити профіл перед тим как переглянути події' }),
            },
        ],
    },
    {
        path: 'admin',
        component: AdminMainComponent,
        canActivate: [AdminAuthGuard],
        data: new RouteDataModel({ title: 'Головна', description: 'Сторінка адміна 😙' }),
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: AdminOpportunityComponent,
                data: new RouteDataModel({ title: 'Головна', description: 'Сторінка адміна 😙' }),
            },
            {
                path: 'opportunity/request/:opportunityRequestId',
                component: AdminOpportunityRequestComponent,
                data: new RouteDataModel({ title: 'Заявка на подію', description: 'Можна переглянути та обробити заявку на подію' }),
            },
            {
                path: 'opportunity/new',
                component: AdminOpportunityComponent,
                data: new RouteDataModel({ title: 'Створення нової події', description: 'Уважно переглянь всі деталі' }),
            },
            {
                path: 'opportunity/:opportunityId',
                component: AdminOpportunityComponent,
                data: new RouteDataModel({ title: 'Перегляд події', description: 'Можна переглядати події на поточні заявки на подію' }),
            },
            {
                path: 'opportunities',
                component: AdminOpportunitiesComponent,
                data: new RouteDataModel({ title: 'Список події', description: 'Всі події можна знайти тут' }),
            },
            {
                path: 'generic-types',
                component: AdminGenericTypesComponent,
                data: new RouteDataModel({ title: 'Системні налаштування', description: 'Можна додати категорії події або групи допомоги' }),
            },
            {
                path: 'users/new',
                component: AdminUserComponent,
                data: new RouteDataModel({ title: 'Створення гового користувача', description: 'Можна створити користувача та передати його' }),
            },
            {
                path: 'users/:userId',
                component: AdminUserComponent,
                data: new RouteDataModel({ title: 'Редагування користувача', description: 'Редагування користувача. Можна скинути пароль та задати ролі' }),
            },
            {
                path: 'users',
                component: AdminUsersComponent,
                data: new RouteDataModel({ title: 'Користувачі', description: 'Можна переглянути всіх користувачів або знайти потрібного' }),
            },
            {
                path: 'projects/new',
                component: AdminProjectComponent,
                data: new RouteDataModel({ title: 'Створення проєкту', description: 'Уважно перевір деталі проєкту' }),
            },
            {
                path: 'projects/:projectId',
                component: AdminProjectComponent,
                data: new RouteDataModel({ title: 'Редагування проєкту', description: 'Можна переглянути поточний стан' }),
            },
            {
                path: 'projects',
                component: AdminProjectsComponent,
                data: new RouteDataModel({ title: 'Список проєктів', description: 'Можна переглянути всі створені проєкти' }),
            },
            {
                path: 'data-export',
                component: AdminDataExportComponent,
                data: new RouteDataModel({ title: 'Експорт', description: 'Можна експортувати' }),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
