import { Component, OnDestroy, OnInit, AfterContentInit } from '@angular/core';
import * as ClientActions from '../../store/client.actions';
import * as AuthActions from '../../../auth/store/auth.actions';
import { IUserProfile, UserModel } from 'src/app/modules/core/models/user.model';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { LoadingState, getError } from 'src/app/modules/core/models/request-state.model';

@Component({
    selector: 'app-client-settings',
    templateUrl: './client-settings.component.html',
    styleUrls: ['./client-settings.component.scss'],
})
export class ClientSettingsComponent implements OnInit, OnDestroy {
    isLoading = false;
    error: string | null = '';

    private storeSubscription: Subscription = new Subscription();
    private user: UserModel | null = null;
    memberMode = false;
    public profile: IUserProfile | null = null;

    constructor(private store: Store<IApplicationState>) {}

    ngOnInit(): void {
        setTimeout(() => {
            this.store.dispatch(AuthActions.profileGet());
        }, 100);

        this.storeSubscription = this.store.select('auth').subscribe((authState) => {
            this.isLoading = authState.requestState === LoadingState.LOADING;
            this.error = getError(authState.requestState);

            if (authState.user) {
                this.user = authState.user;
                this.memberMode = authState.user.isMember();
                const profile = this.user?.getProfile();
                if (profile) {
                    this.profile = profile;
                }
            }
        });
    }
    ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
    }

    updateProfileEventHandler(profile: IUserProfile) {
        console.log(profile);
        if (this.user) {
            const userToUpdate = this.user.clone();
            const updatedProfile = profile;
            userToUpdate.setProfile(updatedProfile);
            this.store.dispatch(AuthActions.profileUpdate({ user: userToUpdate }));
        }
    }
}
