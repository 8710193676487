<div class="project container">
    <app-breadcrumps
        [currentItem]="getProjectTitle()"
        [rootItems]="[{ text: getProjectsTitle(), href: getProjectsUrl(), type: 'OpportunityRequests' }]"></app-breadcrumps>
    <h2>{{ getProjectTitle() }}</h2>
    <app-error-message
        *ngIf="error"
        [errorMessage]="error"></app-error-message>

    <div class="project-wrapper">
        <div
            fxLayout="row"
            class="project__settings">
            <form
                [formGroup]="projectForm"
                (ngSubmit)="onProjectSubmit()"
                autocomplete="off">
                <div
                    fxLayoutAlign="start"
                    fxLayoutAlign.xs="space-evenly"
                    fxLayout="row"
                    fxLayout.xs="column">
                    <div class="project__settings-info">
                        <h4>Інфомація про проєкт</h4>
                        <div class="form-row">
                            <input
                                type="hidden"
                                formControlName="id" />
                            <mat-form-field
                                [floatLabel]="'always'"
                                appearance="fill"
                                class="project__input-long">
                                <mat-label>Назва проєкту</mat-label>
                                <input
                                    matInput
                                    placeholder="Назва проєкту"
                                    formControlName="name"
                                    autocomplete="off"
                                    class="project__input-long" />
                                <mat-icon matSuffix>supervisor_account</mat-icon>
                                <mat-error *ngIf="projectForm.controls['name'].hasError('required')"> Назва <strong>Обов'язкова</strong> </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-row">
                            <mat-form-field
                                appearance="fill"
                                [floatLabel]="'always'"
                                class="project__input-long">
                                <mat-label>Статус проєкту</mat-label>
                                <mat-select formControlName="status">
                                    <mat-option
                                        *ngFor="let status of projectStatuses"
                                        [value]="status.value">
                                        {{ status.value | translateEnum : 'ProjectStatus' }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-row">
                            <mat-form-field
                                class="project__input-long"
                                [floatLabel]="'always'"
                                appearance="fill">
                                <mat-label>Опис</mat-label>
                                <textarea
                                    matInput
                                    placeholder="Опис"
                                    formControlName="description"
                                    class="project__input short-description"
                                    rows="4"
                                    [maxlength]="getMaxDescription()"></textarea>
                                <mat-error *ngIf="projectForm.controls['description'].hasError('required')"> Опис <strong>Обов'язковий</strong> </mat-error>
                                <mat-error *ngIf="projectForm.controls['description'].hasError('maxlength')">
                                    Максимальна довжина <strong>{{ getMaxDescription() }} символів</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="project__settings-other">
                        <h4>Налаштування проєкту</h4>
                        <div class="form-row">
                            <mat-form-field
                                [floatLabel]="'always'"
                                appearance="fill">
                                <mat-label>Чоловічі набори</mat-label>
                                <input
                                    type="number"
                                    matInput
                                    placeholder="Чоловічі набори"
                                    formControlName="male"
                                    autocomplete="off" />
                                <mat-icon matSuffix>supervisor_account</mat-icon>
                                <mat-error *ngIf="projectForm.controls['male'].hasError('required')"> Чоловічі набори <strong>Обов'язкові</strong> </mat-error>
                            </mat-form-field>
                            <span class="project__settings-hint"> Фактично: 0 </span>
                        </div>
                        <div class="form-row">
                            <mat-form-field
                                [floatLabel]="'always'"
                                appearance="fill">
                                <mat-label>Жіночі набори</mat-label>
                                <input
                                    type="number"
                                    matInput
                                    placeholder="Жіночі набори"
                                    formControlName="female"
                                    autocomplete="off" />
                                <mat-icon matSuffix>supervisor_account</mat-icon>
                                <mat-error *ngIf="projectForm.controls['female'].hasError('required')"> Жіночі набори <strong>Обов'язкові</strong> </mat-error>
                            </mat-form-field>
                            <span class="project__settings-hint"> Фактично: 0 </span>
                        </div>
                        <div class="form-row">
                            <mat-form-field
                                [floatLabel]="'always'"
                                appearance="fill">
                                <mat-label>Дитячі(Ч) набори</mat-label>
                                <input
                                    type="number"
                                    matInput
                                    placeholder="Дитячі(Ч) набори"
                                    formControlName="childMale"
                                    autocomplete="off" />
                                <mat-icon matSuffix>supervisor_account</mat-icon>
                                <mat-error *ngIf="projectForm.controls['childMale'].hasError('required')"> Дитячі(Ч) набори <strong>Обов'язкові</strong> </mat-error>
                            </mat-form-field>
                            <span class="project__settings-hint"> Фактично: 0 </span>
                        </div>
                        <div class="form-row">
                            <mat-form-field
                                [floatLabel]="'always'"
                                appearance="fill">
                                <mat-label>Дитячі(Ж) набори</mat-label>
                                <input
                                    type="number"
                                    matInput
                                    placeholder="Дитячі(Ж) набори"
                                    formControlName="childFemale"
                                    autocomplete="off" />
                                <mat-icon matSuffix>supervisor_account</mat-icon>
                                <mat-error *ngIf="projectForm.controls['childFemale'].hasError('required')"> Дитячі(Ж) набори <strong>Обов'язкові</strong> </mat-error>
                            </mat-form-field>
                            <span class="project__settings-hint"> Фактично: 0 </span>
                        </div>
                        <div class="form-row">
                            <mat-form-field
                                [floatLabel]="'always'"
                                appearance="fill">
                                <mat-label>Літні(Ч) набори</mat-label>
                                <input
                                    type="number"
                                    matInput
                                    placeholder="Літні(Ч)  набори"
                                    formControlName="seniorMale"
                                    autocomplete="off" />
                                <mat-icon matSuffix>supervisor_account</mat-icon>
                                <mat-error *ngIf="projectForm.controls['seniorMale'].hasError('required')"> Літні(Ч) набори <strong>Обов'язкові</strong> </mat-error>
                            </mat-form-field>
                            <span class="project__settings-hint"> Фактично: 0 </span>
                        </div>
                        <div class="form-row">
                            <mat-form-field
                                [floatLabel]="'always'"
                                appearance="fill">
                                <mat-label>Літні(Ж) набори</mat-label>
                                <input
                                    type="number"
                                    matInput
                                    placeholder="Літні(Ж) набори"
                                    formControlName="seniorFemale"
                                    autocomplete="off" />
                                <mat-icon matSuffix>supervisor_account</mat-icon>
                                <mat-error *ngIf="projectForm.controls['seniorFemale'].hasError('required')"> Літні(Ж) набори <strong>Обов'язкові</strong> </mat-error>
                            </mat-form-field>
                            <span class="project__settings-hint"> Фактично: 0 </span>
                        </div>
                    </div>
                </div>
                <div>
                    <button
                        mat-flat-button
                        color="primary"
                        type="submit"
                        [disabled]="!projectForm.valid">
                        {{ getButtonUpdateProjectTitle() }}
                    </button>
                </div>
            </form>
        </div>

        <div class="project-opportunities-grid"  *ngIf="!isNewProject()">
            <h4>Перелік подій</h4>
            <app-empty-table *ngIf="!opportunities.length && !isLoading"></app-empty-table>

            <div
                fxLayout="row"
                fxLayoutAlign="start">
                <table
                    *ngIf="opportunities.length"
                    mat-table
                    [dataSource]="opportunities"
                    multiTemplateDataRows
                    class="project-opportunities">
                    <ng-container matColumnDef="name">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="project-opportunities__cell">
                            Подія
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="project-opportunities__cell">
                            <span *ngIf="!isMobile()">{{ element.baseOpportunity.name }}</span>
                            <div *ngIf="isMobile()">
                                <div
                                    fxLayoutAlign="start"
                                    fxLayout="row">
                                    <div class="project-opportunities__mobile-info">
                                        <div class="project-opportunities__mobile-title">Подія</div>
                                        <div class="project-opportunities__mobile-value">{{ element.baseOpportunity.name }}</div>
                                    </div>
                                </div>
                                <div
                                    fxLayoutAlign="start"
                                    fxLayout="row"
                                    class="project-opportunities__mobile-container">
                                    <div class="project-opportunities__mobile-info">
                                        <div class="project-opportunities__mobile-title">Розподіл</div>
                                        <div class="project-opportunities__mobile-value">
                                           0
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="distribution">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="align-center">
                            Розподіл
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            class="align-center">
                            <div>
                                0
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="expand">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            aria-label="row actions">
                            &nbsp;
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element">
                            <button
                                type="button"
                                mat-icon-button
                                (click)="navigateToOpportunityPage(element)">
                                <mat-icon>visibility</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr
                        mat-header-row
                        *matHeaderRowDef="opportunitiesColumnsWithExpand"></tr>
                    <tr
                        mat-row
                        *matRowDef="let element; columns: opportunitiesColumnsWithExpand"
                        class="requests__element-row"></tr>
                </table>
            </div>
        </div>
    </div>
</div>
