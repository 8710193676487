<div
    class="opportunity-card"
    [ngClass]="{ 'opportunity-card--expanded': showDescription }">
    <div
        class="opportunity-card__image"
        (click)="onNavigateToOpportunity()">
        <div class="opportunity-card__status">{{ this.opportunity.baseOpportunity.status | translateEnum : 'OpportunityStatus' }}</div>
    </div>
    <h3>{{ this.opportunity.baseOpportunity.name }}</h3>
    <div class="opportunity-card__option">
        <div class="opportunity-card__option-header">Тип допомоги</div>
        <div fxLayout="row wrap">
            <div
                class="opportunity-card__option-item"
                *ngFor="let item of this.opportunity.baseOpportunity.types">
                {{ item.baseType.name }}
            </div>
        </div>
    </div>
    <div class="opportunity-card__option">
        <div class="opportunity-card__option-header">Категорія допомоги</div>
        <div fxLayout="row wrap">
            <div
            class="opportunity-card__option-item"
            *ngFor="let item of this.opportunity.baseOpportunity.availableCategories">
            {{ item.baseType.name }}
        </div>
        </div>
    </div>
    <div
        class="opportunity-card__description"
        [ngClass]="{ 'opportunity-card__description--visible': showDescription }">
        {{ opportunity.baseOpportunity.shortDescription }}
    </div>
    <div class="opportunity-card__actions">
        <div fxLayout="row">
            <button
                mat-stroked-button
                class="opportunity-card__button button-grey"
                (click)="onTogleShowDescription()"
                [ngClass]="{ 'opportunity-card__button--visible': isShowMoreVisible() }">
                Детальніше про подію
            </button>
        </div>
        <div fxLayout="row">
            <button
                mat-stroked-button
                color="primary"
                class="opportunity-card__button opportunity-card__button--visible button-light-blue"
                (click)="onNavigateToOpportunity()">
                Перейти на подію
            </button>
        </div>
        <div fxLayout="row">
            <div
                [ngClass]="{ 'opportunity-card__action--visible': isStateSent() }"
                class="opportunity-card__action opportunity-card__action--sent">
                Заявка успішно подана
            </div>
        </div>
        <div fxLayout="row">
            <div
                [ngClass]="{ 'opportunity-card__action--visible': isStateApproved() }"
                class="opportunity-card__action opportunity-card__action--approved">
                Заявку схвалено
            </div>
        </div>
        <div fxLayout="row">
            <div
                [ngClass]="{ 'opportunity-card__action--visible': isStateRejected() }"
                class="opportunity-card__action opportunity-card__action--rejected">
                Заявку відхилено
            </div>
        </div>
        <div fxLayout="row">
            <div
                [ngClass]="{ 'opportunity-card__action--visible': isStateFullfilled() }"
                class="opportunity-card__action opportunity-card__action--approved">
                Допомогу отримано
            </div>
        </div>
    </div>
</div>
