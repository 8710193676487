<div class="common-dialog-wrapper">
    <div class="common-dialog">
        <div class="common-dialog__icon">
            <img
                [src]="data.icon"
                alt="success" />
        </div>
        <div
            class="common-dialog__header"
            *ngIf="data.header">
            <h3>{{ data.header }}</h3>
        </div>
        <div
            class="common-dialog__content"
            *ngIf="data.header">
            {{ data.content }}
            <div
                class="common-dialog__content common-dialog__content--errors"
                *ngIf="data.errors"
                [innerHtml]="data.errors"></div>
        </div>
        <div class="common-dialog__buttons">
            <a
                *ngIf="isTextButton()"
                mat-dialog-close
                (click)="buttonClickHandler()"
                class="text-button">
                {{ data.actionText || defaultActionText }}
            </a>
            <button
                *ngIf="isButton()"
                mat-dialog-close
                mat-flat-button
                color="primary"
                class="common-dialog__button"
                (click)="buttonClickHandler()">
                {{ data.actionText || defaultActionText }}
            </button>
        </div>
    </div>
</div>
