import { createAction, props } from '@ngrx/store';
import { UserModel } from '../../core/models/user.model';
import { OpportunityModel } from '../../core/models/opportunity.model';
import { OpportunityRequestModel } from '../../core/models/opportunity-request.model';

export const CLIENT_FAILED = '[Client] CLIENT_FAILED';

export const GET_PROFILE = '[Client] GET_PROFILE';
export const GET_PROFILE_SUCCESS = '[Client] GET_PROFILE_SUCCESS';

export const OPPORTUNITIES_GET = '[Client] OPPORTUNITIES_GET';
export const OPPORTUNITIES_GET_SUCCESS = '[Client] OPPORTUNITIES_GET_SUCCESS';
export const OPPORTUNITY_GET_ONE = '[Client] OPPORTUNITY_GET_ONE';
export const OPPORTUNITY_GET_ONE_SUCCESS = '[Client] OPPORTUNITY_GET_ONE_SUCCESS';

export const OPPORTUNITY_REQUEST_SUBMIT = '[Client] OPPORTUNITY_REQUEST_SUBMIT';
export const OPPORTUNITY_REQUEST_SUCCESS = '[Client] OPPORTUNITY_REQUEST_SUCCESS';
export const OPPORTUNITY_REQUEST_UPDATE = '[Client] OPPORTUNITY_REQUEST_UPDATE';
export const OPPORTUNITY_REQUEST_UPDATE_SUCCESS = '[Client] OPPORTUNITY_REQUEST_UPDATE_SUCCESS';

export const OPPORTUNITIES_REQUESTS_GET = '[Client] OPPORTUNITIES_REQUESTS_GET';
export const OPPORTUNITIES_REQUESTS_GET_SUCCESS = '[Client] OPPORTUNITIES_REQUESTS_GET_SUCCESS';

export const INIT_TOGGLE_MENU = '[Client] INIT_TOGGLE_MENU';
export const CANCEL_TOGGLE_MENU = '[Client] CANCEL_TOGGLE_MENU';

export const CHECK_TUTORIAL = '[Client] CHECK_TUTORIAL';
export const LAUNCH_TUTORIAL = '[Client] LAUNCH_TUTORIAL';

export const getProfile = createAction(GET_PROFILE, props<{ user: UserModel }>());
export const getProfileSuccess = createAction(GET_PROFILE_SUCCESS);

export const clientFailed = createAction(CLIENT_FAILED, props<{ error: string; initialError: string }>());

export const opportunitiesGet = createAction(OPPORTUNITIES_GET);
export const opportunitiesGetSuccess = createAction(OPPORTUNITIES_GET_SUCCESS, props<{ opportunities: OpportunityModel[] }>());
export const opportunityGetOne = createAction(OPPORTUNITY_GET_ONE, props<{ opportunityId: string }>());
export const opportunityGetOneSuccess = createAction(OPPORTUNITY_GET_ONE_SUCCESS, props<{ opportunity: OpportunityModel }>());

export const opportunityRequestSubmit = createAction(OPPORTUNITY_REQUEST_SUBMIT, props<{ opportunity: OpportunityModel }>());
export const opportunityRequestSuccess = createAction(OPPORTUNITY_REQUEST_SUCCESS, props<{ opportunity: OpportunityModel }>());
export const opportunityRequestUpdate = createAction(OPPORTUNITY_REQUEST_UPDATE, props<{ opportunity: OpportunityModel }>());
export const opportunityRequestUpdateSuccess = createAction(OPPORTUNITY_REQUEST_UPDATE_SUCCESS, props<{ opportunity: OpportunityModel }>());

export const opportunitiesRequestGet = createAction(OPPORTUNITIES_REQUESTS_GET);
export const opportunitiesRequestGetSuccess = createAction(OPPORTUNITIES_REQUESTS_GET_SUCCESS, props<{ opportunityRequests: OpportunityRequestModel[] }>());

export const initToggleMenu = createAction(INIT_TOGGLE_MENU);
export const cancelToggleMenu = createAction(CANCEL_TOGGLE_MENU);

export const checkTutorial = createAction(CHECK_TUTORIAL);
export const launchTutorial = createAction(LAUNCH_TUTORIAL);