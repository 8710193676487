import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, ActivationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, map, shareReplay } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { LoadingState } from 'src/app/modules/core/models/request-state.model';
import { RouteDataModel } from 'src/app/modules/core/models/route-data.model';
import { UserModel } from 'src/app/modules/core/models/user.model';

@Component({
    selector: 'app-client-main',
    templateUrl: './client-main.component.html',
    styleUrls: ['./client-main.component.scss'],
})
export class ClientMainComponent implements OnInit, OnDestroy {
    isLoading = false;
    badgeText = '';
    user: null | UserModel = null;
    storeSubscription = new Subscription();
    routeSubscription = new Subscription();
    routeDataSubscription = new Subscription();
    currentData: RouteDataModel = new RouteDataModel();

    constructor(private store: Store<IApplicationState>, private currentRoute: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        setTimeout(() => {
            this.storeSubscription = this.store.select('auth').subscribe((state) => {
                this.isLoading = state.requestState === LoadingState.LOADING;
            });
        }, 100);

        this.routeDataSubscription = this.currentRoute.data.subscribe((data) => {
            this.currentData = new RouteDataModel((data as RouteDataModel).baseRoute);
        });

        this.routeSubscription = this.router.events.subscribe((event) => {
            if (event instanceof ActivationStart) {
                this.currentData = new RouteDataModel((event.snapshot.data as RouteDataModel).baseRoute);
            }
        });
    }

    ngOnDestroy(): void {
        this.routeDataSubscription.unsubscribe();
        this.storeSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }
}
