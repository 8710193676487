<div class="admin-breakcrump">
    <app-breadcrumps
        [currentItem]="opportunity.baseOpportunity.name"
        [rootItems]="[{ text: 'Події', href: '/admin/opportunities' }]"></app-breadcrumps>
</div>
<mat-tab-group
    #opportunityTabs
    class="tabs-same-width"
    #opportunnityTabs>
    <mat-tab label="Налаштування">
        <app-admin-opportunity-settings></app-admin-opportunity-settings>
    </mat-tab>
    <mat-tab label="Заявки">
        <app-admin-opportunity-requests></app-admin-opportunity-requests>
    </mat-tab>
</mat-tab-group>
