import { Component, OnInit, ViewChild } from '@angular/core'
import { MatTabGroup } from '@angular/material/tabs'
import { ActivatedRoute, Router } from '@angular/router'
import { NavTabType } from 'src/app/modules/core/models/nav-tabs.model'


@Component({
    selector: 'app-auth-form',
    templateUrl: './auth-form.component.html',
    styleUrls: ['./auth-form.component.scss'],
})
export class AuthFormComponent implements OnInit {
    @ViewChild('authTabs') authTabs?: MatTabGroup
    selectedTab: NavTabType = NavTabType.Login

    constructor(private currentRoute: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.currentRoute.queryParams.subscribe((currentParams) => {
            const currentType = currentParams['type']
            if (currentType) {
                this.selectedTab = currentType;
                this.processViewTypeChange()
            }
        })
    }

    processViewTypeChange() {
        setTimeout(() => {
            const selectedIndex = this.selectedTab === NavTabType.Login ? 0 : 1
            if (this.authTabs) {
                this.authTabs.selectedIndex = selectedIndex
            }
        }, 200)
    }
}
