<div class="container container--main">
    <div class="home-logo">
        <app-logo></app-logo>
    </div>

    <div class="home-content">
        <h2>
            Портал соціальної<br />
            <span class="text-highlight">допомоги</span>
        </h2>

        <div *ngIf="!user">
            Для того, щоб користуватися системою,<br />
            увійдіть або зареєструйтесь
            <div class="home-buttons">
                <button
                    mat-stroked-button
                    class="home-buttons__button"
                    (click)="navigateToLogin()">
                    Вхід
                </button>
                <button
                    mat-flat-button
                    color="primary"
                    class="home-buttons__button"
                    (click)="navigateToRegister()">
                    Реєстрація
                </button>
            </div>
        </div>
        <h3 *ngIf="this.user?.isProfileFulfilled()">
            Вітаємо! Тепер ви можете переглядати проєкти<br />
            та подавати заявки на допомогу!
            <div class="home-buttons">
                <button
                    mat-flat-button
                    color="primary"
                    class="home-buttons__button"
                    (click)="navigateToMain()">
                    На головну
                </button>
            </div>
        </h3>
        <h3 *ngIf="this.user && !this.user?.isProfileFulfilled()">
            Вітаємо! Необхідно заповнити профіль, щоб переглянути події.
            <div class="home-buttons">
                <button
                    mat-flat-button
                    color="primary"
                    class="home-buttons__button"
                    (click)="navigateToSettings()">
                    Налаштування
                </button>
            </div>
        </h3>
    </div>
</div>
