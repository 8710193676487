import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, forkJoin, map, withLatestFrom, of, tap } from 'rxjs';
import { IApplicationState } from 'src/app/app.reducer';
import { environment } from 'src/environments/environment';
import { switchMapCatchErrorOperator } from '../../core/operators/switchmap-catch.operator';
import {
    IClientOpportunitiesListResponseData,
    IClientOpportunityRequestResponseData,
    IClientOpportunityRequestsResponseData,
    IClientOpportunityResponseData,
    IClientRequestData,
    IClientResponseData,
} from '../models/client.model';
import * as ClientActions from './client.actions';
import {
    fromOpportunitiesToOpportunityModels,
    fromOpportunityRequestToOpportunityRequestModel,
    fromOpportunityRequestToOpportunityRequestRequestModel,
    fromOpportunityRequestsToOpportunityRequestsModel,
    fromOpportunityToOpportunityModel,
} from './client.utils';
import { OpportunityRequestFulfillType, OpportunityRequestModel } from '../../core/models/opportunity-request.model';
import { exportSentryErrorMessage, extractErrorFromResponse } from '../../core/helpers/exception.helper';
import * as Sentry from '@sentry/angular-ivy';
import { ExceptionArea } from '../../core/models/exception.model';
import { TutorialHelper } from '../../core/helpers/tutorial.helper';

const LOCAL_STORAGE_TUTORIAL_VIEWED = 'userTutorialViewed';

const handleSwitchMapError = (errorRes: any) => {
    const error = extractErrorFromResponse(errorRes, false);
    return ClientActions.clientFailed({ error: error.error, initialError: error.initialError });
};

@Injectable()
export class ClientEffects {
    constructor(
        private actions: Actions,
        private http: HttpClient,
        private router: Router, // private authService: AuthService, // private store: Store<IApplicationState>,
        public dialog: MatDialog,
        private store: Store<IApplicationState>,
        private tutorialHelper: TutorialHelper
    ) {}

    getProfile = createEffect(
        () =>
            this.actions.pipe(
                ofType(ClientActions.getProfile),
                switchMap(({ user }) => {
                    console.log('getProfile');
                    // const requestData: IClientRequestData = {
                    //     email: user.baseUser.email,
                    //     password: '',
                    // };
                    const getProfileRequest = this.http.get<IClientResponseData>(`${environment.apiUrl}/api/v1/account/profile`);
                    return forkJoin([getProfileRequest]);
                }),
                map(([registrationResponse]) => {
                    console.log(registrationResponse);
                    return ClientActions.getProfileSuccess();
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    opportunitiesGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(ClientActions.opportunitiesGet),
                switchMap(() => {
                    console.log('opportunitiesGet');
                    const opportunitiesGetRequest = this.http.get<IClientOpportunitiesListResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity?join=addresses,types,suggestionSettings,availableCategories&filter=status|$eq|active`
                    );
                    return forkJoin([opportunitiesGetRequest]);
                }),
                map(([opportunitiesGetResponse]) => {
                    console.log(opportunitiesGetResponse);
                    const opportunities = fromOpportunitiesToOpportunityModels(opportunitiesGetResponse);
                    return ClientActions.opportunitiesGetSuccess({ opportunities });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    opportunityGetOne = createEffect(
        () =>
            this.actions.pipe(
                ofType(ClientActions.opportunityGetOne),
                withLatestFrom(this.store.select('auth')),
                switchMap(([{ opportunityId }, authState]) => {
                    console.log('opportunityGetOne');
                    const profileId = authState.user?.getProfile().id || '';
                    const opportunityGetOneRequest = this.http.get<IClientOpportunityResponseData>(`${environment.apiUrl}/api/v1/opportunity/${opportunityId}?join=types,availableCategories,hasAccess`);
                    return forkJoin([of(profileId), opportunityGetOneRequest]);
                }),
                switchMap(([userProfileId, opportunityGetOneResponse]) => {
                    console.log('opportunityGetOne');
                    const opportunity = fromOpportunityToOpportunityModel(opportunityGetOneResponse);
                    const opportunityRequestGet = this.http.get<IClientOpportunityRequestsResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity/request?filter=profile.id|$eq|${userProfileId}&filter=opportunityId|$eq|${opportunity.baseOpportunity.id}`
                    );
                    return forkJoin([of(opportunityGetOneResponse), opportunityRequestGet]);
                }),
                map(([opportunityGetOneResponse, opportunityRequestGetResonse]) => {
                    console.log(opportunityGetOneResponse);
                    const opportunity = fromOpportunityToOpportunityModel(opportunityGetOneResponse);
                    const opportunityRequests = fromOpportunityRequestsToOpportunityRequestsModel(opportunityRequestGetResonse);
                    if (opportunityRequests.length) {
                        opportunity.setOpportunityRequest(opportunityRequests[0]);
                    }
                    return ClientActions.opportunityGetOneSuccess({ opportunity });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    opportunitiesRequestSubmit = createEffect(
        () =>
            this.actions.pipe(
                ofType(ClientActions.opportunityRequestSubmit),
                withLatestFrom(this.store.select('auth')),
                switchMap(([{ opportunity }, authState]) => {
                    console.log('opportunityGetOne');
                    const oppRequest = new OpportunityRequestModel();
                    const requestData = {
                        ...oppRequest.baseRequest,
                        profileId: authState.user?.getProfile().id,
                        opportunityId: opportunity.baseOpportunity.id,
                        fulfillType: OpportunityRequestFulfillType.none,
                    };

                    const opportunitiesRequestSubmitRequest = this.http.post<IClientOpportunityRequestResponseData>(`${environment.apiUrl}/api/v1/opportunity/request`, requestData);
                    return forkJoin([of(opportunity), opportunitiesRequestSubmitRequest]);
                }),
                map(([opportunity, opportunitiesRequestSubmitOneResponse]) => {
                    console.log(opportunitiesRequestSubmitOneResponse);
                    const updatedOpportunity = opportunity.clone();
                    const opportunityRequest = fromOpportunityRequestToOpportunityRequestModel(opportunitiesRequestSubmitOneResponse);
                    updatedOpportunity.setOpportunityRequest(opportunityRequest);
                    return ClientActions.opportunityRequestSuccess({ opportunity: updatedOpportunity });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    opportunityRequestUpdate = createEffect(
        () =>
            this.actions.pipe(
                ofType(ClientActions.opportunityRequestUpdate),
                withLatestFrom(this.store.select('auth')),
                switchMap(([{ opportunity }, authState]) => {
                    console.log('opportunityRequestUpdate');

                    const oppRequest = opportunity.getOpportunityRequest()!;
                    const requestData = fromOpportunityRequestToOpportunityRequestRequestModel(oppRequest);

                    const opportunityRequestUpdateRequest = this.http.patch<IClientOpportunityRequestResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity/request/${oppRequest?.baseRequest.id}`,
                        requestData
                    );
                    return forkJoin([of(opportunity), opportunityRequestUpdateRequest]);
                }),
                map(([opportunity, opportunityRequestUpdateResponse]) => {
                    console.log(opportunityRequestUpdateResponse);
                    const updatedOpportunity = opportunity.clone();
                    const opportunityRequest = fromOpportunityRequestToOpportunityRequestModel(opportunityRequestUpdateResponse);
                    updatedOpportunity.setOpportunityRequest(opportunityRequest);
                    return ClientActions.opportunityRequestSuccess({ opportunity: updatedOpportunity });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    opportunitiesRequestGet = createEffect(
        () =>
            this.actions.pipe(
                ofType(ClientActions.opportunitiesRequestGet),
                withLatestFrom(this.store.select('auth')),
                switchMap(([{}, authState]) => {
                    const userId = authState.user?.baseUser.id || '';
                    console.log('opportunitiesRequestGet');
                    const opportunitiesRequestGetRequest = this.http.get<IClientOpportunityRequestsResponseData>(
                        `${environment.apiUrl}/api/v1/opportunity/request?filter=profileId|$eq|${userId}&join=opportunity,opportunity.types,opportunity.availableCategories&limit=100`
                    );
                    return forkJoin([opportunitiesRequestGetRequest]);
                }),
                map(([opportunitiesRequestGetResponse]) => {
                    console.log(opportunitiesRequestGetResponse);
                    const opportunityRequests = fromOpportunityRequestsToOpportunityRequestsModel(opportunitiesRequestGetResponse);
                    return ClientActions.opportunitiesRequestGetSuccess({ opportunityRequests });
                }),
                switchMapCatchErrorOperator(handleSwitchMapError)
            )
        // {dispatch: false}
    );

    clientFailed = createEffect(
        () =>
            this.actions.pipe(
                ofType(ClientActions.clientFailed),
                withLatestFrom(this.store.select('auth')),
                tap(([action, { user }]) => {
                    const errorMessage = exportSentryErrorMessage(action, user, ExceptionArea.client);
                    Sentry.captureException(JSON.stringify(errorMessage));
                })
            ),
        { dispatch: false }
    );

    checkTutorial = createEffect(
        () =>
            this.actions.pipe(
                ofType(ClientActions.launchTutorial),
                withLatestFrom(this.store.select('client')),
                tap(([action, { opportunitiesRequests }]) => {
                    const savedUserTutorialViewed = localStorage.getItem(LOCAL_STORAGE_TUTORIAL_VIEWED);
                    if (!savedUserTutorialViewed && !opportunitiesRequests.length) {
                        this.tutorialHelper.startTutorial();
                    }
                })
            ),
        { dispatch: false }
    );

    launchTutorial = createEffect(
        () =>
            this.actions.pipe(
                ofType(ClientActions.launchTutorial),
                withLatestFrom(this.store.select('auth')),
                tap(([action, { user }]) => {
                    localStorage.setItem(LOCAL_STORAGE_TUTORIAL_VIEWED, JSON.stringify({ userTutorialViewed: true }));
                    this.tutorialHelper.startTutorial();
                })
            ),
        { dispatch: false }
    );
}
